import { Component } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { MessagingService } from './astrologer/services/messaging/messaging.service';
import { OrderServiceService } from './astrologer/services/order/order-service.service';
import { UserdataService } from './astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'astro-panel';

  constructor(
    private messagingService: MessagingService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router,
    private orderService: OrderServiceService
  ) { }

  ngOnInit() {
    this.messagingService.receiveMessage()
    this.onDataChangeReceived()
  }

  message: ''
  orderData: any = ''
  onDataChangeReceived = () => {
    this.messagingService.currentMessage.subscribe((change: any) => {
      if (change) {
        this.message = change.notification.body
        var data = JSON.parse(change.data.fulldata)
        //console.log(data)
        if (data.is_chat != undefined && data.is_chat) {
          /** API to fetch order Details*/
          this.orderService.getOrderDetail({ astroId: this.userdata.getId(), _id: data.orderserviceId }).subscribe(
            (res: any) => {
              this.orderData = res.data
              var date = moment(data.astrologer_notification_time);
              var notify = date.utc().format('YYYY-MM-DD hh:mm:ss');
              var notifyTime = moment(notify, 'YYYY-MM-DD hh:mm:ss');
              //var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
              var current = moment(res.server_current_time);
              var currentdate = current.utc().format('YYYY-MM-DD hh:mm:ss');
              var currentTime = moment(currentdate, 'YYYY-MM-DD hh:mm:ss');
              var secondsDiff = (currentTime.diff(notifyTime, 'seconds')) + 10;
              var timeLeft = 180 - secondsDiff
              if (timeLeft <= 0) {
                Swal.fire(
                  'Timeout',
                  "Chat request can't be accepted due to chat timeout.",
                  'error'
                )
                return
              }
              if (data.is_popup) {
                let timerInterval
                //console.log(this.orderData)
                /**
                 * Popup
                 */
                Swal.fire({
                  // title: change.notification.title,
                  html: 'Time Left <b></b>. <hr/><table class="table table-bordered p-0"><tr><td><strong>Name:</strong></td><td>' + this.orderData.customerId?.name + '</td></tr><tr><td colspan="2" class="text-danger"><strong>Profile Details</strong></td></tr><tr><td><strong>Person Name:</strong></td><td>' + this.orderData.personId?.name + '</td></tr><tr><td><strong>Gender:</strong></td><td>' + this.orderData.personId?.gender + '</td></tr><tr><td><strong>DOB:</strong></td><td>' + this.orderData.personId?.dob + '</td></tr><tr><td><strong>TOB:</strong></td><td>' + this.orderData.personId?.tob + '</td></tr><tr><td><strong>POB:</strong></td><td>' + this.orderData.personId?.city + '</td></tr><tr><td><strong>Marital Status:</strong></td><td>' + this.orderData.personId?.marital_status + '</td></tr><tr><td><strong>Occupation:</strong></td><td>' + this.orderData.personId?.employed_in + '</td></tr><tr><td><strong>Comments</strong></td><td>' + this.orderData.chatId?.comments + '</td></tr></table>',
                  // icon: 'info',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'ACCEPT',
                  cancelButtonText: 'REJECT',
                  allowOutsideClick: false,
                  timer: timeLeft * 1000,
                  timerProgressBar: true,
                  didOpen: () => {
                    //Swal.showLoading()
                    timerInterval = setInterval(() => {
                      const content = Swal.getHtmlContainer()
                      if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                          b.textContent = String(moment.utc(Swal.getTimerLeft()).format('mm:ss'));
                        }
                      }
                    }, 100)
                  },
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  if (result.value) {
                    this.orderService.acceptRejectChat({ chatId: data.chatId, is_astrologer_accept: true }).subscribe(
                      (res: any) => {
                        //console.log(data.orderserviceId)
                        this.router.navigateByUrl('/astrologer/chat-ongoing/' + data.orderserviceId)
                      }, err => {
                        this.toast.error(err.error.message, 'Error')
                      }
                    )
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.orderService.acceptRejectChat({ chatId: data.chatId, is_astrologer_accept: false }).subscribe(
                      (res: any) => {
                        this.toast.success(res.message, 'Success')
                      }, err => {
                        this.toast.error(err.error.message, 'Error')
                      }
                    )
                  }
                })
                /**---------------------- */
              }
            }, err => {
              //console.log(err)
            }
          )
        }
      }
    });
  }
}
