import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileServiceService } from '../../services/profile/profile-service.service';
import { UserdataService } from '../../services/userdata/userdata.service';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss']
})
export class WaitingListComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private profileService: ProfileServiceService,
    private userdata: UserdataService
  ) { }

  data: any = '';
  temp: any = '';

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getWaitingList()
  }

  getWaitingList() {
    this.spinner.show()
    this.profileService.getWaitingList({ astroId: this.userdata.getId(),is_user_notified:false,is_used:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  /**
 * Update filter
 */
   updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.customerId.name.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.data = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Get Waiting Duration
   */
  calculateDuration(secs:any){
    const formattedHr = moment.utc(secs*1000).format('HH');
    const formattedMin = moment.utc(secs*1000).format('mm');
    const formattedSec = moment.utc(secs*1000).format('ss');
    if(formattedHr == '00' || formattedHr == '0' || formattedHr == undefined){
      return formattedMin+':'+formattedSec+' Min'
    }else{
      return formattedHr+':'+formattedMin+':'+formattedSec+' Hrs'
    }
  }
}
