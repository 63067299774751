<div style="display: flex; justify-content: space-between;" class="pl-2 pr-2 align-items-end">
    <!-- MODAL CREATE ISSUE-->
    <ng-template #contentIssue let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="issueForm" (submit)="addIssue()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="issue_title">Issue Title</label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="issue_title">
                    </div>
                    <div *ngIf="i.issue_title.invalid && (i.issue_title.dirty || i.issue_title.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
                <div class="form-group">
                    <label for="issue_description">Description</label>
                    <div class="input-group">
                        <textarea class="form-control" formControlName="issue_description"></textarea>
                    </div>
                    <div *ngIf="i.issue_description.invalid && (i.issue_description.dirty || i.issue_description.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark" [disabled]="!issueForm.valid">Save</button>
            </div>
        </form>
    </ng-template>
    <!-- MODAL CREATE ISSUE END-->
    <!-- MODAL VIEW ISSUE-->
    <ng-template #viewIssue let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">View Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body row">
            <table class="table">
                <tbody>
                    <tr>
                        <td><strong>Issue Id:</strong> #{{issueData.issueId}}</td>
                        <td><strong>Amount:</strong> #{{issueData.amount | currency:'CAD':'symbol-narrow'}}</td>
                        <td> 
                            <span *ngIf="issueData.is_issue_solved" class="badge badge-success">Solved</span>
                            <span *ngIf="!issueData.is_issue_solved" class="badge badge-danger">Pending</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Title: </strong>{{issueData.issue_title}}</td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Description: </strong>{{issueData.issue_description}}</td>
                    </tr>
                    <tr *ngIf="issueData.is_reply_by_system">
                        <td colspan="3"><strong>Reply: </strong>{{issueData.reply_by_system}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="d('Cross click')">
                Close
            </button>
        </div>
    </ng-template>
    <!-- MODAL VIEW ISSUE END-->
    <input type="text"  placeholder="Type to filter the wallet ID..." class="form-control col-lg-3 mt-3"
        (keyup)="updateFilter($event)" />
    <!-- <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker> -->
    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="radioValue">
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="searchDate(0)" [value]="0"> Today
        </label>
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="searchDate(1)" [value]="1"> Yesterday
        </label>
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="searchDate(2)" [value]="2"> This Month
        </label>
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="searchDate(3)" [value]="3"> Last Month
        </label>
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="searchDate(4)" [value]="4"> Last 6 Month
        </label>
    </div>
    <div>
        <button class="btn-filter-custom btn btn-success mr-2" (click)="getAstroCreditedWalletHistory()">Recharged
            Transactions</button>
        <button class="btn-filter-custom btn btn-primary mr-2" (click)="getAstroDebitedWalletHistory()">Deducted
            Transactions</button>

    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Wallet Id" prop="awalletId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.awalletId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Type">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <small *ngIf="row.txn_type == 1" class="badge badge-success">Recharged</small>
            <small *ngIf="row.txn_type == 2" class="badge badge-primary">Deducted</small>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Order">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <small *ngIf="row.orderserviceId?.is_promo_order && row.orderserviceId?.is_call_order" class="badge badge-warning">Call Promo</small>
            <small *ngIf="row.orderserviceId?.is_promo_order && row.orderserviceId?.is_chat_order" class="badge badge-info">Chat Promo</small>
            <small *ngIf="!row.orderserviceId?.is_promo_order && row.orderserviceId?.is_call_order" class="badge badge-warning">Call</small>
            <small *ngIf="!row.orderserviceId?.is_promo_order && row.orderserviceId?.is_chat_order" class="badge badge-info">Chat</small>
            <small *ngIf="row.orderserviceId?.is_report_order" class="badge badge-info">Report</small>
            <small *ngIf="row.orderserviceId?.is_query_order" class="badge badge-warning">Query</small>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="Amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.amount | currency:'INR':'symbol-narrow'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Balance" prop="Balance">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <strong>{{row.balance | currency:'INR':'symbol-narrow'}}</strong>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Wallet Remarks" prop="Wallet Comments">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.wallet_comments">{{row.wallet_comments}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Help">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <button class="btn-user-custom btn btn-outline-primary btn-sm" (click)="openIssue(contentIssue,row._id,row.amount)"
                *ngIf="!row.is_issue_created">Help?</button>
            <button class="btn-user-custom btn btn-outline-primary btn-sm" (click)="openViewIssue(viewIssue,row.walletissueId)"
                *ngIf="row.is_issue_created">View</button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>