<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />

</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="categoryId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.categoryId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Name" prop="categoryname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.categoryname">{{row.categoryname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Slug" prop="category_slug">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.category_slug">{{row.category_slug}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Keywords" prop="category_keyword">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.category_keyword">{{row.category_keyword}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/astrologer/malls-category/',row._id]" class="fa fa-eye text-primary mr-1"></a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>