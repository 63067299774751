<div style="display: flex; justify-content: center;" class="pl-2 pr-2 align-items-end">
    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="radioValue">
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="getCurrentMonthEarning()" [value]="0"> This Month
        </label>
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="getTotalEarning({'astroId':astroId,'startmonth':lastMonth,'payoutyear':lastYear})" [value]="1"> Last 3 Months
        </label>
        <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" (click)="getTotalEarning({'astroId':astroId})" [value]="2"> Total Earning
        </label>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="earningList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" style="position: relative !important;">
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Id" prop="payoutId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{(row.payoutId=='' || row.payoutId == undefined ) ? '1' : row.payoutId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Month">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.payoutmonth}}/{{row.payoutyear}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="TDS">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{(row.tds=='' || row.tds == undefined ) ? '10' : row.tds}}%
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="PG">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.pgchargeper}}%
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="2" name="Total Payout">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.totalpayout | currency:'INR':'symbol-narrow'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="2" name="Final Payout">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.finalpayout | currency:'INR':'symbol-narrow'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_paid" class="badge badge-success">PAID</span>
            <span *ngIf="!row.is_paid" class="badge badge-danger">PENDING</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Paid On">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{(row.paid_on=='' || row.paid_on == undefined ) ? '-' : row.paid_on |date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>