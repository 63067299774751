<span>
  <!-- GLOBAL-LOADER -->
  <!-- <div id="global-loader">
        <img src="../../../../assets/images/svgs/loader.svg" class="loader-img" alt="Loader">
    </div> -->

  <div class="page">
    <div class="page-main">
      <!-- HEADER -->
      <div class="header">
        <div class="container">
          <div class="d-flex">
            <a id="horizontal-navtoggle" class="animated-arrow hor-toggle"><span></span></a>
            <a class="header-brand" href="https://astroriver.com/">
              <img src="../../../../../assets/images/brand/astro.png" class="header-brand-img desktop-logo"
                alt="Solic logo" />
              <img src="../../../../../assets/images/brand/astro.png" class="header-brand-img mobile-view-logo"
                alt="Solic logo" /> </a><!-- LOGO -->
          </div>
        </div>
      </div>
      <!-- HEADER END -->

      <!-- HORIZONTAL-MENU -->
      <div class="sticky">
        <div class="horizontal-main hor-menu clearfix">
          <div class="horizontal-mainwrapper container clearfix">
            <nav class="horizontalMenu clearfix">
              <ul class="horizontalMenu-list p-4">
                <li class="p-1">&nbsp;</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!-- HORIZONTAL-MENU END -->

      <!-- CONTAINER -->
      <!-- CONTAINER -->
      <div class="container content-area relative">
        <!-- PAGE-HEADER -->
        <div class="page-header">
          <h4 class="page-title">Sign-Up As An Astrologer</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="https://astroriver.com/">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Sign-up</li>
          </ol>
        </div>
        <!-- PAGE-HEADER END -->

        <!-- ROW-1 OPEN -->
        <form [formGroup]="addAstrologerForm" (submit)="onAddAstrologerSubmit()">
          <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Please Add Your Details</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Full Name<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" form placeholder="Full Name" formControlName="name" />
                        <div *ngIf="
                            f.name.invalid && (f.name.dirty || f.name.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12" *ngIf="!isSocialLogin">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email <strong class="text-danger">*</strong>
                          <button class="
                              ml-2
                              btn btn-outline-danger btn-sm
                              social-login
                            " (click)="signUpWithGoogle()" type="button">
                            Signup With Google
                          </button></label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email address"
                          formControlName="email" />
                        <div *ngIf="
                            f.email.invalid &&
                            (f.email.dirty || f.email.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-12" *ngIf="!isSocialLogin">
                                            <div class="form-group">
                                                <label for="exampleInputname1">Password<strong class="text-danger">*</strong></label>
                                                <input type="password" class="form-control" placeholder="Password"
                                                    formControlName="password">
                                                <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"
                                                    class="alert alert-danger mt-1">
                                                    Required. Password should contains minimum 1 special symbol, 1 uppercase letter, 1 lowercase letter, 1 number and length not less than 8 characters.
                                                </div>
                                            </div>
                                        </div> -->
                    <div class="col-lg-8 col-md-12" *ngIf="isSocialLogin">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email <strong class="text-danger">*</strong>
                          <button class="
                              ml-2
                              btn btn-outline-danger btn-sm
                              social-login
                            " (click)="signUpWithEmail()" type="button">
                            SignUp With Email
                          </button></label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email address"
                          formControlName="email" readonly />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputnumber">Country Code<strong class="text-danger">*</strong></label>
                        <select class="form-control" formControlName="country_code">
                          <option *ngFor="let country of countryList" [value]="country.phone_code" data-foo="dogs">
                            {{ country.emoji }}&nbsp;&nbsp;{{
                            country.name
                            }}&nbsp;&nbsp;(+{{ country.phone_code }})
                          </option>
                        </select>
                        <div *ngIf="
                              f.country_code.invalid &&
                              (f.country_code.dirty || f.country_code.touched)
                            " class="alert alert-danger mt-1">
                          Required.
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputnumber">Contact Number<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" id="exampleInputnumber" placeholder="Contact number"
                          formControlName="phone" />
                        <div *ngIf="
                            f.phone.invalid &&
                            (f.phone.dirty || f.phone.touched)
                          " class="alert alert-danger mt-1">
                          Valid Contact Number Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Alternate Number</label>
                        <input type="text" class="form-control" placeholder="Alternate Number"
                          formControlName="alternate_num" />
                        <div *ngIf="
                            f.alternate_num.invalid &&
                            (f.alternate_num.dirty || f.alternate_num.touched)
                          " class="alert alert-danger mt-1">
                          Valid Alternate Number Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <label for="exampleInputname1">Gender<strong class="text-danger">*</strong></label>
                      <!-- <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                            <label class="form-check-label" for="inlineRadio1">Male<strong class="text-danger">*</strong></label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                            <label class="form-check-label" for="inlineRadio2">Female<strong class="text-danger">*</strong></label>
                                        </div>
                                    </div> -->
                      <div class="col-xl-12 col-lg-12 col-md-12 p-0">
                        <select class="form-control" formControlName="gender">
                          <option value="" selected disabled>
                            Select Gender
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Profile Image<strong class="text-danger">*</strong></label>
                        <input type="file" class="form-control" placeholder="Profile Image"
                          (change)="onProfileImageUpload($event)" />
                        <div *ngIf="
                            f.profile_image.invalid &&
                            (f.profile_image.dirty || f.profile_image.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                        <div class="alert alert-danger mt-1" *ngIf="profileError != ''">
                          {{ profileError }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">Consultant Type<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="Consultant Type"
                          formControlName="consultant_type" readonly />
                        <div *ngIf="
                            f.consultant_type.invalid &&
                            (f.consultant_type.dirty ||
                              f.consultant_type.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Language Known<strong class="text-danger">*</strong></label>
                        <div>
                          <tag-input [onlyFromAutocomplete]="true" secondaryPlaceholder="Enter Languages"
                            formControlName="lang" placeholder="+Language" ng-model="[(languages)]">
                            <tag-input-dropdown [autocompleteItems]="languages" [identifyBy]="'value'"
                              [displayBy]="'display'" [showDropdownIfEmpty]="true" [dynamicUpdate]="false"
                              value="[lang]"></tag-input-dropdown>
                          </tag-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">Skills<strong class="text-danger">*</strong></label>
                        <div>
                          <tag-input [onlyFromAutocomplete]="true" secondaryPlaceholder="Enter Skills"
                            formControlName="skill" placeholder="+Skills">
                            <tag-input-dropdown [autocompleteItems]="skills" [showDropdownIfEmpty]="true"
                              [dynamicUpdate]="false" value="[skills]" [identifyBy]="'value'" [displayBy]="'display'">
                            </tag-input-dropdown>
                          </tag-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Address<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="Complete Address"
                          formControlName="complete_address" />
                        <div *ngIf="
                            f.complete_address.invalid &&
                            (f.complete_address.dirty ||
                              f.complete_address.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">City<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="City" formControlName="city" />
                        <div *ngIf="
                            f.city.invalid && (f.city.dirty || f.city.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">State<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="State" formControlName="state" />
                        <div *ngIf="
                            f.state.invalid &&
                            (f.state.dirty || f.state.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Country<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="Country" formControlName="country" />
                        <div *ngIf="
                            f.country.invalid &&
                            (f.country.dirty || f.country.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputname">Bank Account Number</label>
                                                <input type="text" class="form-control"
                                                    placeholder="Bank Account Number"
                                                    formControlName="bank_account_num">
                                                <div *ngIf="f.bank_account_num.invalid"
                                                    class="alert alert-danger mt-1">
                                                    Enter Only numbers.
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                  <!-- <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputname1">Account Type</label>
                                                <div>
                                                    <select class="form-control" formControlName="account_type">
                                                        <option value="" selected disabled>Select Account Type</option>
                                                        <option value="savings">Saving</option>
                                                        <option value="current">Current</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputname">Account Holder Name</label>
                                                <input type="text" class="form-control"
                                                    placeholder="Account Holder Name"
                                                    formControlName="account_holder_name">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputname1">IFSC</label>
                                                <input type="text" class="form-control" placeholder="IFSC"
                                                    formControlName="ifsc">
                                            </div>
                                        </div>
                                    </div> -->
                  <div class="row" *ngIf="!isWorkingWithOther">
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Pan Number(Optional)</label>
                        <input type="text" class="form-control" placeholder="Pan Number" formControlName="pan_number" />
                        <div *ngIf="
                            f.pan_number.invalid &&
                            (f.pan_number.dirty || f.pan_number.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">Aadhaar Number<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="Aadhaar Number"
                          formControlName="addhar_number" />
                        <div *ngIf="
                            f.addhar_number.invalid &&
                            (f.addhar_number.dirty || f.addhar_number.touched)
                          " class="alert alert-danger mt-1">
                          Required. Enter Valid Aadhar Card Number
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Working With Others<strong class="text-danger">*</strong></label>
                        <div class="col-xl-12 col-lg-12 col-md-12 p-0">
                          <select class="form-control" formControlName="working_with_other"
                            (change)="checkWorkingWith($event)">
                            <option value="" selected disabled>
                              Select Status
                            </option>
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="isWorkingWithOther">
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Pan Number(Optional)</label>
                        <input type="text" class="form-control" placeholder="Pan Number" formControlName="pan_number" />
                        <div *ngIf="
                            f.pan_number.invalid &&
                            (f.pan_number.dirty || f.pan_number.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">Aadhar Number<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="Aadhar Number"
                          formControlName="addhar_number" />
                        <div *ngIf="
                            f.addhar_number.invalid &&
                            (f.addhar_number.dirty || f.addhar_number.touched)
                          " class="alert alert-danger mt-1">
                          Required. Enter Valid Aadhar Card Number
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Working With Others<strong class="text-danger">*</strong></label>
                        <div class="col-xl-12 col-lg-12 col-md-12 p-0">
                          <select class="form-control" formControlName="working_with_other"
                            (change)="checkWorkingWith($event)">
                            <option value="" selected disabled>
                              Select Status
                            </option>
                            <option value="yes">yes</option>
                            <option value="no">no</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname">Employment Details
                          <strong class="required-text">*</strong></label>
                        <div class="col-xl-12 col-lg-12 col-md-12 p-0">
                          <textarea placeholder="Please give more information about your current work status"
                            class="form-control" formControlName="working_company"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">Pin Code<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="Pin Code" formControlName="pincode" />
                        <div *ngIf="
                            f.pincode.invalid &&
                            (f.pincode.dirty || f.pincode.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">Proof Image<strong class="text-danger">*</strong></label>
                        <input type="file" class="form-control" placeholder="Proof Image" name="proof_image"
                          (change)="onProofImageUpload($event)" />
                        <div *ngIf="
                            f.proof_image.invalid &&
                            (f.proof_image.dirty || f.proof_image.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                        <div class="alert alert-danger mt-1" *ngIf="proofError != ''">
                          {{ proofError }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label class="">Date Of Birth<strong class="text-danger">*</strong></label>
                        <div class="row">
                          <div class="col-lg-3 col-md-4 col-xl-4 col-sm-4">
                            <select class="form-control" formControlName="birth_date">
                              <option [selected]="true" disabled>Date</option>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                            </select>
                          </div>
                          <div class="col-lg-3 col-md-4 col-xl-4 col-sm-4">
                            <select class="form-control" formControlName="birth_month">
                              <option [selected]="true" disabled>Month</option>
                              <option value="01">Jan</option>
                              <option value="02">Feb</option>
                              <option value="03">Mar</option>
                              <option value="04">Apr</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">Aug</option>
                              <option value="09">Sep</option>
                              <option value="10">Oct</option>
                              <option value="11">Nov</option>
                              <option value="12">Dec</option>
                            </select>
                          </div>
                          <div class="col-lg-3 col-md-4 col-xl-4 col-sm-4">
                            <select class="form-control" formControlName="birth_year">
                              <option [selected]="true" disabled>Year</option>
                              <!-- <option>2021</option>
                                                            <option>2020</option>
                                                            <option>2019</option>
                                                            <option>2018</option>
                                                            <option>2017</option>
                                                            <option>2016</option>
                                                            <option>2015</option>
                                                            <option>2014</option>
                                                            <option>2013</option>
                                                            <option>2012</option>
                                                            <option>2012</option>
                                                            <option>2011</option>
                                                            <option>2010</option>
                                                            <option>2009</option>
                                                            <option>2008</option>
                                                            <option>2007</option>
                                                            <option>2006</option>
                                                            <option>2005</option>
                                                            <option>2004</option>
                                                            <option>2003</option> -->
                              <option>2002</option>
                              <option>2001</option>
                              <option>1999</option>
                              <option>1998</option>
                              <option>1997</option>
                              <option>1996</option>
                              <option>1995</option>
                              <option>1994</option>
                              <option>1993</option>
                              <option>1992</option>
                              <option>1991</option>
                              <option>1990</option>
                              <option>1989</option>
                              <option>1988</option>
                              <option>1987</option>
                              <option>1986</option>
                              <option>1985</option>
                              <option>1984</option>
                              <option>1983</option>
                              <option>1982</option>
                              <option>1981</option>
                              <option>1980</option>
                              <option>1979</option>
                              <option>1978</option>
                              <option>1977</option>
                              <option>1976</option>
                              <option>1975</option>
                              <option>1974</option>
                              <option>1973</option>
                              <option>1972</option>
                              <option>1971</option>
                              <option>1970</option>
                              <option>1969</option>
                              <option>1968</option>
                              <option>1967</option>
                              <option>1966</option>
                              <option>1965</option>
                              <option>1964</option>
                              <option>1963</option>
                              <option>1962</option>
                              <option>1961</option>
                              <option>1960</option>
                              <option>1959</option>
                              <option>1958</option>
                              <option>1957</option>
                              <option>1956</option>
                              <option>1954</option>
                              <option>1953</option>
                              <option>1952</option>
                              <option>1951</option>
                              <option>1950</option>
                              <option>1949</option>
                              <option>1948</option>
                              <option>1947</option>
                              <option>1946</option>
                              <option>1945</option>
                              <option>1944</option>
                              <option>1943</option>
                              <option>1942</option>
                              <option>1941</option>
                              <option>1940</option>
                              <option>1939</option>
                              <option>1938</option>
                              <option>1937</option>
                              <option>1936</option>
                              <option>1935</option>
                              <option>1934</option>
                              <option>1933</option>
                              <option>1932</option>
                              <option>1931</option>
                              <option>1930</option>
                              <option>1929</option>
                              <option>1928</option>
                              <!-- <option>1927</option>
                                                            <option>1926</option>
                                                            <option>1925</option>
                                                            <option>1924</option>
                                                            <option>1923</option>
                                                            <option>1922</option>
                                                            <option>1921</option> -->
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group">
                        <label for="exampleInputname1">Experience (Years)<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="Experience eg. 1, 10, 15"
                          formControlName="experience" />
                        <div *ngIf="
                            f.experience.invalid &&
                            (f.experience.dirty || f.experience.touched)
                          " class="alert alert-danger mt-1">
                          Required. Only Numbers Allowed
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12">
                      <div class="form-group">
                        <label class="">Short Bio<strong class="text-danger">*</strong></label>
                        <input class="form-control" placeholder="" formControlName="short_bio" />
                        <div *ngIf="
                            f.short_bio.invalid &&
                            (f.short_bio.dirty || f.short_bio.touched)
                          " class="alert alert-danger mt-1">
                          Required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="">About Me<strong class="text-danger">*</strong></label>
                    <textarea class="form-control" rows="6" formControlName="long_bio">
My bio.........</textarea>
                    <div *ngIf="
                        f.long_bio.invalid &&
                        (f.long_bio.dirty || f.long_bio.touched)
                      " class="alert alert-danger mt-1">
                      Required
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <input type="checkbox" formControlName="terms" class="mr-2" /><label class="custom-label">I've
                          read and accept the <a href="https://astroriver.com/terms-and-conditions-of-use/"
                            target="_blank">Terms & Conditions</a>, <a href="https://astroriver.com/privacy-policy/"
                            target="_blank">Privacy Policy</a>.</label>
                        <div *ngIf="
                            f.terms.invalid &&
                            (f.terms.dirty || f.terms.touched)
                          " class="alert alert-danger mt-1">
                          Please accept Terms and Conditions to SignUp.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-center">
                  <button type="submit" class="col-4 btn btn-success mt-1" [disabled]="!addAstrologerForm.valid">
                    Submit
                  </button>
                  <!--<button class="btn btn-danger mt-1 ml-2">Cancel</button>-->
                </div>
                <span class="text-danger text-center mb-4" *ngIf="!addAstrologerForm.valid">Fields with * are
                  required</span>
              </div>
            </div>
          </div>
        </form>
        <!-- ROW-1 CLOSED -->
      </div>
      <!--CONTAINER CLOSED -->
      <!-- CONTAINER END -->
    </div>

    <!-- SIDE-BAR -->
    <!-- <app-sidebar></app-sidebar> -->
    <!-- SIDE-BAR CLOSED -->

    <!-- FOOTER -->
    <app-footer></app-footer>
    <!-- FOOTER END -->
  </div>

  <!-- BACK-TO-TOP -->
  <a href="#top" id="back-to-top"><i class="fa fa-long-arrow-up"></i></a>
</span>