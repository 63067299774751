import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileServiceService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  getAstroProfile(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/myprofile', data, { headers: header_object })
  }

  updateServiceStatus(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/serviceonoff', data, { headers: header_object })
  }

  updateAstroProfile(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/requestprofileupdation', data, { headers: header_object })
  }

  updatePassword(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/changeownpassword', data, { headers: header_object })
  }

  /**
   * Earning and Payout
   */
  getCurrentMonthEarning(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/mycurrentearning', data, { headers: header_object })
  }

  getTotalEarning(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/mypayouthistory', data, { headers: header_object })
  }

  /**
   * Notification
   */
   getNotification(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/mynotifications', data, { headers: header_object })
  }

  /**
   * Waiting List
   */
  getWaitingList(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/mywaitinglist', data, { headers: header_object })
  }

  /**
   * Set Online Schedule
   */
  onlineSchedule(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/addOnlineTime', data, { headers: header_object })
  }

  /**
   * Get all astrologers
   */
   getAllAstrologers(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/allastrologers', data, { headers: header_object })
  }
}
