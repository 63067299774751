import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueServiceService } from 'src/app/astrologer/services/issue/issue-service.service';
import { NoteServiceService } from 'src/app/astrologer/services/note/note-service.service';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss']
})
export class ChatDetailComponent implements OnInit {
  suggestedRemedy: any;
  serverTime: any;
  isShowChatAcceptReject = false
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private issueService: IssueServiceService,
    private modalService: NgbModal,
    private noteService: NoteServiceService,
    private route: Router,
    private _lightbox: Lightbox,
    private _lightboxConfig: Lightbox,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  data: any = '';
  id: any = '';

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getServiceData()
  }

  getServiceData() {
    this.spinner.show()
    this.orderService.getOrderDetail({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.data = res.data
        this.serverTime = res.server_current_time
        this.suggestedRemedy = res.suggested_remedy
        this.isShowChatAcceptReject = this.data.chatId?.is_astrologer_accept == false && !this.data.chatId?.is_astrologer_acceptance_used && this.data.chatId?.is_end == false
        this.spinner.hide()
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/astrologer/orders/chat')
      }
    )
  }

  public convertSecondstoTime(val: any) {
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString = hours.toString().padStart(2, '0')
      + ':' + minutes.toString().padStart(2, '0')
      + ':' + seconds.toString().padStart(2, '0');
    return timeString
  }

  /**
   * Issue
   */
  openIssue(content) {
    this.modalService.open(content);
  }

  issueForm: FormGroup = new FormGroup({
    astroId: new FormControl(''),
    issue_type: new FormControl('2', Validators.required),
    is_service_issue: new FormControl(true, Validators.required),
    orderserviceId: new FormControl(''),
    amount: new FormControl(''),
    issue_title: new FormControl('', Validators.required),
    issue_description: new FormControl('', Validators.required),
  });

  get i() {
    return this.issueForm.controls
  }

  addIssue() {
    this.issueForm.patchValue({ astroId: this.userdata.getId() })
    this.issueForm.patchValue({ orderserviceId: this.data._id })
    this.issueForm.patchValue({ amount: this.data.astro_amount })
    this.issueService.addAstroIssue(this.issueForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.getServiceData()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.modalService.dismissAll()
      }
    )
  }

  /**
   * Note
   */
  open(content) {
    this.modalService.open(content);
  }

  noteForm: FormGroup = new FormGroup({
    noteId: new FormControl(''),
    note_description: new FormControl('', Validators.required)
  });

  addNote() {
    this.noteForm.patchValue({ noteId: this.data.noteId._id })
    this.noteService.addNote(this.noteForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.modalService.dismissAll()
      }
    )
  }

  /**
   * Review 
   */
  reviewReply: FormGroup = new FormGroup({
    chatId: new FormControl(''),
    astro_review_reply: new FormControl('', [Validators.required]),
    astroId: new FormControl(''),
  });

  get g() {
    return this.reviewReply.controls
  }

  reviewAnswer() {
    var msg = (this.reviewReply.get('astro_review_reply').value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply',"This message cannot be sent as our policy doesn't allows to share number or email",'error')
      return;
    }
    this.reviewReply.patchValue({ astro_review_reply: msg })
    this.spinner.show()
    this.reviewReply.patchValue({ chatId: this.data.chatId?._id })
    this.reviewReply.patchValue({ astroId: this.userdata.getId() })
    this.orderService.answerReviewChat(this.reviewReply.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.changeReviewReply = false
        this.getServiceData()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
     * Check Seven days
     */
  checkSevenDays(val) {
    var str = val;
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    //var enddateComponent = moment().utc().format('YYYY-MM-DD');
    var enddateComponent = moment(this.serverTime).utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(enddateComponent, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 7) {
      return true
    } else {
      return false
    }
  }

  /**
   * Check three days
   */
  checkThreeDays(val) {
    var str = val
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    //var enddateComponent = moment().utc().format('YYYY-MM-DD');
    var enddateComponent = moment(this.serverTime).utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(enddateComponent, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 3) {
      return true
    } else {
      return false
    }
  }

  changeFeedbackReply = false
  changeReviewReply = false

  updateFeedbackReply() {
    this.changeFeedbackReply = !this.changeFeedbackReply
  }

  updateReviewReply() {
    this.changeReviewReply = !this.changeReviewReply
  }
  /**
     * Feedback 
     */
  feedbackReply: FormGroup = new FormGroup({
    chatId: new FormControl(''),
    astro_feedback_reply: new FormControl('', [Validators.required]),
  });

  get h() {
    return this.feedbackReply.controls
  }

  feedbackAnswer() {
    var msg = (this.feedbackReply.get('astro_feedback_reply').value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply',"This message cannot be sent as our policy doesn't allows to share number or email",'error')
      return;
    }
    this.feedbackReply.patchValue({ astro_feedback_reply: msg })
    this.spinner.show()
    this.feedbackReply.patchValue({ chatId: this.data.chatId?._id })
    this.orderService.answerFeedbackChat(this.feedbackReply.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.changeFeedbackReply = false
        this.getServiceData()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Get attachment type
   */
   checkExtenstion(val: any) {
    var fileName, fileExtension;
    fileName = val
    fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toLowerCase();
    if (fileExtension == 'jpg' || fileExtension == 'png' || fileExtension == 'jpeg') {
      return 0
    } else if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'txt') {
      return 1
    } else if (fileExtension == 'xlsx' || fileExtension == 'xls' || fileExtension == 'csv') {
      return 2
    } else if (fileExtension == 'pdf') {
      return 3
    } else {
      return 4
    }
  }

  BaseUrl: any;

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  public getOpenUrl(url: string) {
    //return this.trusturl.bypassSecurityTrustUrl('http://docs.google.com/viewer?url=' + this.BaseUrl + url)
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
  private _albums:any = [];
  lightOpen(val: any): void {
    this._albums = []
    const album = {
      src: this.BaseUrl + val,
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, { wrapAround: true, showImageNumberLabel: true, fitImageInViewPort: true, centerVertically: true });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  /**
   * Reply to Issue
   */
   openIssueReply(content) {
    this.issueReplyForm.patchValue({astroId:this.userdata.getId()})
    this.issueReplyForm.patchValue({_id:this.data?.astro_issueId?._id})
    this.modalService.open(content);
  }

  issueReplyForm: FormGroup = new FormGroup({
    astroId: new FormControl(''),
    _id: new FormControl('', Validators.required),
    reply_by_system: new FormControl('', Validators.required),
    is_reply_by_astro: new FormControl(true),
  });

  get issueReply() {
    return this.issueReplyForm.controls
  }

  addReplyIssue() {
    var value = (this.issueReplyForm.get('reply_by_system')?.value).replace(/^\s+|\s+$/gm,'')
    if(value == '' || value == undefined || value == null){
      return
    }
    this.issueReplyForm.patchValue({reply_by_system:value})
    this.issueService.replyAstroIssue(this.issueReplyForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        //this.modalService.dismissAll()
        this.getServiceData()
        this.issueReplyForm.patchValue({reply_by_system:''})
      }, err => {
        this.toast.error(err.error.message, 'Error')
        //this.modalService.dismissAll()
      }
    )
  }

  acceptChat(isAccept:boolean){
    this.orderService.acceptRejectChat({ chatId: this.data.chatId?._id, is_astrologer_accept: isAccept }).subscribe(
      (res: any) => {
        //console.log(data.orderserviceId)
        location.reload()
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }
}
