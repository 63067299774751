import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IssueServiceService } from 'src/app/astrologer/services/issue/issue-service.service';
import { NoteServiceService } from 'src/app/astrologer/services/note/note-service.service';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { RemedyServiceService } from 'src/app/astrologer/services/remedy/remedy-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent implements OnInit {
  suggestedRemedy: any;
  serverTime: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: Router,
    private remedyService: RemedyServiceService,
    private modalService: NgbModal,
    private noteService: NoteServiceService,
    private issueService: IssueServiceService
  ) { }

  data: any = '';
  id: any = '';
  characterCount = 0
  orderData: any = ''

  private unsubscribe = new Subject<void>()
  
  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getReportSingle()
    this.formGroup.valueChanges.pipe(
      switchMap(formValue => this.orderService.saveReportTempAnswer({ reportId: this.id, temp_answer: this.formGroup.get('astro_answer').value })),
      takeUntil(this.unsubscribe)
    ).subscribe()
  }

  ngOnDestroy() {
    this.unsubscribe.next()
  }

  getReportSingle() {
    this.spinner.show()
    this.orderService.getReportSingle({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        if (!this.data.is_astro_answer) {
          this.checkSavedAnswer()
        } else {
          this.formGroup.patchValue({ astro_answer: this.data.astro_answer.replace(/<br *\/?>/gi, "\n") })
          this.countCharactersPrefill(this.data.astro_answer.replace(/<br *\/?>/gi, "\n"))
        }
        //this.formGroup.patchValue({astro_answer:this.data.astro_answer})
        if (this.data.orderserviceId.is_remedy_suggested) {
          this.getSuggestedRemedy()
        }
        this.getSingleService()
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/astrologer/orders/report')
      }
    )
  }

  getSingleService() {
    this.spinner.show()
    this.orderService.getOrderDetail({ _id: this.data.orderserviceId._id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderData = res.data
        this.serverTime = res.server_current_time
        this.suggestedRemedy = res.suggested_remedy
        this.noteForm.patchValue({ note_description: this.orderData.noteId.note_description })
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/astrologer/orders/report')
      }
    )
  }

  formGroup: FormGroup = new FormGroup({
    reportId: new FormControl(''),
    astro_answer: new FormControl('', [Validators.required]),
  });

  get f() {
    return this.formGroup.controls
  }

  countError = true
  countCharacters(event: any) {
    this.characterCount = event.target.value.replace(/\s/g, "").length
    if (this.characterCount >= 2000) {
      this.countError = false
    }
  }

  countCharactersPrefill(value) {
    this.characterCount = value.replace(/\s/g, "").length
    if (this.characterCount >= 2000) {
      this.countError = false
    }
  }

  reportAnswer() {
    var msg = (this.formGroup.get('astro_answer').value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply', "This message cannot be sent as our policy doesn't allows to share number or email", 'error')
      return;
    }
    this.formGroup.patchValue({ astro_answer: msg })
    this.spinner.show()
    this.formGroup.patchValue({ reportId: this.id })
    this.orderService.answerReport(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.reloadCurrentRoute()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  saveAnswer() {
    var msg = (this.formGroup.get('astro_answer').value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire("Answer cann't be saved.", "This message cannot be saved as our policy doesn't allows to share number or email", 'error')
      return;
    }
    this.formGroup.patchValue({ astro_answer: msg })
    this.orderService.saveReportTempAnswer({ reportId: this.id, temp_answer: this.formGroup.get('astro_answer').value }).subscribe(
      (res: any) => {
        this.toast.success('Answer Saved', 'Success')
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
    //localStorage.setItem(this.id,this.formGroup.get('astro_answer').value)
  }

  checkSavedAnswer() {
    //var data = localStorage.getItem(this.id)
    var data = this.data.temp_answer
    if (data != null && data != undefined && data != '') {
      this.formGroup.patchValue({ astro_answer: data })
      this.countCharactersPrefill(this.data.temp_answer.replace(/<br *\/?>/gi, "\n"))
      //this.characterCount = data.length
      this.f
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  feedbackReply: FormGroup = new FormGroup({
    reportId: new FormControl(''),
    astro_feedback_reply: new FormControl('', [Validators.required]),
  });

  get g() {
    return this.feedbackReply.controls
  }

  feedbackAnswer() {
    var msg = (this.feedbackReply.get('astro_feedback_reply').value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply', "This message cannot be sent as our policy doesn't allows to share number or email", 'error')
      return;
    }
    this.feedbackReply.patchValue({ astro_feedback_reply: msg })
    this.spinner.show()
    this.feedbackReply.patchValue({ reportId: this.id })
    this.orderService.answerReportFeedback(this.feedbackReply.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.reloadCurrentRoute()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  remedyData: any = ''
  getSuggestedRemedy() {
    this.remedyService.remedyDetail({ astroId: this.userdata.getId(), _id: this.data.orderserviceId.remedysuggestId }).subscribe(
      (res: any) => {
        this.remedyData = res.data
      }, err => {

      }
    )
  }

  open(content) {
    this.modalService.open(content);
  }

  noteForm: FormGroup = new FormGroup({
    noteId: new FormControl(''),
    note_description: new FormControl('', Validators.required)
  });

  addNote() {
    this.noteForm.patchValue({ noteId: this.orderData.noteId._id })
    this.noteService.addNote(this.noteForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.modalService.dismissAll()
      }
    )
  }

  /**
   * Issue
   */
  openIssue(content) {
    this.modalService.open(content);
  }

  issueForm: FormGroup = new FormGroup({
    astroId: new FormControl(''),
    issue_type: new FormControl('2', Validators.required),
    is_service_issue: new FormControl(true, Validators.required),
    orderserviceId: new FormControl(''),
    amount: new FormControl(''),
    issue_title: new FormControl('', Validators.required),
    issue_description: new FormControl('', Validators.required),
  });

  get i() {
    return this.issueForm.controls
  }

  addIssue() {
    this.issueForm.patchValue({ astroId: this.userdata.getId() })
    this.issueForm.patchValue({ orderserviceId: this.data.orderserviceId._id })
    this.issueForm.patchValue({ amount: this.data.astro_amount })
    this.issueService.addAstroIssue(this.issueForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.getReportSingle()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.modalService.dismissAll()
      }
    )
  }

  /**
   * Check Seven days
   */
  checkSevenDays(val) {
    var str = val;
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    //var enddateComponent = moment().utc().format('YYYY-MM-DD');
    var enddateComponent = moment(this.serverTime).utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(enddateComponent, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 7) {
      return true
    } else {
      return false
    }
  }

  /**
   * Check three days
   */
  checkThreeDays(val) {
    var str = val
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    //var enddateComponent = moment().utc().format('YYYY-MM-DD');
    var enddateComponent = moment(this.serverTime).utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(enddateComponent, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 3) {
      return true
    } else {
      return false
    }
  }

  changeFeedbackReply = false
  changeReviewReply = false

  updateFeedbackReply() {
    this.changeFeedbackReply = !this.changeFeedbackReply
  }

  updateReviewReply() {
    this.changeReviewReply = !this.changeReviewReply
  }

  /**
   * Reply 
   */
  reviewReply: FormGroup = new FormGroup({
    reportId: new FormControl(''),
    astro_review_reply: new FormControl('', [Validators.required]),
  });

  get r() {
    return this.reviewReply.controls
  }

  reviewAnswer() {
    var msg = (this.reviewReply.get('astro_review_reply').value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply', "This message cannot be sent as our policy doesn't allows to share number or email", 'error')
      return;
    }
    this.reviewReply.patchValue({ astro_review_reply: msg })
    this.spinner.show()
    this.reviewReply.patchValue({ reportId: this.orderData.reportId?._id })
    this.orderService.answerFeedbackReview(this.reviewReply.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.changeReviewReply = false
        this.reloadCurrentRoute()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Format Text
   */
  textFormat(value) {
    return value.replace(/(?:\r\n|\r|\n)/g, "<br>")
  }
}
