<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Remedy</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Suggest Remedy</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body row" *ngIf="!isRemedySuggested">
                    <div class="col-12">
                        <h3>Instructions: </h3>
                        <table class="table table-bordered">
                            <tr>
                                <td [innerHTML]="instruction"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="card-body row">
                    <div class="col-10">
                        <h3>Order Details: </h3>
                    </div>
                    <div class="col-12">
                        <table class="table table-bordered">
                            <tr>
                                <td><strong>Order Type: </strong> <span *ngIf="orderData?.is_call_order">Call</span><span *ngIf="orderData?.is_chat_order">Chat</span><span *ngIf="orderData?.is_query_order">Query</span><span *ngIf="orderData?.is_report_order">Report</span></td>
                                <td><strong>Order ID: </strong> #<span *ngIf="orderData?.is_call_order">{{orderData?.callId?.ocallId}}</span><span *ngIf="orderData?.is_chat_order">{{orderData?.chatId?.ochatId}}</span><span *ngIf="orderData?.is_query_order">{{orderData?.queryId?.oquestionId}}</span><span *ngIf="orderData?.is_report_order">{{orderData?.reportId?.oreportId}}</span></td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-12" *ngIf="isRemedySuggested">
                        <table class="table table-bordered">
                            <tr>
                                <th colspan="8" class="font-weight-bold">Remedy Details</th>
                            </tr>
                            <tr>
                                <th>Sno.</th>
                                <th>Type</th>
                                <th>Product</th>
                                <th>Description</th>
                                <th>Suggested Astro</th>
                                <th>Status</th>
                                <th>Action</th>
                                <th>Date</th>
                            </tr>
                            <ng-container *ngFor="let remedy of orderData?.remedysuggestIds; let i = index">
                                <tr [ngClass]="{'bg-success text-white':remedy?.remedyId?.isSuggestedProductBought}">
                                    <td>{{remedy?.remedyId?.rsId}}</td>
                                    <td>{{remedy?.remedyId?.isPaid ? 'PAID':'FREE'}} <span class="badge badge-danger" *ngIf="remedy?.remedyId?.isPaid">{{remedy?.remedyId?.isPaid && remedy?.remedyId?.is_created_by_astro_remedy ? 'Astro Suggested Product':'Astro Shop Product'}}</span></td>
                                    <td>
                                        <span *ngIf="remedy?.remedyId?.isPaid; else noProduct">
                                            {{remedy?.remedyId?.product_name == '' ? remedy?.remedyId?.suggestedProductId?.productname : remedy?.remedyId?.product_name}}
                                        </span>
                                        <ng-template #noProduct>-</ng-template>
                                    </td>
                                    <td>{{remedy?.remedyId?.remedy_description == '' || remedy?.remedyId?.remedy_description == undefined || remedy?.remedyId?.remedy_description == null ? '-': remedy?.remedyId?.remedy_description}}</td>
                                    <td>{{remedy?.remedyId?.suggestedastroId == '' || remedy?.remedyId?.suggestedastroId == undefined || remedy?.remedyId?.suggestedastroId == null ? '-':remedy?.remedyId?.suggestedastroId?.name}}</td>
                                    <td>{{remedy?.remedyId?.isSuggestedProductBought ? 'BOOKED':'NOT BOOKED'}}</td>
                                    <td><button *ngIf="!remedy?.remedyId?.isSuggestedProductBought" class="btn btn-sm btn-primary" (click)="deleteRemedy(remedy?.remedyId?._id)">Delete</button></td>
                                    <td>{{remedy?.remedyId?.created_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                    <div class="col-12"><button class="btn btn-sm btn-primary" *ngIf="!suggestNewRemedy" (click)="suggestNewRemedy = !suggestNewRemedy">Suggest Remedy</button></div>
                    <div class="col-12" *ngIf="suggestNewRemedy">
                        <form [formGroup]="formGroup" (submit)="suggestRemedy()">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="remedy_name">Remedy Type*</label>
                                        <mat-radio-group aria-label="Remedy Type" formControlName="isPaid"
                                            class="d-block" (change)="onChange($event)">
                                            <mat-radio-button [value]="false">Free</mat-radio-button>
                                            <mat-radio-button [value]="true">Paid</mat-radio-button>
                                        </mat-radio-group>

                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="isPaid">
                                    <div class="form-group">
                                        <label for="remedy_name">Remedy Type*</label>
                                        <mat-checkbox  class="d-block" formControlName="is_created_by_astro_remedy" (change)="astroCreatedRemedy($event)">Is this your created remedy?</mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isPaid">
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="remedy_name">Remedy Name</label>
                                        <input type="text" class="form-control text-capitalize" name="remedy_name"
                                            formControlName="remedy_name" placeholder="Remedy Name">
                                        <div *ngIf="f.remedy_name.invalid && (f.remedy_name.dirty || f.remedy_name.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="remedy_link">Remedy Link</label>
                                        <input type="text" class="form-control text-capitalize" name="remedy_link"
                                            formControlName="remedy_link" placeholder="Remedy Link">
                                        <div *ngIf="f.remedy_link.invalid && (f.remedy_link.dirty || f.remedy_link.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="remedy_name">Remedy Description*</label>
                                        <textarea formControlName="remedy_description" class="form-control"
                                            placeholder="Remedy Description"></textarea>
                                        <div *ngIf="f.remedy_description.invalid && (f.remedy_description.dirty || f.remedy_description.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isPaid && !isAstroCreated">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="suggestedCategoryId">Select Category*</label>
                                        <ng-select placeholder="Select Category" appendTo="body" [searchable]="true"
                                            [clearable]="true" (change)="onSelect($event)">
                                            <ng-option selected disabled value=''>Select Category</ng-option>
                                            <ng-option [value]="category._id" *ngFor="let category of categoryList">
                                                #{{category.categoryId}} - {{category.categoryname}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="isSubcategoryProduct">
                                    <div class="form-group">
                                        <label for="suggestedCategoryId">Select Subcategory*</label>
                                        <ng-select placeholder="Select Subcategory" appendTo="body" [searchable]="true"
                                            [clearable]="true" (change)="onSelectSubcategory($event)">
                                            <ng-option selected disabled value=''>Select Subcategory</ng-option>
                                            <ng-option [value]="subcategory._id" *ngFor="let subcategory of subcategoryList">
                                                #{{subcategory.subcategoryId}} - {{subcategory.subcategoryname}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="suggestedProductId">Select Product*</label>
                                        <ng-select placeholder="Select Product" appendTo="body" [searchable]="true"
                                            [clearable]="true" formControlName="suggestedProductId" (change)="onProductSelect($event)">
                                            <ng-option selected disabled value=''>Select Product</ng-option>
                                            <ng-option [value]="product._id" *ngFor="let product of productList">
                                                #{{product.productId}} - {{product.productname}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="suggestedastroId">Select Astrologer <span *ngIf="astrologerList?.length > 0">*</span></label>
                                        <ng-select [items]="astrologerList" placeholder="Select Astrologer" formControlName="suggestedastroId" bindLabel="astroId.name" bindValue="astroId._id">
                                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                <div style="margin: 10px 0;" class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title" [ngOptionHighlight]="search">{{item.astroId.displayname}}</h5>
                                                        <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
                                                        <p class="card-text">
                                                            <img height="50" width="50" [src]="getSanitizeUrl(item.astroId.profile_image)"/>
                                                            {{item.astroId.name}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                        <!-- <ng-select placeholder="Select Astrologer" appendTo="body" [searchable]="true"
                                            [clearable]="true" formControlName="suggestedastroId">
                                            <ng-option selected disabled value=''>Select Astrologer</ng-option>
                                            <ng-option [value]="astro.astroId._id" *ngFor="let astro of astrologerList">
                                                #{{astro.astroId.astroAutoId}} - {{astro.astroId.displayname}} ({{astro.astroId.name}})
                                            </ng-option>
                                        </ng-select> -->
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="remedy_name">Remedy Description*</label>
                                        <textarea formControlName="remedy_description" class="form-control"
                                            placeholder="Remedy Description"></textarea>
                                        <div *ngIf="f.remedy_description.invalid && (f.remedy_description.dirty || f.remedy_description.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isPaid && isAstroCreated">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="suggestedCategoryId">Select Category*</label>
                                        <ng-select placeholder="Select Category" appendTo="body" [searchable]="true"
                                            [clearable]="true" (change)="onSelect($event)" formControlName="categoryId" >
                                            <ng-option selected disabled value=''>Select Category</ng-option>
                                            <ng-option [value]="category._id" *ngFor="let category of categoryList">
                                                #{{category.categoryId}} - {{category.categoryname}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="isSubcategoryProduct">
                                    <div class="form-group">
                                        <label for="suggestedCategoryId">Select Subcategory*</label>
                                        <ng-select placeholder="Select Subcategory" appendTo="body" [searchable]="true"
                                            [clearable]="true" (change)="onSelectSubcategory($event)">
                                            <ng-option selected disabled value=''>Select Subcategory</ng-option>
                                            <ng-option [value]="subcategory._id" *ngFor="let subcategory of subcategoryList">
                                                #{{subcategory.subcategoryId}} - {{subcategory.subcategoryname}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="product_name">Product Name*</label>
                                        <input class="form-control" formControlName="product_name" placeholder="Enter Product Name"/>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="suggestedastroId">Assigned to</label>
                                        <input class="form-control"  value="Me" disabled/>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="remedyPrice">Remedy Price*</label>
                                        <input class="form-control" formControlName="remedyPrice" placeholder="Enter Remedy Price"/>
                                        <small>Enter total display amount without GST. You shall earn 50% of this amount.</small>
                                    </div>
                                    <div *ngIf="f.remedyPrice.invalid && (f.remedyPrice.dirty || f.remedyPrice.touched)"
                                            class="alert alert-danger mt-1">
                                            Only Valid Price Allowed.
                                        </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="remedy_name">Remedy Description*</label>
                                        <textarea formControlName="remedy_description" class="form-control"
                                            placeholder="Remedy Description"></textarea>
                                        <div *ngIf="f.remedy_description.invalid && (f.remedy_description.dirty || f.remedy_description.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="product_description">Product Description*</label>
                                        <textarea formControlName="product_description" class="form-control"
                                            placeholder="Product Description"></textarea>
                                        <div *ngIf="f.product_description.invalid && (f.product_description.dirty || f.product_description.touched)"
                                            class="alert alert-danger mt-1">
                                            Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="user-wrap d-flex justify-content-center">
                                        <button type="submit()" class="btn-form-control col-4 btn btn-success mt-1 mb-2"
                                            [disabled]="!formGroup.valid"> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->