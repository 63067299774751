import { Location } from '@angular/common';
import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/astrologer/services/login/login.service';
import { MallServiceService } from 'src/app/astrologer/services/mall-service/mall-service.service';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { RemedyServiceService } from 'src/app/astrologer/services/remedy/remedy-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-suggest-remedy',
  templateUrl: './suggest-remedy.component.html',
  styleUrls: ['./suggest-remedy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SuggestRemedyComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class SuggestRemedyComponent implements OnInit {

  @ViewChild('chosenuser') public ngSelect
  BaseUrl: any;

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private remedyService: RemedyServiceService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private orderService: OrderServiceService,
    private route: Router,
    private mallService: MallServiceService,
    private location: Location,
    private profileService: ProfileServiceService,
    private loginService: LoginService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  data: any = '';
  id: any = '';
  isPaid = false
  categoryList: any = []
  subcategoryList: any = []
  productList: any = []
  astrologerList: any = null
  orderData: any = ''

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.getProductCategory()
    this.getAllAstrologers()
    this.formGroup.patchValue({ astroId: this.userdata.getId() })
    this.formGroup.patchValue({ orderserviceId: this.id })
  }

  formGroup: FormGroup = new FormGroup({
    orderserviceId: new FormControl('', Validators.required),
    astroId: new FormControl(''),
    remedy_name: new FormControl(''),
    // remedy_link: new FormControl(''),
    customerId: new FormControl('', Validators.required),
    isPaid: new FormControl(false),
    is_astro_suggest_remedy: new FormControl(true),
    is_created_by_astro_remedy: new FormControl(false),
    categoryId: new FormControl(''),
    subcategoryId: new FormControl(''),
    product_name: new FormControl(''),
    remedyPrice: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    remedy_description: new FormControl(''),
    suggestedProductId: new FormControl(''),
    suggestedastroId: new FormControl(''),
    product_description: new FormControl(''),
  });


  getDataById() {
    this.spinner.show()
    this.orderService.getOrderDetail({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.orderData = res.data
        if (!this.orderData.is_remedy_suggested) {
          this.isRemedySuggested = false
          this.getRemedyInstruction()
        } else {
          this.isRemedySuggested = true
        }
        this.formGroup.patchValue({ customerId: res.data.customerId?._id });
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Add Remedy
 *********** */
  public suggestRemedy() {
    /// console.log(this.formGroup.get('categoryId').value,this.formGroup.get('product_name').value,this.formGroup.get('remedyPrice').value,this.formGroup.get('suggestedastroId').value)
    if (this.isPaid && !this.isAstroCreated) {
      if (this.formGroup.get('suggestedProductId').value == null || this.formGroup.get('suggestedProductId').value == undefined || this.formGroup.get('suggestedProductId').value == '') {
        this.toast.error('Please Select Category and Product', 'Error')
        return
      } else {
        if (this.astrologerList.length > 0 && (this.formGroup.get('suggestedastroId').value == '' || this.formGroup.get('suggestedastroId').value == null || this.formGroup.get('suggestedastroId').value == undefined)) {
          this.toast.error('Please Select Suggested Astrologer', 'Error')
          return
        }
      }
    }
    if (this.isPaid && this.isAstroCreated) {
      if ((this.formGroup.get('categoryId').value == null || this.formGroup.get('categoryId').value == undefined || this.formGroup.get('categoryId').value == '') || (this.formGroup.get('product_name').value == null || this.formGroup.get('product_name').value == undefined) || (this.formGroup.get('remedyPrice').value == null || this.formGroup.get('remedyPrice').value == undefined) || (this.formGroup.get('suggestedastroId').value == null || this.formGroup.get('suggestedastroId').value == undefined)) {
        this.toast.error('Please Select Category, Suggested Astrologer and Enter Product Name and Remedy Price', 'Error')
        return
      }
      if (this.formGroup.get('product_description').value == null || this.formGroup.get('product_description').value == undefined || this.formGroup.get('product_description').value == '') {
        this.toast.error('Please Enter Product Description', 'Error')
        return
      }
    }
    if (this.formGroup.get('remedy_description').value == null || this.formGroup.get('remedy_description').value == undefined || this.formGroup.get('remedy_description').value == '') {
      this.toast.error('Please Enter Remedy Description', 'Error')
      return
    }
    if (this.isSubcategoryProduct) {
      if (this.formGroup.get('subcategoryId').value == null || this.formGroup.get('subcategoryId').value == undefined || this.formGroup.get('subcategoryId').value == '') {
        this.toast.error('Please Select Subcategory', 'Error')
        return
      }
    } else {
      this.formGroup.patchValue({ subcategoryId: '' })
    }
    // else{
    //   if(this.formGroup.get('remedy_name').value == '' || this.formGroup.get('remedy_name').value == null || this.formGroup.get('suggestedastroId').value == undefined){
    //     this.toast.error('Please Enter Remedy Name','Error')
    //     return
    //   }
    // }
    // console.log(this.formGroup.get('suggestedastroId').value)
    //return
    this.spinner.show();
    this.remedyService.suggestRemedy(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        //console.log(res)
        this.toast.success('Remedy Suggested!', 'Success');
        //this.location.back()
        //this.route.navigateByUrl('/astrologer/remedy')
        this.getDataById()
        this.formGroup.reset()
        this.isPaid = false
        this.isAstroCreated = false
        this.formGroup.patchValue({ isPaid: false })
        this.formGroup.patchValue({ is_astro_suggest_remedy: true })
        this.formGroup.patchValue({ is_created_by_astro_remedy: false })
        this.formGroup.patchValue({ astroId: this.userdata.getId() })
        this.formGroup.patchValue({ orderserviceId: this.id })
      },
      err => {
        this.spinner.hide()
        //console.log(err)
        this.toast.error('Something went wrong!', 'Error');
      })
  }

  onChange(event: any) {
    this.isPaid = !this.isPaid
  }

  isAstroCreated: boolean = false
  astroCreatedRemedy(event: any) {
    this.isAstroCreated = !this.isAstroCreated
  }

  /**
   * Get All Product Category
   */
  public getProductCategory() {
    this.mallService.getProductCategoryList({ status: true }).subscribe(
      (res: any) => {
        this.categoryList = res.data
      }, err => {

      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  /**
   * Get all astrologers
   */
  allAstroList: any = null
  getAllAstrologers() {
    this.profileService.getAllAstrologers({}).subscribe(
      (res: any) => {
        this.allAstroList = res.data
      }, err => {

      }
    )
  }

  /** Get Category Detail */
  getProductCategoryById(id: any) {
    this.mallService.detailProductCategory({ _id: id }).subscribe(
      (res: any) => {
        if (res.data.is_subcategory_associated) {
          this.isSubcategoryProduct = true
          this.getProductSubcategory(id)
        } else {
          this.isSubcategoryProduct = false
          var data = { productcategoryId: id, status: true }
          this.getProductList(data)
        }
      }, err => {

      }
    )
  }

  /** Get all subcategory from categoryId */
  getProductSubcategory(id: any) {
    this.mallService.getProductSubcategoryByCategory({ status: true, categoryId: id }).subscribe(
      (res: any) => {
        this.subcategoryList = res.data
      }, err => {

      }
    )
  }

  isSubcategoryProduct = false

  onSelect(event: any) {//Select Category
    this.productList = []
    this.astrologerList = []
    this.formGroup.patchValue({ suggestedProductId: '' })
    this.formGroup.patchValue({ suggestedastroId: '' })
    if (event != '' && event != undefined) {
      this.getProductCategoryById(event)
    }
  }

  onSelectSubcategory(event: any) {//select subcategory
    if (event != '' && event != undefined) {
      this.formGroup.patchValue({ subcategoryId: event })
      var data = { productsubcategoryId: event, status: true }
      this.getProductList(data)
    }
  }

  onProductSelect(event: any) {
    this.astrologerList = []
    this.formGroup.patchValue({ suggestedastroId: '' })
    if (event != '' && event != undefined) {
      this.mallService.detailProduct({ _id: event }).subscribe(
        (res: any) => {
          this.astrologerList = res.data.astrologerList
        }, err => {

        }
      )
    }
  }

  /** Get Product List */
  getProductList(data: any) {
    this.mallService.getProductList(data).subscribe(
      (res: any) => {
        this.productList = res.data
      }, err => {

      }
    )
  }

  /** Get Remedy Instruction */
  instruction: any = '';
  isRemedySuggested = false
  suggestNewRemedy = false
  getRemedyInstruction() {
    this.loginService.getSingleCustomization({ customization_type: 10 }).subscribe(
      (res: any) => {
        this.instruction = res.data.customization_value
      }, err => {
        //this.toast.error(err.error.message,'Error')
      }
    )
  }

  deleteRemedy(id: any) {
    Swal.fire({
      title: 'Delete Suggested Remedy',
      text: 'Are you sure you want to delete suggested remedy?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No.',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.remedyService.deleteRemedy({ _id: id }).subscribe(
          (res: any) => {
            this.spinner.hide()
            this.toast.success('Suggested Remedy Deleted!', 'Success');
            this.getDataById()
          },
          err => {
            this.spinner.hide()
            this.toast.error('Something went wrong!', 'Error');
          })
      }
    });
  }
}
