import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PriceServiceService } from 'src/app/astrologer/services/price/price-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-unapproved-price',
  templateUrl: './unapproved-price.component.html',
  styleUrls: ['./unapproved-price.component.scss']
})
export class UnapprovedPriceComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private priceService: PriceServiceService,
    private toast: ToastrService,
    private route: Router
  ) { }

  priceList = [];
  temp: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getUnapprovedPriceList()
  }

  /* ***********
  list Call Orders
  *********** */

  getUnapprovedPriceList() {
    this.spinner.show()
    this.priceService.unapprovedPriceRequest({_id:this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.priceList = res.data;
        this.temp = this.priceList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }


}
