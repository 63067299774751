import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-earning',
  templateUrl: './earning.component.html',
  styleUrls: ['./earning.component.scss']
})
export class EarningComponent implements OnInit {

  constructor(
    private profileService: ProfileServiceService,
    private userdata: UserdataService
  ) { }

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  earningList: any = []
  temp: any = []

  ngOnInit(): void {
    this.getTotalEarning()
  }

  getTotalEarning() {
    this.profileService.getTotalEarning({ astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.earningList = res.data
        this.temp = this.earningList
      }, err => {

      }
    )
  }

  /**
* Update filter
*/
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.payoutyear.toString().indexOf(val) !== -1 ||
        d.payoutmonth.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.earningList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  getCurrentMonthEarning(){
    this.profileService.getCurrentMonthEarning({ astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.earningList = []
        this.earningList = res.data
        this.temp = this.earningList
      }, err => {

      }
    )
  }

  getLastThreeMonthEarning(){
    var month = moment().clone().month()+1;
    var year = moment().clone().year()
    this.profileService.getTotalEarning({ astroId: this.userdata.getId(),startmonth:month,payoutyear: year}).subscribe(
      (res: any) => {
        this.earningList = res.data
        this.temp = this.earningList
      }, err => {

      }
    )
  }

}
