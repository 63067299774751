<ngx-spinner></ngx-spinner>
<div class="pr-2 pl-2 d-flex justify-content-center">
    <h4>Your price for call <span class="text-primary">{{data.call_charges | currency:'INR':'symbol-narrow'}}</span></h4>
</div>
<!-- ROW-1 OPEN -->
<div class="row" id="user-profile">
    <div class="col-lg-12 pl-4 pr-4">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Update Call Price</h3>
            </div>
            <div class="card-body">
                <div>
                    <form [formGroup]="formGroup" (submit)="updatePrice()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="charges">Select Price</label>
                                    <select formControlName="charges" class="form-control">
                                    <option value="" selected disabled>Select Charges</option>
                                    <option *ngFor="let price of priceList">{{price}}</option>
                                </select>
                                    <div *ngIf="f.charges.invalid && (f.charges.dirty || f.charges.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="change_comment">Reason</label>
                                    <textarea formControlName="change_comment" class="form-control" placeholder="Write reason for updating price"></textarea>
                                    <div *ngIf="f.change_comment.invalid && (f.change_comment.dirty || f.change_comment.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row user-wrap justify-content-center">
                                    <button type="submit()" class="btn-form-custom col-sm-4 btn btn-success mt-1 mb-2" [disabled]="!formGroup.valid">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- COL-END -->
</div>
<!-- ROW-1 CLOSED -->