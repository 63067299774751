import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-report-order-list',
  templateUrl: './report-order-list.component.html',
  styleUrls: ['./report-order-list.component.scss']
})
export class ReportOrderListComponent implements OnInit {

  constructor(
    private orderService: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private router: Router
  ) { }

  reportList = []; temp = [];
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getReportOrders()
  }

  /* ***********
  list All Report Orders
  *********** */

  getReportOrders() {
    this.spinner.show()
    this.orderService.getReportOrders({ 'astroId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.customerId.name.toLowerCase().indexOf(val) !== -1 ||
        d.oreportId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.reportList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }
  /**
   * Not Answered Reports
   */
  notAnswered() {
    this.spinner.show()
    this.orderService.getReportOrders({ 'astroId': this.userdata.getId(), 'is_astro_answer': false }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        //console.log(this.reportList)
        this.temp = this.reportList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * No Astrologer feedback reply Reports
   */
  noAstrologerFeedbackReply() {
    this.spinner.show()
    this.orderService.getReportOrders({ 'astroId': this.userdata.getId(), 'is_astro_replied_feedback': false,is_feedback_given:true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportList = res.data;
        this.temp = this.reportList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  selected = [];
  SelectionType = SelectionType;
  onSelect({ selected }) {
    this.router.navigateByUrl('/astrologer/report/'+selected[0]._id)
    //console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

}
