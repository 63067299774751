import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-call-order-list',
  templateUrl: './call-order-list.component.html',
  styleUrls: ['./call-order-list.component.scss']
})
export class CallOrderListComponent implements OnInit {

  constructor(
    private orderService: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private router: Router
  ) { }

  callList = [];
  temp: any = [];
  selected = [];
  SelectionType = SelectionType;

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getCallOrderList()
  }

  /* ***********
  list Call Orders
  *********** */

  getCallOrderList() {
    this.spinner.show()
    this.orderService.getCallOrders({astroId:this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        this.temp = this.callList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId.name.toLowerCase().indexOf(val) !== -1 ||
        d.ocallId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.callList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  customerReview(){
    this.spinner.show()
    this.orderService.getCallOrders({astroId:this.userdata.getId(),is_order_reviewed:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        this.temp = this.callList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }
  
  noAstrologerReviewReply(){
    this.spinner.show()
    this.orderService.getCallOrders({astroId:this.userdata.getId(),is_order_reviewed:true,is_astro_replied_review:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        this.temp = this.callList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }
 
  showPromoCalls(){
    this.spinner.show()
    this.orderService.getCallOrders({astroId:this.userdata.getId(),is_promotional_call:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.callList = res.data;
        this.temp = this.callList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  public convertSecondstoTime(val:any) { 
    var given_seconds = val;   
    var dateObj = new Date(given_seconds * 1000); 
    var hours = dateObj.getUTCHours(); 
    var minutes = dateObj.getUTCMinutes(); 
    var seconds = dateObj.getSeconds(); 
  
    var timeString = hours.toString().padStart(2, '0') 
                + ':' + minutes.toString().padStart(2, '0') 
                + ':' + seconds.toString().padStart(2, '0'); 
    return timeString
  } 

  onSelect({ selected }) {
    this.router.navigateByUrl('/astrologer/call/'+selected[0].orderserviceId?._id)
    //console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }
}
