import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FestivalServiceService } from '../../services/festival-service/festival-service.service';
import { UserdataService } from '../../services/userdata/userdata.service';

@Component({
  selector: 'app-festival-list',
  templateUrl: './festival-list.component.html',
  styleUrls: ['./festival-list.component.scss']
})
export class FestivalListComponent implements OnInit {
  BaseUrl: any;

  constructor(
    private festivalService: FestivalServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getYearlyFestivalList()
  }

  /* ***********
  list Festival
  *********** */

  getYearlyFestivalList() {
    this.spinner.show()
    this.festivalService.listYearlyFestival({year:new Date().getFullYear()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.festivalId.festivalName.toLowerCase().indexOf(val) !== -1 ||d.year.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  detailData:any = ''
  /**
   * Detail Modal
   */
   openLg(content,data) {
     this.detailData = data
    this.modalService.open(content, { size: 'lg' });
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

}
