<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Blog Category</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/blog/category-list']">Category List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12 col-md-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Category Details </h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                    <table class="user-table-custom table row table-bordered">
                                                            <!-- <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Title :</strong> {{data.blogTitle}}</td>
                                                                </tr>
                                                            </tbody> -->
                                                            <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 pr-0">
                                                                <tr>
                                                                    <td><strong>ID :</strong> #{{data.categoryId}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                                                <tr>
                                                                    <td><strong>Name :</strong> {{data.categoryname}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-4 col-xl-4 col-sm-4 p-0">
                                                                <tr>
                                                                    <td><strong>Slug :</strong> {{data.category_slug}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Description :</strong> <div [innerHTML]="data.categorydescription"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Keywords :</strong> <div [innerHTML]="data.category_keyword"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>                                                           
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->