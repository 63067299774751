<!-- ROW-1 OPEN -->
<div >
    <form [formGroup]="passwordForm" (submit)="changePassword()" class="row" id="user-profile" >
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header"><h3 class="card-title">Change Password</h3></div>
            <div class="card-body">                 
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Old Password <strong class="required-text">*</strong></label>
                            <input type="password" class="form-control" 
                                placeholder="Old Password" formControlName="oldpassword"> 
                            <div *ngIf="f.oldpassword.invalid && (f.oldpassword.dirty || f.oldpassword.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">New Password<strong class="required-text">*</strong></label>
                            <input type="password" class="form-control" 
                                placeholder="New Password" formControlName="newpassword">
                            <div *ngIf="f.newpassword.invalid && (f.newpassword.dirty || f.newpassword.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">Confirm Password <strong class="required-text">*</strong></label>
                            <input type="password" class="form-control" 
                                placeholder="Confirm Password" formControlName="confirmpassword">
                                <div *ngIf="f.confirmpassword.invalid && (f.confirmpassword.dirty || f.confirmpassword.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>
                                <div *ngIf="passwordForm.errors?.checkPassword && (passwordForm.touched || passwordForm.dirty)" class="cross-validation-error-message alert alert-danger">
                                    Password and Confirm Password should match.
                                </div>
                        </div>                         
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
                <button type="submit" class="btn-form-custom btn btn-success mt-1 col-sm-4" [disabled]="!passwordForm.valid">Save</button>
            </div>
            <p class="text-danger m-auto" *ngIf="!passwordForm.valid">Fields with * are Required</p>
        </div>
    </div><!-- COL-END -->
    </form>
</div>
<!-- ROW-1 CLOSED -->