<div class="card">
    <div class="card-body">
        <div class="border-0">
            <div id="profile-log-switch">
                <div class="media-heading">
                    <h5 class="text-uppercase"><strong>Personal Information</strong></h5>
                </div>
                <hr class="m-0">
                <div class="table-responsive ">
                    <table class="table row table-borderless">
                        <tbody class="col-lg-12 col-xl-6 p-0">
                            <tr>
                                <td><strong>Display Name :</strong> {{data.displayname}}</td>
                            </tr>
                            <tr>
                                <td><strong>Full Name :</strong> {{data.name}}</td>
                            </tr>
                            <tr>
                                <td><strong>Languages :</strong><span *ngFor="let language of data.lang" class="text-capitalize"> {{language?.langId?.name}},</span></td>
                            </tr>
                            <tr>
                                <td><strong>Skills :</strong><span *ngFor="let skill of data.skill" class="text-capitalize"> {{skill?.skillId?.skillname}},</span></td>
                            </tr>
                            <tr>
                                <td><strong>Date Of Birth :</strong> {{data.birth_date}}-{{data.birth_month}}-{{data.birth_year}}</td>
                            </tr>
                            <tr>
                                <td><strong>Gender :</strong> {{data.gender}}</td>
                            </tr>
                            <tr>
                                <td><strong>Email :</strong> {{data.email}}</td>
                            </tr>
                            <tr>
                                <td><strong>Phone :</strong> +{{data.phone}} </td>
                            </tr>
                            <tr>
                                <td class="d-flex"><strong>Working With Other :</strong> 
                                    <ng-container
                                        *ngIf="data.working_with_other === true; then active; else Inactive">
                                    </ng-container>
                                    <ng-template #active>
                                        <span class="badge badge-success  mr-1">YES</span>
                                    </ng-template>
                                    <ng-template #Inactive>
                                        <span class="badge badge-danger  mr-1">No</span>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                        <tbody class="col-lg-12 col-xl-6 p-0">
                            <tr>
                                <td><strong>Alternative Number :</strong> +{{data?.alternate_num}} </td>
                            </tr>
                            <tr>
                                <td><strong>Consultant Type :</strong> {{data?.consultant_type}}</td>
                            </tr>
                            <tr>
                                <td><strong>Experience :</strong> {{data?.experience}} Years</td>
                            </tr>
                            <tr>
                                <td><strong>Complete Address :</strong> {{data?.complete_address}}</td>
                            </tr>
                            <tr>
                                <td><strong>City :</strong> {{data?.city}}</td>
                            </tr>
                            <tr>
                                <td><strong>state :</strong> {{data?.state}}</td>
                            </tr>
                            <tr>
                                <td><strong>country :</strong> {{data?.country}}</td>
                            </tr>
                            <tr>
                                <td><strong>pincode :</strong> {{data?.pincode}}</td>
                            </tr>
                        
                        </tbody>
                    </table>
                </div>
                <div class="media-heading">
                    <h5 class="text-uppercase"><strong>Proof Image</strong></h5>
                </div>
                <hr class="m-0">
                <div class="table-responsive ">
                    <table class="table row table-borderless">
                        <tbody class="col-lg-12 col-xl-6 p-0">
                            <tr>
                                <td><img class="" height="200px" [src]=getSanitizeUrl(data.proof_image) alt="Astro Image"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="media-heading">
                    <h5 class="text-uppercase"><strong>Bank Details</strong></h5>
                </div>
                <hr class="m-0">
                <div class="table-responsive ">
                    <table class="table row table-borderless">
                        <tbody class="col-lg-12 col-xl-6 p-0">
                            <tr>
                                <td><strong>Account Holder Name :</strong> {{data?.account_holder_name}}</td>
                            </tr>
                            <tr>
                                <td><strong>Bank Account Number :</strong> {{data?.bank_account_num}}</td>
                            </tr>
                            <tr>
                                <td><strong>Account Type :</strong> {{data?.account_type}}</td>
                            </tr>
                            <tr>
                                <td><strong>IFSC :</strong> {{data?.ifsc}}</td>
                            </tr>
                        </tbody>
                        <tbody class="col-lg-12 col-xl-6 p-0">
                            <tr>
                                <td><strong>Addhar Number :</strong> {{data?.addhar_number}}</td>
                            </tr>
                            <tr>
                                <td><strong>Pan Number:</strong> {{data?.pan_number}}</td>
                            </tr>
                            <tr>
                                <td><strong>Phone :</strong> +{{data?.phone}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row profie-img">
                    <div class="col-md-12">
                        <div class="media-heading">
                            <h5 class="text-uppercase"><strong>Short Biography</strong></h5>
                        </div>
                        <hr class="m-0 mb-3">
                        <p>{{data?.short_bio}}</p>
                    </div>
                </div>
                <div class="row profie-img">
                    <div class="col-md-12">
                        <div class="media-heading">
                            <h5 class="text-uppercase"><strong>Biography</strong></h5>
                        </div>
                        <hr class="m-0 mb-3">
                        <p>{{data?.long_bio}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>