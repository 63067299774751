<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-3" (keyup)="updateFilter($event)"   />
    <div class="mt-3">
        <button class="btn-filter-custom btn btn-secondary mr-2" (click)="getReportOrders()">All</button>
        <button class="btn-filter-custom btn btn-primary mr-2" (click)="notAnswered()">Not Answered</button>
        <button class="btn-filter-custom btn btn-info mr-2" (click)="noAstrologerFeedbackReply()">No Feedback Reply</button>
    </div>
</div>
  <br>
  <ngx-datatable class="material" [rows]="reportList" [columnMode]="'force'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;" [selected]="selected" [selectionType]="SelectionType.single" (activate)="onActivate($event)" (select)="onSelect($event)">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="oquestionId">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a class="text-primary" [routerLink]="['/astrologer/report/', row._id]">#{{row.oreportId}}</a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="4" name="Report Name" prop="reportId?.reportname">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.reportId?.reportname}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.customerId.name}} <span class="badge badge-pill badge-primary" >{{row.customerId?.regCountryName}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="astro_amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span class="price">{{row.astro_amount | currency :'INR':'symbol-narrow'}}</span>
        </ng-template>
      </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Status" prop="order_current_status">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.order_current_status}}
        </ng-template>
      </ngx-datatable-column>
    <!-- <ngx-datatable-column [flexGrow]="3" name="Actions">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a class="btn btn-warning btn-sm mr-1" [routerLink]="['/astrologer/report/', row._id]">Note</a>
        <a class="btn btn-primary btn-sm mr-1" [routerLink]="['/astrologer/report/', row._id]" *ngIf="row.order_current_status != 'Pending' ">Suggest</a>
        <a class="btn btn-secondary btn-sm" [routerLink]="['/astrologer/report/', row._id]" *ngIf="row.order_current_status != 'Pending' ">Help</a>
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
      <ng-template let-row="row" ngx-datatable-cell-template>
       {{row.created_at |date :'medium'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
      <ng-template let-row="row" ngx-datatable-cell-template>
          <a [routerLink]="['/astrologer/report/', row._id]"><i class="fa fa-eye"></i></a>
      </ng-template>
  </ngx-datatable-column>
  </ngx-datatable>
