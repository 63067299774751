<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Waiting List</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Waiting List</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div style="display: flex; justify-content: space-between;" class="pl-2 pr-2">
                        <input type="text"  placeholder="Type to filter the Customer Name..." class="form-control col-lg-3 mt-1" (keyup)="updateFilter($event)" />
                    </div>
                    <br>
                    <ngx-datatable class="material" [rows]="data" [columnMode]="'force'" [headerHeight]="50"
                        [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" style="position: relative !important;">
                        <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Waiting Id" prop="waitingId">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                #{{row.waitingId}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Type">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span *ngIf="row.is_chat" class="badge badge-success">CHAT</span>
                                <span *ngIf="row.is_call" class="badge badge-primary">CALL</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [sortable]="false" [flexGrow]="2" name="Customer Name">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.customerId.name}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Duration">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{calculateDuration(row.duration)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [sortable]="false" [flexGrow]="2" name="Date">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.created_at | date:'medium'}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>

                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->