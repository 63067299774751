import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MallServiceService } from 'src/app/astrologer/services/mall-service/mall-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-detail-mall-product',
  templateUrl: './detail-mall-product.component.html',
  styleUrls: ['./detail-mall-product.component.scss']
})
export class DetailMallProductComponent implements OnInit {
  BaseUrl: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private mallService: MallServiceService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private route: Router,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  data: any = '';
  reviewList:any = '';
  id: any = '';
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1,'dots':true,"autoplay":true};
  page =1;pageSize =10;
  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDetailProduct()
  }

  getDetailProduct(){
    this.spinner.show()
    this.mallService.detailProduct({_id:this.id}).subscribe(
      (res:any)=>{
        this.data = res.data
        this.reviewList = res.reviews
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
        this.route.navigateByUrl('/astrologer/mall-product/list')
      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

}
