import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  constructor(
    private profileService: ProfileServiceService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getNotifications()
  }

  notificationList:any = []
  page = 1; pageSize = 10;
  getNotifications(){
    this.profileService.getNotification({astroId:this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.notificationList = res.data
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
