<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Query</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/orders/query']">Query</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>

    <!-- MODAL NOTE-->
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Note For Customer</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="noteForm" (submit)="addNote()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="note_description">Note</label>
                    <div class="input-group">
                        <textarea class="form-control" formControlName="note_description"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark" [disabled]="!noteForm.valid">Save</button>
            </div>
        </form>
    </ng-template>
    <!-- MODAL NOTE END-->

    <!-- MODAL ISSUE-->
    <ng-template #contentIssue let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="issueForm" (submit)="addIssue()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="issue_title">Issue Title</label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="issue_title">
                    </div>
                    <div *ngIf="i.issue_title.invalid && (i.issue_title.dirty || i.issue_title.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
                <div class="form-group">
                    <label for="issue_description">Description</label>
                    <div class="input-group">
                        <textarea class="form-control" formControlName="issue_description"></textarea>
                    </div>
                    <div *ngIf="i.issue_description.invalid && (i.issue_description.dirty || i.issue_description.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark" [disabled]="!issueForm.valid">Save</button>
            </div>
        </form>
    </ng-template>
    <!-- MODAL ISSUE END-->
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-8 col-md-4">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Query Details
                                                            <span *ngIf="data.queryId?.is_first_order"
                                                                class="badge badge-success">AR NEW</span><span
                                                                *ngIf="data.queryId?.is_new_for_astrologer"
                                                                class="badge badge-success">NEW FOR YOU</span><span
                                                                *ngIf="!data.queryId?.is_first_order"
                                                                class="badge badge-info">AR REPEAT</span>
                                                        </h4>
                                                        <div>
                                                            <button
                                                                class="mr-2 btn btn-outline-primary btn-filter-custom"
                                                                *ngIf="data.queryId?.is_astro_answer && !data.is_remedy_suggested && checkSevenDays(data.queryId?.created_at)"
                                                                [routerLink]="['/astrologer/remedy/suggest',data._id]">Suggest
                                                                Remedy</button>
                                                            <button class="mr-2 btn-user-custom btn btn-outline-primary"
                                                                *ngIf="data.is_remedy_suggested "
                                                                [routerLink]="['/astrologer/remedy/suggest',data._id]">Check
                                                                Suggested Remedy</button>
                                                            <button
                                                                class="mr-2 btn btn-outline-secondary btn-filter-custom"
                                                                *ngIf="data.queryId?.is_astro_answer && !data.is_astro_issue_created"
                                                                (click)="openIssue(contentIssue)">Help</button>
                                                            <button class="btn btn-outline-warning btn-filter-custom"
                                                                (click)="open(content)">Note</button>
                                                        </div>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Question :</strong>
                                                                        {{data.queryId?.question}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Order ID
                                                                            :</strong> #{{data.queryId?.oquestionId}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong class="mr-1">Order On :</strong>
                                                                        {{data.queryId?.created_at | date :'medium'}}
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="data.queryId?.is_astro_answer">
                                                                    <td><strong class="mr-1">First Answer On :</strong>
                                                                        {{data.queryId?.first_answer_time |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Amount
                                                                            :</strong>{{data.queryId?.astro_amount |
                                                                        currency:'INR':'symbol-narrow'}} <span
                                                                            class="badge badge-pill badge-primary"
                                                                            *ngIf="data.queryId?.is_foreign_order">{{data.customerId?.regCountryName}}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong class="mr-1">Status :</strong><span
                                                                            *ngIf="!data.queryId?.is_astro_answer"
                                                                            class="badge badge-danger">Not
                                                                            Answered</span><span
                                                                            *ngIf="data.queryId?.is_astro_answer"
                                                                            class="badge badge-success">Answered</span>
                                                                        <p *ngIf="!data.queryId?.is_astro_answer && 
                                                                        !checkSevenDays(data.queryId?.created_at)"
                                                                            class="text-danger pt-2">Note: You can't
                                                                            answer query after 7 days.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="data.queryId?.is_astro_answer">
                                                                    <td><strong class="mr-1">Last Answer On :</strong>
                                                                        {{data.queryId?.last_answer_time |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.queryId?.is_astro_answer">
                                                                <tr>
                                                                    <td class="text-justify"><strong>Answer :</strong>
                                                                        <div
                                                                            [innerHtml]="textFormat(data.queryId?.astro_answer)">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="data.queryId?.is_feedback_given">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Feedback</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Question :</strong>
                                                                        {{data.queryId?.feedback_value}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="!data.queryId?.is_astro_replied_feedback && !checkThreeDays(data.queryId?.feedback_date)">
                                                                <tr>
                                                                    <td><strong>Note: You cannot reply to this feedback
                                                                            after 3 days.</strong></td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.queryId?.is_astro_replied_feedback">
                                                                <tr>
                                                                    <td><strong>Answer :</strong>
                                                                        {{data.queryId?.astro_feedback_reply}} <button
                                                                            class="btn btn-link"
                                                                            (click)="updateFeedbackReply()"
                                                                            *ngIf="checkThreeDays(data.queryId?.feedback_date)">Change</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Question Time :</strong>
                                                                        {{data.queryId?.feedback_date | date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.queryId?.is_astro_replied_feedback">
                                                                <tr>
                                                                    <td><strong>Answer Time:</strong>
                                                                        {{data.queryId?.astro_feedback_date |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <form [formGroup]="feedbackReply" (submit)="feedbackAnswer()"
                                                        *ngIf="!data.queryId?.is_astro_replied_feedback && checkThreeDays(data.queryId?.feedback_date)">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_feedback_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.astro_feedback_reply.invalid && (g.astro_feedback_reply.dirty || g.astro_feedback_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!feedbackReply.valid">
                                                                        Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <form [formGroup]="feedbackReply" (submit)="feedbackAnswer()"
                                                        *ngIf="data.queryId?.is_astro_replied_feedback && changeFeedbackReply">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_feedback_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.astro_feedback_reply.invalid && (g.astro_feedback_reply.dirty || g.astro_feedback_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!feedbackReply.valid">
                                                                        Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="data.queryId?.is_order_reviewed">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Review</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Review :</strong>
                                                                        {{data.queryId?.customer_review}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Stars :</strong>
                                                                        <ng-template #t let-fill="fill">
                                                                            <span class="star"
                                                                                [class.full]="fill === 100">
                                                                                <span class="half"
                                                                                    [style.widtr.%]="fill">&#x2605;</span>&#x2605;
                                                                            </span>
                                                                        </ng-template>

                                                                        <ngb-rating
                                                                            [(rate)]="data.queryId.customer_review_stars"
                                                                            [starTemplate]="t" [readonly]="true"
                                                                            [max]="5"></ngb-rating>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.queryId?.is_astro_replied_review">
                                                                <tr>
                                                                    <td><strong>Answer :</strong>
                                                                        {{data.queryId?.astro_review_reply}} <button
                                                                            class="btn btn-link"
                                                                            (click)="updateReviewReply()"
                                                                            *ngIf="checkThreeDays(data.queryId?.review_given_date)">Change</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="!data.queryId?.is_astro_replied_review && !checkThreeDays(data.queryId?.review_given_date)">
                                                                <tr>
                                                                    <td><strong>Note: You cannot reply to this review
                                                                            after 3 days.</strong></td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Reviewed On :</strong>
                                                                        {{data.queryId?.review_given_date |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.queryId?.is_astro_replied_review">
                                                                <tr>
                                                                    <td><strong>Replied On :</strong>
                                                                        {{data.queryId?.astro_review_date |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="!data.queryId?.is_astro_replied_review && checkThreeDays(data.queryId?.review_given_date)">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_review_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="r.astro_review_reply.invalid && (r.astro_review_reply.dirty || r.astro_review_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="data.queryId?.is_astro_replied_review && changeReviewReply">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_review_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="r.astro_review_reply.invalid && (r.astro_review_reply.dirty || r.astro_review_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="checkSevenDays(data.queryId?.created_at)">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Astrologer Answer</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <form [formGroup]="formGroup" (submit)="queryAnswer()">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <p class="text-right text-muted">
                                                                        {{characterCount}}/600 Characters</p>
                                                                    <textarea appBlockCopyPaste class="form-control"
                                                                        formControlName="astro_answer" rows="20"
                                                                        (keyup)="countCharacters($event)"></textarea>
                                                                    <p class="text-right text-muted">
                                                                        {{characterCount}}/600</p>
                                                                    <div *ngIf="countError"
                                                                        class="alert alert-danger mt-1">
                                                                        Minimum 600 characters required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="button"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2 mr-4"
                                                                        (click)="saveAnswer()">
                                                                        Save</button>
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!formGroup.valid || countError">
                                                                        Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
        <div class="col-lg-4 col-md-4">
            <div class="card productdesc">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Client
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row" *ngIf="data.customerId?.regCountryName">
                                            <div class="col-sm-4 text-muted">Registered Country</div>
                                            <div class="col-sm-8">{{data.customerId?.regCountryName}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.name">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.name}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.gender">
                                            <div class="col-sm-4 text-muted">Gender</div>
                                            <div class="col-sm-8">{{data.personId?.gender}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.marital_status">
                                            <div class="col-sm-4 text-muted">Marital Status</div>
                                            <div class="col-sm-8">{{data.personId?.marital_status}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.dob">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.dob}} {{data.personId?.tob}},
                                                {{data.personId?.city}}, {{data.personId?.state}},
                                                {{data.personId?.country}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.employed_in">
                                            <div class="col-sm-4 text-muted">Occupation</div>
                                            <div class="col-sm-8">{{data.personId?.employed_in}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.comments">
                                            <div class="col-sm-4 text-muted">Note</div>
                                            <div class="col-sm-8">{{data.personId?.comments}}</div>
                                        </li>
                                    </ul>
                                    <h4 class="mb-5 mt-3" *ngIf="data.personId?.is_partner_details">Partner Details</h4>
                                    <ul class="list-unstyled mb-0" *ngIf="data.personId?.is_partner_details">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.partner_name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.partner_dob}}
                                                {{data.personId?.partner_tob}}, {{data.personId?.partner_city}},
                                                {{data.personId?.partner_state}}, {{data.personId?.partner_country}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="card productdesc" *ngIf="data.is_remedy_suggested">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Suggested
                                            Remedy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">ID</div>
                                            <div class="col-sm-8">#{{suggestedRemedy?.rsId}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Remedy Type</div>
                                            <div class="col-sm-8"><span class="badge badge-info"
                                                    *ngIf="suggestedRemedy?.isPaid">PAID</span><span
                                                    class="badge badge-success"
                                                    *ngIf="!suggestedRemedy?.isPaid">FREE</span><span
                                                    class="badge badge-warning"
                                                    *ngIf="suggestedRemedy?.is_created_by_astro_remedy">OWN</span></div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && !suggestedRemedy?.is_created_by_astro_remedy">
                                            <div class="col-sm-4 text-muted">Product Name</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.suggestedProductId?.productname}}
                                            </div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && suggestedRemedy?.is_created_by_astro_remedy">
                                            <div class="col-sm-4 text-muted">Category Name</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.categoryId.categoryname}}
                                            </div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && suggestedRemedy?.is_created_by_astro_remedy">
                                            <div class="col-sm-4 text-muted">Product Name</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.product_name}}
                                            </div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.remedy_description != '' && suggestedRemedy?.remedy_description != null">
                                            <div class="col-sm-4 text-muted">Description</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.remedy_description}}</div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && suggestedRemedy?.suggestedastroId != null">
                                            <div class="col-sm-4 text-muted">Suggested Astrologer</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.suggestedastroId?.name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Suggested On</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.created_at | date:'medium'}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="card productdesc" *ngIf="data.is_astro_issue_created">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Issue
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Issue Id</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issueId}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Amount</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.amount}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Title</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issue_title}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Description</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issue_description}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Status</div>
                                            <div class="col-sm-8">
                                                <span class="badge badge-success"
                                                    *ngIf="data.astro_issueId?.is_issue_solved">SOLVED</span>
                                                <span class="badge badge-danger"
                                                    *ngIf="!data.astro_issueId?.is_issue_solved">PENDING</span>
                                            </div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Created At</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.created_at | date:'medium'}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.astro_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.reply_by_system}}</div>
                                        </li>

                                        <li class="row" *ngIf="data.astro_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.replyupdatedBy | date:'medium'}}
                                            </div>
                                        </li>
                                    </ul>
                                    <h4 class="mb-5 mt-3" *ngIf="data.personId?.is_partner_details">Partner Details</h4>
                                    <ul class="list-unstyled mb-0" *ngIf="data.personId?.is_partner_details">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.partner_name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.partner_dob}}
                                                {{data.personId?.partner_tob}}, {{data.personId?.partner_city}},
                                                {{data.personId?.partner_state}}, {{data.personId?.partner_country}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->