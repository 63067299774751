import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login/login.service';
import { MessagingService } from '../../services/messaging/messaging.service';
import { UserdataService } from '../../services/userdata/userdata.service';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isTermsAccepted = false;
  siteKey: string = '6Le3m2waAAAAAC1xu4U8248Sw6tWmJwweoyMUzu7';
  constructor(
    private toast: ToastrService,
    private ngxSpinner: NgxSpinnerService,
    private userdata: UserdataService,
    private router: Router,
    private loginService: LoginService,
    private messagingService: MessagingService,
    private authService: SocialAuthService
  ) {}

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    is_terms_accepted: new FormControl(false, [Validators.requiredTrue]),
    recaptcha: new FormControl('', [Validators.required]),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
    is_social_login: new FormControl(false),
  });

  get f() {
    return this.loginForm.controls;
  }

  loginSocialForm = new FormGroup({
    is_terms_accepted: new FormControl(false, [Validators.requiredTrue]),
    is_social_login: new FormControl(true),
    social_id: new FormControl(''),
    password: new FormControl(''),
    // recaptcha: new FormControl('', [Validators.required]),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
  });

  get s() {
    return this.loginSocialForm.controls;
  }

  ngOnInit(): void {
    if (this.userdata.getToken() != null) {
      this.router.navigateByUrl('/astrologer/dashboard');
    }
    this.messagingService.requestPermission();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.getLink();
  }

  acceptTerms() {
    this.isTermsAccepted = !this.isTermsAccepted;
    this.loginForm.patchValue({ is_terms_accepted: this.isTermsAccepted });
    this.loginSocialForm.patchValue({
      is_terms_accepted: this.isTermsAccepted,
    });
  }

  public onLogin() {
    //console.log(this.userdata.getFcm())
    if (
      this.userdata.getFcm() == null ||
      this.userdata.getFcm() == '' ||
      this.userdata.getFcm() == 'null'
    ) {
      Swal.fire({
        title: 'Notification Not Allowed.',
        text: 'You have not enabled browser notification for AstroRiver. This will result in no notification and Chat will be disabled from web. Are you sure, you want to continue like that?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No.',
      }).then((result) => {
        if (result.isConfirmed) {
          this.login();
        }
      });
    } else {
      this.login();
    }
  }

  login() {
    this.ngxSpinner.show();
    this.loginForm.patchValue({ fcm_token: this.userdata.getFcm() });
    this.loginService.deleteFcm({ token: this.userdata.getFcm() }).subscribe(
      (res: any) => {
        this.loginService.login(this.loginForm.value).subscribe(
          (res: any) => {
            this.ngxSpinner.hide();
            //console.log(res)
            if (res.success) {
              this.userdata.setData(res);
              if (!res.astrodetail.is_otp_verified) {
                this.router.navigate(['/verify-otp', res.data.email]);
              } else {
                this.toast.success('Login Successfully', 'Success');
                this.router.navigateByUrl('/astrologer/dashboard');
              }
            } else {
              this.toast.error(res.message, 'Error');
            }
          },
          (err) => {
            this.ngxSpinner.hide();
            this.toast.error(err.error.message, 'Error');
            this.reloadCurrentRoute();
          }
        );
      },
      (err) => {
        this.toast.error(err.error.message, 'Error');
      }
    );
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  link: any = '';
  getLink() {
    this.ngxSpinner.show();
    this.loginService
      .getSingleCustomization({ customization_type: 5 })
      .subscribe(
        (res: any) => {
          this.ngxSpinner.hide();
          this.link = res.data.customization_value;
        },
        (err) => {
          this.ngxSpinner.hide();
        }
      );
  }

  public signInWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((data) => {
        this.loginSocialForm.patchValue({ social_id: data.id });
        this.loginSocialForm.patchValue({ password: data.id });
        // this.loginSocialForm.patchValue({email:data.email})
        this.signOut();
        this.socialLogin();
      })
      .catch((err) => {
        //console.log(err)
        //this.toast.error(err,'Error')
      });
  }

  public signOut(): void {
    this.authService.signOut(true);
  }

  public onSocialLogin() {
    if (
      this.userdata.getFcm() == null ||
      this.userdata.getFcm() == '' ||
      this.userdata.getFcm() == 'null'
    ) {
      Swal.fire({
        title: 'Notification Not Allowed.',
        text: 'You have not enabled browser notification for AstroRiver. This will result in no notification and Chat will be disabled from web. Are you sure, you want to continue like that?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No.',
      }).then((result) => {
        if (result.isConfirmed) {
          this.signInWithGoogle();
        }
      });
    } else {
      this.signInWithGoogle();
    }
  }

  socialLogin() {
    this.ngxSpinner.show();
    this.loginSocialForm.patchValue({ fcm_token: this.userdata.getFcm() });
    this.loginService.deleteFcm({ token: this.userdata.getFcm() }).subscribe(
      (res: any) => {
        this.loginService.login(this.loginSocialForm.value).subscribe(
          (res: any) => {
            this.ngxSpinner.hide();
            if (res.success) {
              this.userdata.setData(res);
              if (!res.astrodetail.is_otp_verified) {
                this.router.navigate(['/verify-otp', res.data.email]);
              } else {
                this.toast.success('Login Successfully', 'Success');
                this.router.navigateByUrl('/astrologer/dashboard');
              }
            } else {
              this.toast.error(res.message, 'Error');
            }
          },
          (err) => {
            this.ngxSpinner.hide();
            this.toast.error(err.error.message, 'Error');
            this.reloadCurrentRoute();
          }
        );
      },
      (err) => {
        this.toast.error(err.error.message, 'Error');
      }
    );
  }
}
