<!-- DETAIL MODAL -->
<ng-template #content let-modal>
  <div class="modal-header">
      <h4 class="modal-title">Festival Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body p-4">
      <table class="table table-bordered">
          <tr>
              <td><strong>ID:</strong></td>
              <td>{{detailData.noteId}}</td>
          </tr>
          <tr>
              <td><strong>Customer:</strong></td>
              <td>{{detailData.customerId.name}}</td>
          </tr>
          <tr>
              <td><strong>Note:</strong></td>
              <td>{{detailData.note_description}}</td>
          </tr>
          <tr>
              <td><strong>Date:</strong></td>
              <td>{{detailData.created_at |date :'medium'}}</td>
          </tr>
          <tr>
            <td><strong>Edit:</strong></td>
            <td><a [routerLink]="['/astrologer/notes/add',detailData._id]" class="btn-user-custom btn btn-outline-primary btn-sm">Edit</a></td>
        </tr>
      </table>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
<!-- //DETAIL MODAL-->
<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
      (keyup)="updateFilter($event)"   />
  </div>
  <br>
  <ngx-datatable class="material" [rows]="noteList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
  <ngx-datatable-column [flexGrow]="1" name="Note ID" prop="noteId">
    <ng-template let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="openLg(content,row)">#{{row.noteId}}</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.customerId.name}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="Note" prop="note_description">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a (click)="openLg(content,row)">{{row.note_description}}</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
    <ng-template let-row="row" ngx-datatable-cell-template>
     {{row.created_at |date :'medium'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Edit">
    <ng-template let-row="row" ngx-datatable-cell-template>
     <a [routerLink]="['/astrologer/notes/add',row._id]" class="btn-user-custom btn btn-outline-primary btn-sm">Edit</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Action">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a (click)="openLg(content,row)"><i class="fa fa-eye text-primary"></i></a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>