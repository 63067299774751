import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserdataService } from '../../services/userdata/userdata.service';
import { WalletServiceService } from '../../services/wallet/wallet-service.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private walletService: WalletServiceService,
    private userdata: UserdataService
  ) { }

  data: any = '';
  temp: any = '';
  radioValue=0
  startDate:any = ''; endDate:any = '';
  astroWallet = ''

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.startDate = moment().clone().format('YYYY-MM-DD')
    this.endDate = this.startDate
    this.getAstroWalletHistory()
  }

  getAstroWalletHistory() {
    this.spinner.show()
    this.walletService.getAstroWalletHistory({ astroId: this.userdata.getId(),startdate:this.startDate, enddate:this.endDate }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.astroWallet = res.astrowallet
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroCreditedWalletHistory() {
    this.spinner.show()
    this.walletService.getAstroWalletHistory({ astroId: this.userdata.getId(), txn_type: 1,startdate:this.startDate, enddate:this.endDate }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroDebitedWalletHistory() {
    this.spinner.show()
    this.walletService.getAstroWalletHistory({ astroId: this.userdata.getId(), txn_type: 2,startdate:this.startDate, enddate:this.endDate }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  /**
 * Update filter
 */
   updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.awalletId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.data = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  searchDate(val){
    var startDate;var endDate;
    if(val == 0){
      startDate = moment().clone().format('YYYY-MM-DD')
      endDate = startDate
    }
    if(val == 1){
      startDate = moment().clone().subtract(1,'days').format('YYYY-MM-DD')
      endDate = startDate
    }
    if(val == 2){
      startDate = moment().clone().startOf('month').format('YYYY-MM-DD')
      endDate = moment().clone().endOf('month').format('YYYY-MM-DD')
    }
    if(val == 3){
      startDate = moment().clone().startOf('month').subtract(1,'months').format('YYYY-MM-DD')
      endDate = moment().clone().startOf('month').subtract(1,'months').endOf('month').format('YYYY-MM-DD')
    }
    if(val == 4){
      startDate = moment().clone().startOf('month').subtract(6,'months').format('YYYY-MM-DD')
      endDate = moment().clone().startOf('month').subtract(1,'months').endOf('month').format('YYYY-MM-DD')
    }
    this.startDate = startDate
    this.endDate = endDate
    this.getAstroWalletHistory()
  }
}
