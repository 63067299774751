<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Festival List</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Festival List</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- DETAIL MODAL -->
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Festival Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body p-4">
            <table class="table table-bordered">
                <tr>
                    <td><strong>Name:</strong></td>
                    <td>{{detailData.festivalId.festivalName}}</td>
                </tr>
                <tr>
                    <td><strong>Description:</strong></td>
                    <td>{{detailData.festivalId.festivalDescription}}</td>
                </tr>
                <tr>
                    <td><strong>Date:</strong></td>
                    <td>{{detailData.festivalDate | date:'mediumDate'}}</td>
                </tr>
                <tr>
                    <td><strong>Image:</strong></td>
                    <td><img [src]="getSanitizeUrl(detailData.festivalId.festivalImage)" class="img img-fluid img-thumbnail"/></td>
                </tr>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        </div>
    </ng-template>
    <!-- //DETAIL MODAL-->
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div style="display: flex; justify-content: space-between;" class="pl-2 pr-2">
                        <input type="text"  placeholder="Type to filter the Customer
                            Name..." class="form-control col-lg-3 mt-1" (keyup)="updateFilter($event)" />
                    </div>
                    <br> 
                    <ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50"
                        [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10"
                        [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                        <ngx-datatable-column [flexGrow]="2" name="Name" prop="festivalId.festivalName">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span [title]="row.festivalId.festivalName">{{row.festivalId.festivalName}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Year">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.year}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Festival Date">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.festivalDate | date:'mediumDate'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" name="Action">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <a href="javascript:void(0)" (click)="openLg(content,row)" class="btn-table-custom btn btn-sm btn-outline-primary mr-1">View</a>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>

                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->