import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupportServiceService } from 'src/app/astrologer/services/support/support-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-common-support',
  templateUrl: './common-support.component.html',
  styleUrls: ['./common-support.component.scss']
})
export class CommonSupportComponent implements OnInit {
  issueList: any;

  constructor(
    private supportService: SupportServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }

  ngOnInit(): void {
    this.pendingIssue()
  }

  /**
   * pending Issue
   */
   pendingIssue(){
    this.spinner.show()
    this.supportService.getAstroSupport({'astroId':this.userdata.getId(),'is_issue_solved':false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
