import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallPriceComponent } from './astrologer/component/price/call-price/call-price.component';
import { LayoutComponent } from './astrologer/component/layout/layout.component';
import { LoginComponent } from './astrologer/component/login/login.component';
import { MainDashboardComponent } from './astrologer/component/main-dashboard/main-dashboard.component';
import { CallDetailComponent } from './astrologer/component/orders/call-detail/call-detail.component';
import { CallOrderListComponent } from './astrologer/component/orders/call-order-list/call-order-list.component';
import { OrdersComponent } from './astrologer/component/orders/orders.component';
import { QueryDetailComponent } from './astrologer/component/orders/query-detail/query-detail.component';
import { QueryOrderListComponent } from './astrologer/component/orders/query-order-list/query-order-list.component';
import { ReportDetailComponent } from './astrologer/component/orders/report-detail/report-detail.component';
import { ReportOrderListComponent } from './astrologer/component/orders/report-order-list/report-order-list.component';
import { PriceComponent } from './astrologer/component/price/price.component';
import { QueryPriceComponent } from './astrologer/component/price/query-price/query-price.component';
import { SuggestRemedyComponent } from './astrologer/component/remedy/suggest-remedy/suggest-remedy.component';
import { ReportPriceComponent } from './astrologer/component/price/report-price/report-price.component';
import { WalletComponent } from './astrologer/component/wallet/wallet.component';
import { AuthGuard } from './auth/authguard';
import { ProfileComponent } from './astrologer/component/profile/profile.component';
import { ShowProfileComponent } from './astrologer/component/profile/show-profile/show-profile.component';
import { MyReviewComponent } from './astrologer/component/profile/my-review/my-review.component';
import { EditProfileComponent } from './astrologer/component/profile/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './astrologer/component/profile/change-password/change-password.component';
import { EarningComponent } from './astrologer/component/profile/earning/earning.component';
import { NotificationComponent } from './astrologer/component/profile/notification/notification.component';
import { WaitingListComponent } from './astrologer/component/waiting-list/waiting-list.component';
import { OfferComponent } from './astrologer/component/offer/offer.component';
import { CallOfferComponent } from './astrologer/component/offer/call-offer/call-offer.component';
import { ChatOfferComponent } from './astrologer/component/offer/chat-offer/chat-offer.component';
import { QueryOfferComponent } from './astrologer/component/offer/query-offer/query-offer.component';
import { ReportOfferComponent } from './astrologer/component/offer/report-offer/report-offer.component';
import { ReviewComponent } from './astrologer/component/review/review.component';
import { VerifyOtpComponent } from './astrologer/component/verify-otp/verify-otp.component';
import { VerifyEmailOtpComponent } from './astrologer/component/verify-email-otp/verify-email-otp.component';
import { IssueComponent } from './astrologer/component/issue/issue.component';
import { ListIssueComponent } from './astrologer/component/issue/list-issue/list-issue.component';
import { ChatOrderListComponent } from './astrologer/component/orders/chat-order-list/chat-order-list.component';
import { UnapprovedPriceComponent } from './astrologer/component/price/unapproved-price/unapproved-price.component';
import { NotesComponent } from './astrologer/component/notes/notes.component';
import { ListNotesComponent } from './astrologer/component/notes/list-notes/list-notes.component';
import { AddNotesComponent } from './astrologer/component/notes/add-notes/add-notes.component';
import { WalletDetailsComponent } from './astrologer/component/wallet/wallet-details/wallet-details.component';
import { PayoutDetailsComponent } from './astrologer/component/wallet/payout-details/payout-details.component';
import { ActiveOfferComponent } from './astrologer/component/offer/active-offer/active-offer.component';
import { ChatDetailComponent } from './astrologer/component/orders/chat-detail/chat-detail.component';
import { CommonSupportComponent } from './astrologer/component/support/common-support/common-support.component';
import { ListSupportComponent } from './astrologer/component/support/list-support/list-support.component';
import { AddSupportComponent } from './astrologer/component/support/add-support/add-support.component';
import { DetailSupportComponent } from './astrologer/component/support/detail-support/detail-support.component';
import { OngoingChatAstrologerComponent } from './astrologer/component/orders/ongoing-chat-astrologer/ongoing-chat-astrologer.component';
import { ChatPriceComponent } from './astrologer/component/price/chat-price/chat-price.component';
import { SolvedIssueComponent } from './astrologer/component/issue/solved-issue/solved-issue.component';
import { AddAstrologerComponent } from './astrologer/component/add-astrologer/add-astrologer.component';
import { CommonMallCategoryComponent } from './astrologer/component/mall-category/common-mall-category/common-mall-category.component';
import { ListMallCategoryComponent } from './astrologer/component/mall-category/list-mall-category/list-mall-category.component';
import { DetailMallCategoryComponent } from './astrologer/component/mall-category/detail-mall-category/detail-mall-category.component';
import { CommonMallProductComponent } from './astrologer/component/mall-product/common-mall-product/common-mall-product.component';
import { ListMallProductComponent } from './astrologer/component/mall-product/list-mall-product/list-mall-product.component';
import { NonAssociateMallProductComponent } from './astrologer/component/mall-product/non-associate-mall-product/non-associate-mall-product.component';
import { AssociateMallProductComponent } from './astrologer/component/mall-product/associate-mall-product/associate-mall-product.component';
import { RequestMallProductComponent } from './astrologer/component/mall-product/request-mall-product/request-mall-product.component';
import { DetailMallProductComponent } from './astrologer/component/mall-product/detail-mall-product/detail-mall-product.component';
import { ApplyMallProductComponent } from './astrologer/component/mall-product/apply-mall-product/apply-mall-product.component';
import { CommonBlogComponent } from './astrologer/component/blog/common-blog/common-blog.component';
import { ListBlogCategoryComponent } from './astrologer/component/blog/list-blog-category/list-blog-category.component';
import { DetailBlogCategoryComponent } from './astrologer/component/blog/detail-blog-category/detail-blog-category.component';
import { FestivalListComponent } from './astrologer/component/festival-list/festival-list.component';
import { MallOrderListComponent } from './astrologer/component/orders/mall-order-list/mall-order-list.component';
import { ListBlogComponent } from './astrologer/component/blog/list-blog/list-blog.component';
import { AddBlogComponent } from './astrologer/component/blog/add-blog/add-blog.component';
import { ListMyBlogComponent } from './astrologer/component/blog/list-my-blog/list-my-blog.component';
import { DetailBlogComponent } from './astrologer/component/blog/detail-blog/detail-blog.component';
import { EditBlogComponent } from './astrologer/component/blog/edit-blog/edit-blog.component';
import { MallDetailComponent } from './astrologer/component/orders/mall-detail/mall-detail.component';
import { GroupChatAstrologerComponent } from './astrologer/component/orders/group-chat-astrologer/group-chat-astrologer.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AddAstrologerComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'verify-otp/:id',
    component: VerifyOtpComponent,
  },
  {
    path: 'register',
    component: AddAstrologerComponent,
  },
  {
    path: 'astrologer',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: MainDashboardComponent,
      },
      {
        path: 'verify-email',
        component: VerifyEmailOtpComponent,
      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'call',
            component: CallOrderListComponent,
          },
          {
            path: 'report',
            component: ReportOrderListComponent,
          },
          {
            path: 'query',
            component: QueryOrderListComponent,
          },
          {
            path: 'chat',
            component: ChatOrderListComponent,
          },
          {
            path: 'store',
            component: MallOrderListComponent,
          },
        ],
      },
      {
        path: 'call/:id',
        component: CallDetailComponent,
      },
      {
        path: 'report/:id',
        component: ReportDetailComponent,
      },
      {
        path: 'query/:id',
        component: QueryDetailComponent,
      },
      {
        path: 'chat/:id',
        component: ChatDetailComponent,
      },
      {
        path: 'store/:id',
        component: MallDetailComponent,
      },
      {
        path: 'remedy/suggest/:id',
        component: SuggestRemedyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'wallet',
        component: WalletComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'wallet-details', component: WalletDetailsComponent },
          { path: 'payout-details', component: PayoutDetailsComponent },
        ],
      },
      {
        path: 'price',
        component: PriceComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'call',
            component: CallPriceComponent,
          },
          {
            path: 'chat',
            component: ChatPriceComponent,
          },
          {
            path: 'query',
            component: QueryPriceComponent,
          },
          {
            path: 'report',
            component: ReportPriceComponent,
          },
          {
            path: 'unapproved',
            component: UnapprovedPriceComponent,
          },
        ],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'view',
            component: ShowProfileComponent,
          },
          {
            path: 'review',
            component: MyReviewComponent,
          },
          {
            path: 'edit',
            component: EditProfileComponent,
          },
          {
            path: 'password',
            component: ChangePasswordComponent,
          },
          {
            path: 'earning',
            component: EarningComponent,
          },
          {
            path: 'notification',
            component: NotificationComponent,
          },
        ],
      },
      {
        path: 'waiting-list',
        component: WaitingListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'offers',
        component: OfferComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'call', component: CallOfferComponent },
          { path: 'chat', component: ChatOfferComponent },
          { path: 'query', component: QueryOfferComponent },
          { path: 'report', component: ReportOfferComponent },
          { path: 'active', component: ActiveOfferComponent },
        ],
      },
      {
        path: 'review',
        component: ReviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'issue',
        component: IssueComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ListIssueComponent },
          { path: 'solved', component: SolvedIssueComponent },
        ],
      },
      {
        path: 'notes',
        component: NotesComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ListNotesComponent },
          { path: 'add/:id', component: AddNotesComponent },
        ],
      },
      {
        path: 'supports/:id',
        component: DetailSupportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'support',
        component: CommonSupportComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ListSupportComponent },
          { path: 'add', component: AddSupportComponent },
        ],
      },
      /**
       * Chat with customer
       */
      {
        path: 'chat-ongoing/:id',
        component: OngoingChatAstrologerComponent,
        canActivate: [AuthGuard],
      },

      /**
       * MileStone 3
       */
      /**
       * Product Category
       */
      {
        path: 'mall-category',
        component: CommonMallCategoryComponent,
        canActivate: [AuthGuard],
        children: [{ path: 'list', component: ListMallCategoryComponent }],
      },
      {
        path: 'malls-category/:id',
        component: DetailMallCategoryComponent,
        canActivate: [AuthGuard],
      },
      /**
       * Product
       */
      {
        path: 'mall-product',
        component: CommonMallProductComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ListMallProductComponent },
          {
            path: 'non-associate',
            component: NonAssociateMallProductComponent,
          },
          { path: 'associate', component: AssociateMallProductComponent },
          { path: 'request', component: RequestMallProductComponent },
        ],
      },
      {
        path: 'malls-product/:id',
        component: DetailMallProductComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'malls-product/apply/:id',
        component: ApplyMallProductComponent,
        canActivate: [AuthGuard],
      },
      /**
       * Blog
       */
      {
        path: 'blog',
        component: CommonBlogComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'category-list', component: ListBlogCategoryComponent },
          { path: 'list', component: ListBlogComponent },
          { path: 'my-blog', component: ListMyBlogComponent },
          { path: 'add', component: AddBlogComponent },
        ],
      },
      {
        path: 'blogs/category/:id',
        component: DetailBlogCategoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'blogs/view/:id',
        component: DetailBlogComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'blogs/:id',
        component: EditBlogComponent,
        canActivate: [AuthGuard],
      },
      /**
       * Festival List
       */
      {
        path: 'festival',
        component: FestivalListComponent,
        canActivate: [AuthGuard],
      },
      /**
       * Group Chat with customer and admin
       */
      {
        path: 'groupchat-ongoing/:id',
        component: GroupChatAstrologerComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
