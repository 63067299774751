import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { UserdataService } from '../astrologer/services/userdata/userdata.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private userdata: UserdataService, private router: Router, private toast: ToastrService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userdata.getPhoneVerify() != 'false' && this.userdata.getToken() != null) {
            return true
        } else {
            this.toast.info('Login Required', 'Note:')
            //this.router.navigateByUrl('/login')
            // not logged in so redirect to login page with the return url and return false
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return false
        }
    }
}