import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  BASEURI: string = ""
  BASEOPEN:string = ""
  constructor(private userdata:UserdataService ,private http: HttpClient, @Inject('BASE_URL') _baseUrl:  string, @Inject('BASE_OPEN_URL') _baseOpenUrl:  string) {
    this.BASEURI = _baseUrl
    this.BASEOPEN = _baseOpenUrl
   }

  login(form: any){
    return this.http.post(this.BASEURI + '/loginastrologer', form)
  }

  /**
   * Check If Social ID exists
   */
  checkSocialExists(form: any){
    return this.http.post(this.BASEURI + '/loginastrologer', form)
  }

  logout(form: any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BASEURI + '/logoutastrologer', form, { headers: header_object })
  }

  verifyEmailOtp(form: any){
    return this.http.post(this.BASEURI + '/verifyotpemail', form)
  }

  resendEmailOtp(form: any){
    return this.http.post(this.BASEURI + '/sendotponmail', form)
  }

  verifyMobileOtp(form: any){
    return this.http.post(this.BASEURI + '/verifyotp', form)
  }

  resendMobileOtp(form: any){
    return this.http.post(this.BASEURI + '/resendtopphone', form)
  }

  /**
   * Get Single Customization
   */
   getSingleCustomization(data:any){
    return this.http.post(this.BASEURI + '/showsinglcustomization', data)
  } 

  /**
   * Delete FCM Token
   */
   deleteFcm(form: any){
    return this.http.post(this.BASEOPEN + '/deletefcm', form)
  }

  /**
   * Add Astrologer Open Api
   */
   public addAstrologerOpen(data:any){
    return this.http.post(this.BASEURI + "/addastrologer", data);
  }

  /**
   * Get country list
   */
   public getCountryList(data:any){
    return this.http.get(this.BASEURI + "/country", data);
  }
}
