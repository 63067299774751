<div class="pr-2 pl-2 d-flex justify-content-between">
</div>
<br>
<ngx-datatable class="material" [rows]="priceList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Charges" prop="charges_logs.last_charge">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.charges_logs.last_charge | currency :'INR':'symbol-narrow'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Updated Charg." prop="charges_logs.new_charge">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.charges_logs.new_charge | currency :'INR':'symbol-narrow'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Type" prop="charges_logs.charge_type">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge badge-success mr-2 text-uppercase">{{row.charges_logs.charge_type}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Remarks" prop="charges_logs.change_comment">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <small [title]="row.charges_logs.change_comment">{{row.charges_logs.change_comment}}</small>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.charges_logs.updatedDate |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>