<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-3"
      (keyup)="updateFilter($event)"   />
    <div class="mt-3">
      <button class="btn-filter-custom btn btn-secondary mr-2" (click)="getChatOrderList()">All</button>
      <button class="btn-filter-custom btn btn-primary mr-2" (click)="customerReview()">Customer Reviewed</button>
      <button class="btn-filter-custom btn btn-info mr-2" (click)="noAstrologerReviewReply()">No Review Reply</button>
      <button class="btn-filter-custom btn btn-danger mr-2" (click)="showPromoChats()">Promo Chats</button>
    </div>
  </div>
  <br>
  <ngx-datatable class="material" [rows]="chatList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;" [selected]="selected" [selectionType]="SelectionType.single" (activate)="onActivate($event)" (select)="onSelect($event)">
  <ngx-datatable-column [flexGrow]="1" name="Chat ID" prop="ochatId">
    <ng-template let-row="row" ngx-datatable-cell-template>
        <!-- <a [routerLink]="['/astrologer/chat/', row?.orderserviceId?._id]">{{row?.ochatId}}</a> -->
        <button class="btn btn-link text-primary" (click)="checkStatus(row)"> #{{row?.ochatId}}</button>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row?.customerId?.name}} <span class="badge badge-pill badge-primary">{{row?.customerId?.regCountryName}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Amount" prop="astro_amount">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="price">{{row?.astro_amount | currency :'INR':'symbol-narrow'}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Is Promo Chat ?">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row?.is_promotional_chat && !row?.is_report_promo_chat && !row?.is_direct_promo_chat"
                class="badge badge-success mr-2">FIRST PROMO</span>
            <span *ngIf="row?.is_promotional_chat && row?.is_report_promo_chat" class="badge badge-success">REPORT
                PROMO</span>
            <span *ngIf="row?.is_promotional_chat && row?.is_direct_promo_chat && !row?.is_paid_promotional_chat"
                class="badge badge-success">FREE
                PROMO</span>
            <span *ngIf="row?.is_promotional_chat && row?.is_direct_promo_chat && row?.is_paid_promotional_chat"
                class="badge badge-success">PAID
                PROMO</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="Status" prop="order_current_status">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row?.order_current_status}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
    <ng-template let-row="row" ngx-datatable-cell-template>
     {{row?.created_at |date :'medium'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" name="Action">
    <ng-template let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="['/astrologer/chat/', row?.orderserviceId?._id]"><i class="fa fa-eye"></i></a>
    </ng-template>
</ngx-datatable-column>
</ngx-datatable>