import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class OrderServiceService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  /**
   * Service Orders
   */
  public getAllOrderList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/allserviceorder", data, { headers: header_object });
  }

  public getOrderDetail(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/singleserviceorder", data, { headers: header_object });
  }

  /**
   * Call Orders
   */

  getCallOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/mycallorder', data, { headers: header_object })
  }

  getCallSingle(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/singlecallorder', data, { headers: header_object })
  }

  answerReview(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/callreplyfeedback', data, { headers: header_object })
  }

  answerFeedbackCall(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/feedbackcallreply', data, { headers: header_object })
  }

  /**
   * Query Orders
   */
  getQueryOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/myquestionorder', data, { headers: header_object })
  }

  getQuerySingle(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/singlequestionorder', data, { headers: header_object })
  }

  answerQuery(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/savequestionanswer', data, { headers: header_object })
  }

  answerQueryFeedback(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/questionreplyfeedback', data, { headers: header_object })
  }

  answerQueryReview(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/questionreplyreview', data, { headers: header_object })
  }

  /**
   * Report Orders
   */
  getReportOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/myreportorder', data, { headers: header_object })
  }

  getReportSingle(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/singlereportorder', data, { headers: header_object })
  }

  answerReport(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/savereportanswer', data, { headers: header_object })
  }

  answerReportFeedback(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/reportreplyfeedback', data, { headers: header_object })
  }

  answerFeedbackReview(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/reportreplyreview', data, { headers: header_object })
  }

  /**
   * Chat Orders
   */
   getChatOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/mychatorder', data, { headers: header_object })
  }

  getChatSingle(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/singleChatorder', data, { headers: header_object })
  }

  answerReviewChat(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/chatreplyfeedback', data, { headers: header_object })
  }

  answerFeedbackChat(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/feedbackchatreply', data, { headers: header_object })
  }

  getRecentChats(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/myrecentchatorder', data, { headers: header_object })
  }

  /**
   * Accept/Reject Chat Request
   */
   public acceptRejectChat(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/acceptchat", data, { headers: header_object });
  }

  /**
   * Save chat message
   */
   public saveChatMsg(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/savechatmsgastrologer", data, { headers: header_object });
  }

  /**
   * End chat
   */
   public endChat(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/endchatastro", data, { headers: header_object });
  }

  /**
   * Save Answer For Query
   */
   public saveQueryTempAnswer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/tempsavequestionanswer", data, { headers: header_object });
  }

  /**
   * Save Answer For Report
   */
   public saveReportTempAnswer(data:any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/tempsavereportanswer", data, { headers: header_object });
  }

  /**
   * Store Orders
   */
   getStoreOrders(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/getOrders', data, { headers: header_object })
  }

  getStoreSingle(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/getOrdersById', data, { headers: header_object })
  }

  answerStoreReview(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/replyOrderReview', data, { headers: header_object })
  }

  updateStoreStatus(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/updateOrder', data, { headers: header_object })
  }

  /**
   * Group Chat
   */
  getMyGroupChats(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/astrogroupchats', data, { headers: header_object })
  }

  getDetailGroupChats(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/singlegroupchat', data, { headers: header_object })
  }

  sendGroupChatMsg(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/sendmsgingroup', data, { headers: header_object })
  }

  /**
   * Connect Call through Group Chat
   */
   directCallFromGroupChat(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + '/directCallFromGroupChat', data, { headers: header_object })
  }
}
