<div class="pr-2 pl-2 d-flex justify-content-between align-items-end">
    <input type="text"  placeholder="Type to filter the year and month column..." class="form-control
        col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
    <div class="mt-3">
        <button class="btn-filter-custom btn btn-secondary mr-2"
            (click)="getTotalEarning()">All</button>
        <button class="btn-filter-custom btn btn-primary mr-2"
            (click)="getCurrentMonthEarning()">Current Month Earning</button>
            <button class="btn-filter-custom btn btn-info mr-2"
            (click)="getLastThreeMonthEarning()">Last 3 Month Earning</button>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="earningList" [columnMode]="'force'"
    [headerHeight]="50" [footerHeight]="50" [rowHeight]="60"
    [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column name="ID" prop="payoutId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row?.payoutId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Month"
        prop="payoutmonth">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.payoutmonth}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Year" prop="payoutyear">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.payoutyear}}
        </ng-template>
    </ngx-datatable-column>                                                
    <ngx-datatable-column name="Payout" prop="totalpayout">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.totalpayout | currency:'INR' : 'symbol-narrow'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="PG Amount" prop="pgamount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.pgamount | currency:'INR' : 'symbol-narrow'}} (- {{row.pgchargeper}}%)
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Total Payout" prop="finalpayout">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.finalpayout | currency:'INR' : 'symbol-narrow'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>