import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueServiceService } from 'src/app/astrologer/services/issue/issue-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import { WalletServiceService } from 'src/app/astrologer/services/wallet/wallet-service.service';

@Component({
  selector: 'app-wallet-details',
  templateUrl: './wallet-details.component.html',
  styleUrls: ['./wallet-details.component.scss']
})
export class WalletDetailsComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private walletService: WalletServiceService,
    private userdata: UserdataService,
    private issueService: IssueServiceService,
    private modalService: NgbModal,
    private toast: ToastrService
  ) { }

  data: any = '';
  temp: any = '';
  radioValue = 0
  startDate: any = ''; endDate: any = '';
  astroWallet = ''

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.startDate = moment().clone().format('YYYY-MM-DD')
    this.endDate = this.startDate
    this.getAstroWalletHistory()
  }

  getAstroWalletHistory() {
    this.spinner.show()
    this.walletService.getAstroWalletHistory({ astroId: this.userdata.getId(), startdate: this.startDate, enddate: this.endDate }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.astroWallet = res.astrowallet
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroCreditedWalletHistory() {
    this.spinner.show()
    this.walletService.getAstroWalletHistory({ astroId: this.userdata.getId(), txn_type: 1, startdate: this.startDate, enddate: this.endDate }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public getAstroDebitedWalletHistory() {
    this.spinner.show()
    this.walletService.getAstroWalletHistory({ astroId: this.userdata.getId(), txn_type: 2, startdate: this.startDate, enddate: this.endDate }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.temp = this.data
      },
      err => {
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.awalletId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.data = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  searchDate(val) {
    var startDate; var endDate;
    if (val == 0) {
      startDate = moment().clone().format('YYYY-MM-DD')
      endDate = startDate
    }
    if (val == 1) {
      startDate = moment().clone().subtract(1, 'days').format('YYYY-MM-DD')
      endDate = startDate
    }
    if (val == 2) {
      startDate = moment().clone().startOf('month').format('YYYY-MM-DD')
      endDate = moment().clone().endOf('month').format('YYYY-MM-DD')
    }
    if (val == 3) {
      startDate = moment().clone().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
      endDate = moment().clone().startOf('month').subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    }
    if (val == 4) {
      startDate = moment().clone().startOf('month').subtract(6, 'months').format('YYYY-MM-DD')
      endDate = moment().clone().startOf('month').subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    }
    this.startDate = startDate
    this.endDate = endDate
    this.getAstroWalletHistory()
  }

  /**
   * Issue Create
   */
  id: any = ''; amount: any = ''; issueData: any = '';
  openIssue(content, id, amount) {
    this.id = id
    this.amount = amount
    this.modalService.open(content);
  }

  issueForm: FormGroup = new FormGroup({
    astroId: new FormControl(''),
    issue_type: new FormControl('1', Validators.required),
    is_wallet_issue: new FormControl(true, Validators.required),
    walletId: new FormControl(''),
    amount: new FormControl(''),
    issue_title: new FormControl('', Validators.required),
    issue_description: new FormControl('', Validators.required),
  });

  get i() {
    return this.issueForm.controls
  }

  addIssue() {
    this.issueForm.patchValue({ astroId: this.userdata.getId() })
    this.issueForm.patchValue({ walletId: this.id })
    this.issueForm.patchValue({ amount: this.amount })
    this.issueService.addAstroIssue(this.issueForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.getAstroWalletHistory()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.modalService.dismissAll()
      }
    )
  }
  /**
   * Issue View
   */
  openViewIssue(content, data) {
    this.modalService.open(content);
    this.issueData = data
  }

}
