import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SupportServiceService } from 'src/app/astrologer/services/support/support-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-add-support',
  templateUrl: './add-support.component.html',
  styleUrls: ['./add-support.component.scss']
})
export class AddSupportComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private supportService: SupportServiceService,
    private toast: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  formGroup:FormGroup = new FormGroup({
    astroId: new FormControl(''),
    is_astrologer: new FormControl(true,Validators.required),
    support_title: new FormControl('',Validators.required),
    support_description: new FormControl('',Validators.required),
  });

  get f() {
    return this.formGroup.controls
  }

  addSupport(){
    this.formGroup.patchValue({astroId:this.userdata.getId()})
    this.supportService.addAstroSupport(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/astrologer/support/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
