import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class MallServiceService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  /**
   * Get Product Category
   */
  public getProductCategoryList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/allproductcategory", data, { headers: header_object });
  }

  /**
   * Get Single Category
   */
   public detailProductCategory(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/fetchproductcategorybyid", data, { headers: header_object });
  }

  /**
   * Get Product 
   */
   public getProductList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/allproducts", data, { headers: header_object });
  }

  /**
   * Get Single Product
   */
   public detailProduct(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/fetchproductbyid", data, { headers: header_object });
  }

  /**
   * Get Product Associated to me
   */
   public getProductAssociatedList(data: any) {//astroId
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/productassociatetome", data, { headers: header_object });
  }

  /**
   * Get Product Not Associated to me
   */
   public getProductNotAssociatedList(data: any) {//astroId
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/productnotassociatewithme", data, { headers: header_object });
  }

  /**
   * Apply for  Product
   */
   public applyForProduct(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/addProductRequest", data, { headers: header_object });
  }

  /**
   * Get Product Request
   */
   public getProductRequest(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/getmyproductrequests", data, { headers: header_object });
  }

  /**
   * Get Product Subcategory By Id
   */
   public getProductSubcategoryByCategory(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/allproductsubcategory", data, { headers: header_object });
  }
}