import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueServiceService } from 'src/app/astrologer/services/issue/issue-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-solved-issue',
  templateUrl: './solved-issue.component.html',
  styleUrls: ['./solved-issue.component.scss']
})
export class SolvedIssueComponent implements OnInit {

  issueList = [];
  temp: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  constructor(private issueService: IssueServiceService, private spinner: NgxSpinnerService, private userdata: UserdataService, private toast: ToastrService, private modalService: NgbModal) { }

  data:any;
  ngOnInit(): void {
    this.data = { astroId: this.userdata.getId(), is_reply_by_system: true, is_issue_solved: true }
    this.getSolvedIssue()
  }
  
  getSolvedIssue() {
    this.spinner.show()
    this.issueService.getAstroIssue(this.data).subscribe(
      (res: any) => {
        this.issueList = res.data
        this.temp = this.issueList
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.issueId.toString().indexOf(val) !== -1 || d.issue_title.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.issueList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Issue View
   */
   issueData:any = ''
   openViewIssue(content, data) {
    this.modalService.open(content,{ size: 'xl' });
    this.issueData = data
    //console.log(this.issueData)
  }

  /**
   * Filter
   */
   radioValue = 'all'
   searchIssue(val:any){
     if(val == 'wallet'){
       this.data = { astroId: this.userdata.getId(), is_reply_by_system: true, is_issue_solved: true, is_wallet_issue: true }
     }
 
     if(val == 'service'){
       this.data = { astroId: this.userdata.getId(), is_reply_by_system: true, is_issue_solved: true, is_service_issue: true }
     }
 
     if(val == 'store'){
       this.data = { astroId: this.userdata.getId(), is_reply_by_system: true, is_issue_solved: true, is_order_issue: true }
     }
 
     if(val == 'all'){
       this.data = { astroId: this.userdata.getId(), is_reply_by_system: true, is_issue_solved: true }
     }
     this.getSolvedIssue()
   }

}
