<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Product Details</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/mall-product/list']">Product List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-8 col-md-4">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Details </h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">

                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Product ID
                                                                            :</strong> #{{data.productId}}</td>
                                                                </tr>

                                                            </tbody>
                                                            <tbody
                                                                class="border-0  col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Product Name :</strong>
                                                                        {{data.productname}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12  col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Description :</strong>
                                                                        <span [innerHTML]="data.productdescription"></span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td>
                                                                        <span *ngIf="data.is_direct_price">
                                                                            <strong>Price: </strong>
                                                                            <ng-container
                                                                                *ngIf="!data.is_offer_applied">
                                                                                <span class="price">{{data.price |
                                                                                    currency:'INR':'symbol-narrow'}}</span>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="data.is_offer_applied">
                                                                                <span
                                                                                    class="price mr-1 text-decoration-line-through">{{data.original_amount
                                                                                    |
                                                                                    currency:'INR':'symbol-narrow'}}</span>

                                                                                <span class="price">{{data.price |
                                                                                    currency:'INR':'symbol-narrow'}}</span>
                                                                                (-{{data.offer_percentage}}%)
                                                                            </ng-container>
                                                                        </span>
                                                                        <span *ngIf="data.is_astrologer_associate">
                                                                            <strong>Min. Price: </strong>
                                                                            <span class="price">{{data.min_price |
                                                                                currency:'INR':'symbol-narrow'}}</span>
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                            <tbody
                                                                class="border-0  col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Slug :</strong>
                                                                        {{data.product_slug}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12  col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Keywords :</strong>
                                                                        {{data.product_keyword}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body" *ngIf="data.addon_detail.length > 0">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Add-On List </h4>
                                                    </div>
                                                    <!-- <hr class="m-0"> -->
                                                    <div class="row pt-2 pb-2 border">
                                                        <div class="col-4"><strong>Image</strong></div>
                                                        <div class="col-4"><strong>Name</strong></div>
                                                        <div class="col-4"><strong>Price</strong></div>
                                                    </div>
                                                    <ng-container *ngFor="let addon of data.addon_detail">
                                                        <div class="row pt-2 pb-2 border">
                                                            <div class="col-4"><img
                                                                    [src]="getSanitizeUrl(addon.addon_image)" alt=""
                                                                    class="img img-fluid img-thumbnail"
                                                                    style="height: 50px;"></div>
                                                            <div class="col-4">{{addon.addon_name}}</div>
                                                            <div class="col-4 price">{{addon.addon_price |
                                                                currency:'INR':'symbol-narrow'}}</div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body" *ngIf="data.astrologerList.length > 0">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Astrologer List </h4>
                                                    </div>
                                                    <!-- <hr class="m-0"> -->
                                                    <div class="row pt-2 pb-2 border">
                                                        <div class="col-2"><strong>Image</strong></div>
                                                        <div class="col-4"><strong>Name</strong></div>
                                                        <div class="col-2"><strong>Exp.</strong></div>
                                                        <div class="col-4"><strong>Price</strong></div>
                                                    </div>
                                                    <ng-container *ngFor="let astro of data.astrologerList">
                                                        <div class="row pt-2 pb-2 border">
                                                            <div class="col-2"><img
                                                                    [src]="getSanitizeUrl(astro.astroId.profile_image)"
                                                                    alt="" class="img img-fluid img-thumbnail"
                                                                    style="height: 50px;"></div>
                                                            <div class="col-4">{{astro.astroId.name}}</div>
                                                            <div class="col-2">{{astro.astroId.experience}}Years.</div>
                                                            <div class="col-4 price">{{astro.astroprice |
                                                                currency:'INR':'symbol-narrow'}}</div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
        <div class="col-lg-4 col-md-4">
            <div class="card productdesc">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Product
                                            Images</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                        [config]="slideConfig">
                                        <div ngxSlickItem *ngFor="let slide of data.product_image" class="slide">
                                            <img [src]="getSanitizeUrl(slide)" alt=""
                                                class="img img-fluid img-thumbnail">
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card productdesc" *ngIf="reviewList.length > 0">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Reviews</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <div class="row mb-4 review-bg border rounded"
                                        *ngFor="let review of reviewList | slice: (page-1) * pageSize : page * pageSize">
                                        <div class="col-12">
                                            <div class="row text-muted">
                                                <div class="col-4">Customer</div>
                                                <div class="col-8 text-right">{{review.createdAt | date:'medium'}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    {{review.customerReviewComments}}
                                                </div>
                                            </div>
                                            <div class="row d-flex justify-content-end bg-white mt-4 border"
                                                *ngIf="review.reviewReply != ''">
                                                <div class="col-11">
                                                    <div class="row text-muted">
                                                        <div class="col-3">{{review.reviewReplyBy}}</div>
                                                        <div class="col-9 text-right">{{review.reviewReplyDate |
                                                            date:'medium'}}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12">{{review.reviewReply}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex text-center"><ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                        [collectionSize]="reviewList.length" class="m-auto"></ngb-pagination></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->