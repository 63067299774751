<!-- <div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control
        col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
    <div class="mt-3">
        <button class="btn btn-primary btn-sm mr-2"
            (click)="getReviewList()">All</button>
        <button class="btn btn-warning btn-sm mr-2"
            (click)="autoFlagged()">Auto Flagged</button>
    </div>
</div> -->
<br>
<div class="row">
    <div class="col-md-12 p-4">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Notifications</h3>
            </div>
            <div class="card-body">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of notificationList | slice: (page-1) * pageSize : page * pageSize">
                            <tr *ngIf="item.is_toshow">
                                <td><span class="fe fe-bell text-primary"></span></td>
                                <td>{{item.notification_title}}</td>
                                <td>{{item.notification_description}}</td>
                                <td>{{item.created_at | date:'medium'}}</td>
                            </tr>
                        </ng-container>
                        
                    </tbody>
                </table>
                <ngb-pagination class="d-flex justify-content-center"  [collectionSize]="notificationList.length" [(page)]="page" [maxSize]="5"
                    [boundaryLinks]="true"></ngb-pagination>
            </div>
        </div>
    </div>
</div>