<ngx-spinner></ngx-spinner>
<!-- ROW-1 OPEN -->
<div class="row" id="user-profile">
    <div class="col-lg-12 pl-4 pr-4">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Add Or Update Note</h3>
            </div>
            <div class="card-body">
                <div>
                    <form [formGroup]="formGroup" (submit)="saveNote()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="charges">Customer</label>
                                <input type="text" [value]="data.customerId?.name" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="note_description">Note</label>
                                <textarea formControlName="note_description" class="form-control" placeholder="Write notes"></textarea>
                                <div *ngIf="f.note_description.invalid && (f.note_description.dirty || f.note_description.touched)" class="alert alert-danger mt-1">
                                    Required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row user-wrap justify-content-center">
                                <button type="submit()" class="btn-form-custom col-sm-4 btn btn-success mt-1 mb-2" [disabled]="!formGroup.valid"><i class="fa fa-rss"></i> Submit</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div><!-- COL-END -->
</div>
<!-- ROW-1 CLOSED -->