import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { MallServiceService } from 'src/app/astrologer/services/mall-service/mall-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-request-mall-product',
  templateUrl: './request-mall-product.component.html',
  styleUrls: ['./request-mall-product.component.scss']
})
export class RequestMallProductComponent implements OnInit {

  dataList:any = [];
  temp: any = [];
  constructor(
    private mallService: MallServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private modalService: NgbModal
  ) { }

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getProductRequest()
  }

  getProductRequest() {
    this.spinner.show()
    this.mallService.getProductRequest({astroId:this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  pendingRequest() {
    this.spinner.show()
    this.mallService.getProductRequest({astroId:this.userdata.getId(),isApproved:false,is_used:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  rejectedRequest(){
    this.spinner.show()
    this.mallService.getProductRequest({astroId:this.userdata.getId(),isApproved:false,is_used:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  acceptedRequest(){
    this.spinner.show()
    this.mallService.getProductRequest({astroId:this.userdata.getId(),isApproved:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
* Update filter
*/
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.productname.toLowerCase().indexOf(val) !== -1 ||
        d.productId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  detailData:any = ''
  /**
   * Detail Modal
   */
   openLg(content,data) {
     this.detailData = data
    this.modalService.open(content, { size: 'lg' });
  }

}
