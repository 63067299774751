import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupportServiceService } from 'src/app/astrologer/services/support/support-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-detail-support',
  templateUrl: './detail-support.component.html',
  styleUrls: ['./detail-support.component.scss']
})
export class DetailSupportComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private supportService: SupportServiceService,
    private userdata : UserdataService,
  ) { }

  data: any = '';
  id: any = ''; 

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.supportService.singleAstroSupport({_id: this.id,astroId:this.userdata.getId()}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
      }
    )
  }

}
