import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login/login.service';
import { OrderServiceService } from '../../services/order/order-service.service';
import { ProfileServiceService } from '../../services/profile/profile-service.service';
import { UserdataService } from '../../services/userdata/userdata.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {

  data: any = '';
  totalPromo = '';
  currentPromo = '';
  promoStatus = true
  unAnswerQuery = 0
  unAnswerReport = 0
  isCallGoing = false; isChatGoing = false;
  /**
   * Online Time for call, chat, query, report
   */
  onlineTimeCall = ''
  onlineTimeChat = ''
  onlineTimeQuery = ''
  onlineTimeReport = ''
  minDate: any = ''
  constructor(
    private profileService: ProfileServiceService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private userdata: UserdataService,
    private router: Router,
    private orderService: OrderServiceService,
    private modalService: NgbModal,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.getProfileDetails()
    this.getNoticeBoard()
    this.minDate = {
      year: Number(moment().format('Y')),
      month: Number(moment().format('M')),
      day: Number(moment().format('D')),
    }
    //console.log(this.minDate)
  }

  getProfileDetails() {
    this.isChatGoing = false; this.isCallGoing = false;
    this.spinner.show()
    this.profileService.getAstroProfile({ _id: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.data = res.data
        /** Schedule Online Time */
        this.onlineTimeCall = this.data.call_online_time
        this.onlineTimeChat = this.data.chat_online_time
        this.onlineTimeQuery = this.data.query_online_time
        this.onlineTimeReport = this.data.report_online_time
        if (this.onlineTimeCall != null || this.onlineTimeCall != undefined) {
          var callDateUtc = moment(this.onlineTimeCall).utc().format('YYYY-MM-DD HH:mm')
          this.callOnlineDateTime = moment.utc(callDateUtc).local().format('YYYY-MM-DD HH:mm')
          //console.log(this.callOnlineDateTime, this.onlineTimeCall)
        }else{
          this.callOnlineDateTime = moment().format('YYYY-MM-DD HH:mm')
        }
        if (this.onlineTimeChat != null || this.onlineTimeChat != undefined) {
          var chatDateUtc = moment(this.onlineTimeChat).utc().format('YYYY-MM-DD HH:mm')
          this.chatOnlineDateTime = moment.utc(chatDateUtc).local().format('YYYY-MM-DD HH:mm')
          //console.log(this.chatOnlineDateTime, this.onlineTimeChat)
        }else{
          this.chatOnlineDateTime = moment().format('YYYY-MM-DD HH:mm')
        }
        if (this.onlineTimeQuery != null || this.onlineTimeQuery != undefined) {
          var queryDateUtc = moment(this.onlineTimeQuery).utc().format('YYYY-MM-DD HH:mm')
          this.queryOnlineDateTime = moment.utc(queryDateUtc).local().format('YYYY-MM-DD HH:mm')
          //console.log(this.queryOnlineDateTime, this.onlineTimeQuery)
        }else{
          this.queryOnlineDateTime = moment().format('YYYY-MM-DD HH:mm')
        }
        if (this.onlineTimeReport != null || this.onlineTimeReport != undefined) {
          var reportDateUtc = moment(this.onlineTimeReport).utc().format('YYYY-MM-DD HH:mm')
          this.reportOnlineDateTime = moment.utc(reportDateUtc).local().format('YYYY-MM-DD HH:mm')
          //console.log(this.reportOnlineDateTime, this.onlineTimeReport)
        }else{
          this.reportOnlineDateTime = moment().format('YYYY-MM-DD HH:mm')
        }
        /**----------------------- */
        if (this.data.is_current_call_going) {
          this.isCallGoing = true
        }
        if (this.data.is_current_chat_going) {
          this.isChatGoing = true
        }
        //console.log(this.isChatGoing,this.isCallGoing)
        this.totalPromo = this.data.promocall_totake
        this.currentPromo = this.data.current_promocall_taken
        if (this.totalPromo > this.currentPromo) {
          this.promoStatus = false
        }
        this.unAnswerQuery = this.data.unansweredquestion
        this.unAnswerReport = this.data.unansweredreport
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
      }
    )
  }

  updateServiceStatus(event: any, service: any, val: any, modal) {
    //console.log(event, service, val, modal)
    if (this.userdata.getEmailVerify() == 'false') {
      this.router.navigateByUrl('/astrologer/verify-email')
      return
    }
    if (service == 'promo') {
      if(!val){
        if (this.totalPromo > this.currentPromo) {
          Swal.fire("Today's Promo Not Completed", "You are supposed to take " + this.totalPromo + " promo calls/chats", 'error')
          this.getProfileDetails()
          return
        }else{
          this.changeServiceStatus(service, val)
        }
      }else{
        this.changeServiceStatus(service, val)
      }      
    }
    if (service == 'call') {
      if (!val) {
        this.openCallModal(modal)
        return
      } else {
        this.changeServiceStatus(service, val)
      }
    }
    if (service == 'chat') {
      if (!val) {
        this.openChatModal(modal)
        return
      } else {
        this.changeServiceStatus(service, val)
      }
    }
    if (service == 'query') {
      if (!val) {
        this.openQueryModal(modal)
        return
      } else {
        this.changeServiceStatus(service, val)
      }
    }
    if (service == 'report') {
      if (!val) {
        this.openReportModal(modal)
        return
      } else {
        this.changeServiceStatus(service, val)
      }
    }
    if (service == 'all') {
      if (!val) {
        this.openAllServiceModal(modal)
        return
      } else {
        this.changeServiceStatus(service, val)
      }
    }
    //console.log(val)
    return
    this.spinner.show()
    this.profileService.updateServiceStatus({ _id: this.userdata.getId(), service_name: service, value: val }).subscribe(
      (res: any) => {
        this.getProfileDetails()
        if (!val && service == 'call') {
          this.openCallModal(modal)
        }
        if (!val && service == 'chat') {
          this.openChatModal(modal)
        }
        if (!val && service == 'query') {
          this.openQueryModal(modal)
        }
        if (!val && service == 'report') {
          this.openReportModal(modal)
        }
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
      }
    )
  }

  changeServiceStatus(service: any, val: any) {
    this.spinner.show()
    this.profileService.updateServiceStatus({ _id: this.userdata.getId(), service_name: service, value: val }).subscribe(
      (res: any) => {
        if(res.success){
          this.getProfileDetails()
        }else{
          this.toast.error(res.message,'Error')
        }        
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  /**
   * Check On-going Chat Status
   */
  checkOngoingChat() {
    this.orderService.getOrderDetail({ _id: this.data.currentchatserviceId }).subscribe(
      (res: any) => {
        var key = res.data.chatId
        if (key.is_end || key.order_current_status == 'Customer Rejected' || key.order_current_status == 'Cancelled by customer' || key.order_current_status == 'Astro Rejected') {
          //console.log('if')
          this.router.navigateByUrl('/astrologer/chat/' + this.data.currentchatserviceId)
        } else if (key.order_current_status == 'Pending') {
          let timerInterval
          var orderData
          /** API to fetch order Details*/
          this.orderService.getOrderDetail({ astroId: this.userdata.getId(), _id: this.data.currentchatserviceId }).subscribe(
            (res: any) => {
              orderData = res.data
              var date = moment(orderData.chatId.astrologer_notification_time);
              var notify = date.utc().format('YYYY-MM-DD hh:mm:ss');
              var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
              var notifyTime = moment(notify, 'YYYY-MM-DD hh:mm:ss');
              var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
              var secondsDiff = (currentTime.diff(notifyTime, 'seconds')) + 10;
              var timeLeft = 180 - secondsDiff
              if (timeLeft <= 0) {
                Swal.fire(
                  'Timeout',
                  "Chat request can't be accepted due to chat timeout.",
                  'error'
                )
                this.router.navigateByUrl('/astrologer/chat/' + this.data.currentchatserviceId)
                return
              }
              //console.log(orderData)
              /**
               * Popup
               */
              Swal.fire({
                // title: change.notification.title,
                html: 'Time Left <b></b>. <hr/><table class="table table-bordered p-0"><tr><td><strong>Name:</strong></td><td>' + orderData.customerId?.name + '</td></tr><tr><td colspan="2" class="text-danger"><strong>Profile Details</strong></td></tr><tr><td><strong>Person Name:</strong></td><td>' + orderData.personId?.name + '</td></tr><tr><td><strong>Gender:</strong></td><td>' + orderData.personId?.gender + '</td></tr><tr><td><strong>DOB:</strong></td><td>' + orderData.personId?.dob + '</td></tr><tr><td><strong>TOB:</strong></td><td>' + orderData.personId?.tob + '</td></tr><tr><td><strong>POB:</strong></td><td>' + orderData.personId?.city + '</td></tr><tr><td><strong>Marital Status:</strong></td><td>' + orderData.personId?.marital_status + '</td></tr><tr><td><strong>Occupation:</strong></td><td>' + orderData.personId?.employed_in + '</td></tr><tr><td><strong>Comments</strong></td><td>' + orderData.personId?.comments + '</td></tr></table>',
                // icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ACCEPT',
                cancelButtonText: 'REJECT',
                allowOutsideClick: false,
                timer: timeLeft * 1000,
                timerProgressBar: true,
                didOpen: () => {
                  //Swal.showLoading()
                  timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer()
                    if (content) {
                      const b = content.querySelector('b')
                      if (b) {
                        b.textContent = String(moment.utc(Swal.getTimerLeft()).format('mm:ss'));
                      }
                    }
                  }, 100)
                },
                willClose: () => {
                  clearInterval(timerInterval)
                }
              }).then((result) => {
                if (result.value) {
                  this.orderService.acceptRejectChat({ chatId: orderData.chatId._id, is_astrologer_accept: true }).subscribe(
                    (res: any) => {
                      //console.log(data.orderserviceId)
                      this.router.navigateByUrl('/astrologer/chat-ongoing/' + orderData._id)
                    }, err => {
                      this.toast.error(err.error.message, 'Error')
                    }
                  )
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  this.orderService.acceptRejectChat({ chatId: orderData.chatId._id, is_astrologer_accept: false }).subscribe(
                    (res: any) => {
                      //console.log('res')
                      this.getProfileDetails()
                      this.toast.success(res.message, 'Success')
                    }, err => {
                      //console.log('err')
                      this.getProfileDetails()
                      this.toast.error(err.error.message, 'Error')
                    }
                  )
                }
              })
              /**---------------------- */
            }, err => {
              //console.log(err)
            }
          )
          /**--------------------- */
        } else if (key.order_current_status == 'Astro Accepted' || (key.order_current_status == 'Chat Ongoing' || key.order_current_status == 'Chat ongoing') || key.order_current_status == 'Chat Started') {
          //console.log('else if 2')
          this.router.navigateByUrl('/astrologer/chat-ongoing/' + this.data.currentchatserviceId)
        } else {
          //console.log('else')
          this.router.navigateByUrl('/astrologer/chat/' + this.data.currentchatserviceId)
        }
      },
      err => {
        //console.log(err);

      }
    )
  }


  /**
   * Schedule Call Time
   */
  openCallModal(content) {//Modal for Call
    // console.log(this.callOnlineDateTime,moment(this.callOnlineDateTime,"YYYY-MM-DD HH:mm").format('M'))
    this.callDateModel = {
      year: Number(moment(this.callOnlineDateTime, "YYYY-MM-DD HH:mm").format('Y')),
      month: Number(moment(this.callOnlineDateTime, "YYYY-MM-DD HH:mm").format('M')),
      day: Number(moment(this.callOnlineDateTime, "YYYY-MM-DD HH:mm").format('D'))
    }
    this.callTimeModel = {
      hour: Number(moment(this.callOnlineDateTime, "YYYY-MM-DD HH:mm").format('HH')),
      minute: Number(moment(this.callOnlineDateTime, "YYYY-MM-DD HH:mm").format('mm'))
    }
    //console.log(this.callDateModel,this.callTimeModel)
    this.modalService.open(content).result.then((result) => {
      this.getProfileDetails()
    }, (reason) => {
      this.getProfileDetails()
    });
  }

  callDateModel: any = ''
  callTimeModel: any = ''
  callOnlineDateTime: any = ''

  setCallTime() {
    if ((this.callDateModel.year === undefined || this.callDateModel.year === '' || this.callDateModel.year === null || isNaN(this.callDateModel.year)) || (this.callDateModel.month === undefined || this.callDateModel.month === '' || this.callDateModel.month === null || isNaN(this.callDateModel.month)) || (this.callDateModel.day === undefined || this.callDateModel.day === '' || this.callDateModel.day === null || isNaN(this.callDateModel.day)) || (this.callTimeModel.hour === undefined || this.callTimeModel.hour === '' || this.callTimeModel.hour === null || isNaN(this.callTimeModel.hour)) || (this.callTimeModel.minute === undefined || this.callTimeModel.minute === '' || this.callTimeModel.minute === null || isNaN(this.callTimeModel.minute))) {
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      //this.getProfileDetails()
      return
    }
    var diff = moment().diff(this.callDateModel.year + '-' + this.callDateModel.month + '-' + this.callDateModel.day + ' ' + this.callTimeModel.hour + ':' + this.callTimeModel.minute, 'minutes')
    if(diff > -10){
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      return
    }
    var data = {
      astroId: this.userdata.getId(),
      call_online_time: this.callDateModel.year + '-' + this.callDateModel.month + '-' + this.callDateModel.day + ' ' + this.callTimeModel.hour + ':' + this.callTimeModel.minute
      //call_online_time: '2022-04-21 11:30'
    }
    this.profileService.onlineSchedule(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.changeServiceStatus('call', false)
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Schedule Chat Time
   */
  openChatModal(content) {//Modal for chat
    // console.log(this.chatOnlineDateTime,moment(this.chatOnlineDateTime,"YYYY-MM-DD HH:mm").format('M'))
    this.chatDateModel = {
      year: Number(moment(this.chatOnlineDateTime, "YYYY-MM-DD HH:mm").format('Y')),
      month: Number(moment(this.chatOnlineDateTime, "YYYY-MM-DD HH:mm").format('M')),
      day: Number(moment(this.chatOnlineDateTime, "YYYY-MM-DD HH:mm").format('D'))
    }
    this.chatTimeModel = {
      hour: Number(moment(this.chatOnlineDateTime, "YYYY-MM-DD HH:mm").format('HH')),
      minute: Number(moment(this.chatOnlineDateTime, "YYYY-MM-DD HH:mm").format('mm'))
    }
    //console.log(this.chatDateModel)
    this.modalService.open(content).result.then((result) => {
      this.getProfileDetails()
    }, (reason) => {
      this.getProfileDetails()
    });
  }

  chatDateModel: any = ''
  chatTimeModel: any = ''
  chatOnlineDateTime: any = ''

  setChatTime() {
    if ((this.chatDateModel.year === undefined || this.chatDateModel.year === '' || this.chatDateModel.year === null || isNaN(this.chatDateModel.year)) || (this.chatDateModel.month === undefined || this.chatDateModel.month === '' || this.chatDateModel.month === null || isNaN(this.chatDateModel.month)) || (this.chatDateModel.day === undefined || this.chatDateModel.day === '' || this.chatDateModel.day === null || isNaN(this.chatDateModel.day)) || (this.chatTimeModel.hour === undefined || this.chatTimeModel.hour === '' || this.chatTimeModel.hour === null || isNaN(this.chatTimeModel.hour)) || (this.chatTimeModel.minute === undefined || this.chatTimeModel.minute === '' || this.chatTimeModel.minute === null || isNaN(this.chatTimeModel.minute))) {
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      //this.getProfileDetails()
      return
    }
    var diff = moment().diff(this.chatDateModel.year + '-' + this.chatDateModel.month + '-' + this.chatDateModel.day + ' ' + this.chatTimeModel.hour + ':' + this.chatTimeModel.minute, 'minutes')
    if(diff > -10){
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      return
    }
    var data = {
      astroId: this.userdata.getId(),
      chat_online_time: this.chatDateModel.year + '-' + this.chatDateModel.month + '-' + this.chatDateModel.day + ' ' + this.chatTimeModel.hour + ':' + this.chatTimeModel.minute
    }
    this.profileService.onlineSchedule(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.changeServiceStatus('chat', false)
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Schedule Query Time
   */
  openQueryModal(content) {//Modal for Query
    // console.log(this.queryOnlineDateTime,moment(this.queryOnlineDateTime,"YYYY-MM-DD HH:mm").format('M'))
    this.queryDateModel = {
      year: Number(moment(this.queryOnlineDateTime, "YYYY-MM-DD HH:mm").format('Y')),
      month: Number(moment(this.queryOnlineDateTime, "YYYY-MM-DD HH:mm").format('M')),
      day: Number(moment(this.queryOnlineDateTime, "YYYY-MM-DD HH:mm").format('D'))
    }
    this.queryTimeModel = {
      hour: Number(moment(this.queryOnlineDateTime, "YYYY-MM-DD HH:mm").format('HH')),
      minute: Number(moment(this.queryOnlineDateTime, "YYYY-MM-DD HH:mm").format('mm'))
    }
    //console.log(this.queryDateModel)
    this.modalService.open(content).result.then((result) => {
      this.getProfileDetails()
    }, (reason) => {
      this.getProfileDetails()
    });
  }

  queryDateModel: any = ''
  queryTimeModel: any = ''
  queryOnlineDateTime: any = ''

  setQueryTime() {
    if ((this.queryDateModel.year === undefined || this.queryDateModel.year === '' || this.queryDateModel.year === null || isNaN(this.queryDateModel.year)) || (this.queryDateModel.month === undefined || this.queryDateModel.month === '' || this.queryDateModel.month === null || isNaN(this.queryDateModel.month)) || (this.queryDateModel.day === undefined || this.queryDateModel.day === '' || this.queryDateModel.day === null || isNaN(this.queryDateModel.day)) || (this.queryTimeModel.hour === undefined || this.queryTimeModel.hour === '' || this.queryTimeModel.hour === null || isNaN(this.queryTimeModel.hour)) || (this.queryTimeModel.minute === undefined || this.queryTimeModel.minute === '' || this.queryTimeModel.minute === null || isNaN(this.queryTimeModel.minute))) {
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      //this.getProfileDetails()
      return
    }
    var diff = moment().diff(this.queryDateModel.year + '-' + this.queryDateModel.month + '-' + this.queryDateModel.day + ' ' + this.queryTimeModel.hour + ':' + this.queryTimeModel.minute, 'minutes')
    if(diff > -10){
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      return
    }
    var data = {
      astroId: this.userdata.getId(),
      query_online_time: this.queryDateModel.year + '-' + this.queryDateModel.month + '-' + this.queryDateModel.day + ' ' + this.queryTimeModel.hour + ':' + this.queryTimeModel.minute
    }
    this.profileService.onlineSchedule(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.changeServiceStatus('query', false)
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Schedule Report Time
   */
  openReportModal(content) {//Modal for Report
    // console.log(this.reportOnlineDateTime,moment(this.reportOnlineDateTime,"YYYY-MM-DD HH:mm").format('M'))
    this.reportDateModel = {
      year: Number(moment(this.reportOnlineDateTime, "YYYY-MM-DD HH:mm").format('Y')),
      month: Number(moment(this.reportOnlineDateTime, "YYYY-MM-DD HH:mm").format('M')),
      day: Number(moment(this.reportOnlineDateTime, "YYYY-MM-DD HH:mm").format('D'))
    }
    this.reportTimeModel = {
      hour: Number(moment(this.reportOnlineDateTime, "YYYY-MM-DD HH:mm").format('HH')),
      minute: Number(moment(this.reportOnlineDateTime, "YYYY-MM-DD HH:mm").format('mm'))
    }
    //console.log(this.reportDateModel)
    this.modalService.open(content).result.then((result) => {
      this.getProfileDetails()
    }, (reason) => {
      this.getProfileDetails()
    });
  }

  reportDateModel: any = ''
  reportTimeModel: any = ''
  reportOnlineDateTime: any = ''

  setReportTime() {
    if ((this.reportDateModel.year === undefined || this.reportDateModel.year === '' || this.reportDateModel.year === null || isNaN(this.reportDateModel.year)) || (this.reportDateModel.month === undefined || this.reportDateModel.month === '' || this.reportDateModel.month === null || isNaN(this.reportDateModel.month)) || (this.reportDateModel.day === undefined || this.reportDateModel.day === '' || this.reportDateModel.day === null || isNaN(this.reportDateModel.day)) || (this.reportTimeModel.hour === undefined || this.reportTimeModel.hour === '' || this.reportTimeModel.hour === null || isNaN(this.reportTimeModel.hour)) || (this.reportTimeModel.minute === undefined || this.reportTimeModel.minute === '' || this.reportTimeModel.minute === null || isNaN(this.reportTimeModel.minute))) {
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      //this.getProfileDetails()
      return
    }
    var diff = moment().diff(this.reportDateModel.year + '-' + this.reportDateModel.month + '-' + this.reportDateModel.day + ' ' + this.reportTimeModel.hour + ':' + this.reportTimeModel.minute, 'minutes')
    if(diff > -10){
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      return
    }
    var data = {
      astroId: this.userdata.getId(),
      report_online_time: this.reportDateModel.year + '-' + this.reportDateModel.month + '-' + this.reportDateModel.day + ' ' + this.reportTimeModel.hour + ':' + this.reportTimeModel.minute
    }
    this.profileService.onlineSchedule(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.changeServiceStatus('report', false)
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Schedule All Service Time
   */
  openAllServiceModal(content) {
    // console.log(this.reportOnlineDateTime,moment(this.reportOnlineDateTime,"YYYY-MM-DD HH:mm").format('M'))
    this.allServiceDateModel = {
      year: Number(moment(this.allServiceOnlineDateTime, "YYYY-MM-DD HH:mm").format('Y')),
      month: Number(moment(this.allServiceOnlineDateTime, "YYYY-MM-DD HH:mm").format('M')),
      day: Number(moment(this.allServiceOnlineDateTime, "YYYY-MM-DD HH:mm").format('D'))
    }
    this.allServiceTimeModel = {
      hour: Number(moment(this.allServiceOnlineDateTime, "YYYY-MM-DD HH:mm").format('HH')),
      minute: Number(moment(this.allServiceOnlineDateTime, "YYYY-MM-DD HH:mm").format('mm'))
    }
    //console.log(this.allServiceDateModel)
    this.modalService.open(content).result.then((result) => {
      this.getProfileDetails()
    }, (reason) => {
      this.getProfileDetails()
    });
  }

  allServiceDateModel: any = ''
  allServiceTimeModel: any = ''
  allServiceOnlineDateTime: any = ''

  setAllServiceTime() {
    if ((this.allServiceDateModel.year === undefined || this.allServiceDateModel.year === '' || this.allServiceDateModel.year === null || isNaN(this.allServiceDateModel.year)) || (this.allServiceDateModel.month === undefined || this.allServiceDateModel.month === '' || this.allServiceDateModel.month === null || isNaN(this.allServiceDateModel.month)) || (this.allServiceDateModel.day === undefined || this.allServiceDateModel.day === '' || this.allServiceDateModel.day === null || isNaN(this.allServiceDateModel.day)) || (this.allServiceTimeModel.hour === undefined || this.allServiceTimeModel.hour === '' || this.allServiceTimeModel.hour === null || isNaN(this.allServiceTimeModel.hour)) || (this.allServiceTimeModel.minute === undefined || this.allServiceTimeModel.minute === '' || this.allServiceTimeModel.minute === null || isNaN(this.allServiceTimeModel.minute))) {
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      //this.getProfileDetails()
      return
    }
    var diff = moment().diff(this.allServiceDateModel.year + '-' + this.allServiceDateModel.month + '-' + this.allServiceDateModel.day + ' ' + this.allServiceTimeModel.hour + ':' + this.allServiceTimeModel.minute, 'minutes')
    if(diff > -10){
      this.toast.error('Please select time 10 Minutes after current date and time', 'Error')
      return
    }
    var data = {
      astroId: this.userdata.getId(),
      report_online_time: this.allServiceDateModel.year + '-' + this.allServiceDateModel.month + '-' + this.allServiceDateModel.day + ' ' + this.allServiceTimeModel.hour + ':' + this.allServiceTimeModel.minute,
      query_online_time: this.allServiceDateModel.year + '-' + this.allServiceDateModel.month + '-' + this.allServiceDateModel.day + ' ' + this.allServiceTimeModel.hour + ':' + this.allServiceTimeModel.minute,
      call_online_time: this.allServiceDateModel.year + '-' + this.allServiceDateModel.month + '-' + this.allServiceDateModel.day + ' ' + this.allServiceTimeModel.hour + ':' + this.allServiceTimeModel.minute,
      chat_online_time: this.allServiceDateModel.year + '-' + this.allServiceDateModel.month + '-' + this.allServiceDateModel.day + ' ' + this.allServiceTimeModel.hour + ':' + this.allServiceTimeModel.minute
    }
    this.profileService.onlineSchedule(data).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.changeServiceStatus('all', false)
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }
  /**
   * Astrologer Notice Board
   */
  noticeList: any = []
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, 'dots': true, "autoplay": true };
  getNoticeBoard() {
    this.loginService.getSingleCustomization({ customization_type: 6 }).subscribe(
      (res: any) => {
        this.noticeList = res.data.customization_array
        // console.log('notice list is ', this.noticeList)
      }, err => {
        //this.toast.error(err.error.message,'Error')
      }
    )
  }

  openNoticeBoard(noticeBoard) {
    this.modalService.open(noticeBoard, { scrollable: true });
  }

}
