import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-query-order-list',
  templateUrl: './query-order-list.component.html',
  styleUrls: ['./query-order-list.component.scss']
})
export class QueryOrderListComponent implements OnInit {

  constructor(
    private orderService: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private router: Router
  ) { }

  queryList = [];
  temp: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getQueryOrders()
  }

  getQueryOrders() {
    this.spinner.show()
    this.orderService.getQueryOrders({ 'astroId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        this.temp = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
* Update filter
*/
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.customerId.name.toLowerCase().indexOf(val) !== -1 ||
        d.oquestionId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.queryList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  notAnswered(){
    this.spinner.show()
    this.orderService.getQueryOrders({ 'astroId': this.userdata.getId(),is_astro_answer:false }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        this.temp = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  noAstrologerFeedbackReply(){
    this.spinner.show()
    this.orderService.getQueryOrders({ 'astroId': this.userdata.getId(),is_astro_replied_feedback:false,is_feedback_given:true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.queryList = res.data;
        this.temp = this.queryList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  selected = [];
  SelectionType = SelectionType;
  onSelect({ selected }) {
    this.router.navigateByUrl('/astrologer/query/'+selected[0].orderserviceId?._id)
    //console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

}
