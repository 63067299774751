
<span>
    <!-- GLOBAL-LOADER -->
    <!-- <div id="global-loader">
        <img src="../../../../assets/images/svgs/loader.svg" class="loader-img" alt="Loader">
    </div> -->

    <div class="page">
        <div class="page-main">
            <!-- HEADER -->
           <app-header></app-header>
            <!-- HEADER END -->

            <!-- HORIZONTAL-MENU -->
            <app-horizontal-menu></app-horizontal-menu>
            <!-- HORIZONTAL-MENU END -->

            <!-- CONTAINER -->
            <router-outlet></router-outlet>
            <!-- CONTAINER END -->
        </div>

        <!-- SIDE-BAR -->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- SIDE-BAR CLOSED -->

        <!-- FOOTER -->
       <app-footer></app-footer>
        <!-- FOOTER END -->
    </div>

    <!-- BACK-TO-TOP -->
    <a href="#top" id="back-to-top"><i class="fa fa-long-arrow-up"></i></a>

</span>
