import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-payout-details',
  templateUrl: './payout-details.component.html',
  styleUrls: ['./payout-details.component.scss']
})
export class PayoutDetailsComponent implements OnInit {

  radioValue = 0
  lastMonth: string;
  lastYear: string;
  astroId: string;
  constructor(
    private profileService: ProfileServiceService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  earningList: any = []
  
  // yearList = [2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030];
  // monthList = [{'id':1,'name':'January'},{'id':2,'name':'February'},{'id':3,'name':'March'},{'id':4,'name':'April'},{'id':5,'name':'May'},{'id':6,'name':'June'},{'id':7,'name':'July'},{'id':8,'name':'August'},{'id':9,'name':'September'},{'id':10,'name':'October'},{'id':11,'name':'November'},{'id':12,'name':'December'}]

  ngOnInit(): void {
    this.getCurrentMonthEarning()
    this.lastMonth = moment().month(moment().month() - 1).startOf('month').format('MM');
    this.lastYear = moment().month(moment().month() - 1).startOf('month').format('YYYY');
    this.astroId = this.userdata.getId()
  }

  getTotalEarning(data) {
    this.spinner.show()
    this.profileService.getTotalEarning(data).subscribe(
      (res: any) => {
        this.earningList = res.data
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  getCurrentMonthEarning(){
    this.spinner.show()
    this.profileService.getCurrentMonthEarning({ astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.earningList = res.data
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
