<div class="pr-2 pl-2 d-flex justify-content-start align-items-center">
    <!-- MODAL VIEW ISSUE-->
    <ng-template #viewIssue let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">View Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body row">
            <table class="table">
                <tbody>
                    <tr>
                        <td><strong>Issue Id:</strong> #{{issueData.issueId}}</td>
                        <td><strong>Amount:</strong> {{issueData.amount | currency:'CAD':'symbol-narrow'}}</td>
                        <td> 
                            <span *ngIf="issueData.is_issue_solved" class="badge badge-success">Solved</span>
                            <span *ngIf="!issueData.is_issue_solved" class="badge badge-danger">Pending</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Title: </strong>{{issueData.issue_title}}</td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Description: </strong>{{issueData.issue_description}}</td>
                    </tr>
                    <tr *ngIf="issueData.is_reply_by_system">
                        <td colspan="3"><strong>Reply: </strong>{{issueData.reply_by_system}}</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th colspan="3">Wallet Transaction Details</th>
                    </tr>
                    <tr>
                        <td><strong>Wallet Id: </strong> #{{issueData.walletId.awalletId}}</td>
                        <td><strong>Type: </strong> <small *ngIf="issueData.walletId.txn_type == 1" class="badge badge-success">Recharged</small>
                            <small *ngIf="issueData.walletId.txn_type == 2" class="badge badge-primary">Deducted</small></td>
                        <td><strong>Amount: </strong> {{issueData.walletId.amount | currency:'INR':'symbol-narrow'}}</td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Description: </strong> {{issueData.walletId.wallet_comments}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="d('Cross click')">
                Close
            </button>
        </div>
    </ng-template>
    <!-- MODAL VIEW ISSUE END-->
    <input type="text" placeholder="Type to filter the ID , Title..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"/>
        <div class="ml-4 btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="radioValue">
            <label ngbButtonLabel class="btn-primary">
                <input ngbButton type="radio" (click)="searchIssue('all')" [value]="'all'"> All
            </label>
            <label ngbButtonLabel class="btn-primary">
                <input ngbButton type="radio" (click)="searchIssue('service')" [value]="'service'"> Service Issue
            </label>
            <label ngbButtonLabel class="btn-primary">
                <input ngbButton type="radio" (click)="searchIssue('wallet')" [value]="'wallet'"> Wallet Issue
            </label>
            <label ngbButtonLabel class="btn-primary">
                <input ngbButton type="radio" (click)="searchIssue('store')" [value]="'store'"> Store Issue
            </label>
        </div>
</div>
<br>
<ngx-datatable class="material" [rows]="issueList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="Issue ID" prop="issueId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-primary" *ngIf="row.is_service_issue && row.orderserviceId?.is_call_order"
                [routerLink]="['/astrologer/call/', row.orderserviceId?._id]">#{{row.issueId}}</a>
            <a class="text-primary" *ngIf="row.is_service_issue && row.orderserviceId?.is_chat_order"
                [routerLink]="['/astrologer/chat/', row.orderserviceId?._id]">#{{row.issueId}}</a>
            <a class="text-primary" *ngIf="row.is_service_issue && row.orderserviceId?.is_query_order"
                [routerLink]="['/astrologer/query/', row.orderserviceId?._id]">#{{row.issueId}}</a>
            <a class="text-primary" *ngIf="row.is_service_issue && row.orderserviceId?.is_report_order"
                [routerLink]="['/astrologer/report/', row.orderserviceId?.reportId]">#{{row.issueId}}</a>
            <a class="text-primary" href="javascript:void()" *ngIf="row.is_wallet_issue" (click)="openViewIssue(viewIssue,row)">#{{row.issueId}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Title" prop="issue_title">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.issue_title}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="amount">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="price">{{row.amount | currency :'INR':'symbol-narrow'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Description" prop="issue_description">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <small [title]="row.issue_description">{{row.issue_description}}</small>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Type">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_wallet_issue" class="badge badge-success mr-2">Wallet</span><span
                *ngIf="row.is_service_issue" class="badge badge-success">Service</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Status" prop="is_issue_solved">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.is_issue_solved" class="badge badge-success mr-2">Solved</span>
            <span
                *ngIf="!row.is_issue_solved" class="badge badge-danger">Pending</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>