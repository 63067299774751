<!-- HORIZONTAL-MENU -->
<div class="sticky">
        <div class="horizontal-main hor-menu clearfix">
                <div class="horizontal-mainwrapper container clearfix">
                        <nav class="horizontalMenu clearfix">
                                <ul class="horizontalMenu-list d-flex justify-content-center">
                                        <li aria-haspopup="true"><a [routerLink]="['/astrologer/dashboard']"
                                                        class="sub-icon" routerLinkActive="active"><i
                                                                class="fe fe-airplay"></i>
                                                        Dashboard</a>
                                        </li>
                                        <li aria-haspopup="true"><a [routerLink]="['/astrologer/orders/call']"
                                                        class="sub-icon" routerLinkActive="active"><i
                                                                class="fe fe-clipboard"></i>
                                                        Orders</a>
                                        </li>
                                        <li aria-haspopup="true"><a [routerLink]="['/astrologer/wallet/wallet-details']"
                                                        class="sub-icon" routerLinkActive="active"><i
                                                                class="icon icon-wallet"></i>
                                                        Wallet</a>
                                        </li>
                                        <li aria-haspopup="true"><a [routerLink]="['/astrologer/price/call']"
                                                        class="sub-icon" routerLinkActive="active"><i
                                                                class="fa fa-inr"></i>
                                                        Price</a>
                                        </li>

                                        <li aria-haspopup="true"><a [routerLink]="['/astrologer/issue/list']"
                                                        class="sub-icon" routerLinkActive="active"><i
                                                                class="icon icon-info"></i>
                                                        Issues</a>
                                        </li>
                                        <li aria-haspopup="true"><a [routerLink]="['/astrologer/support/list']"
                                                        class="sub-icon" routerLinkActive="active"><i
                                                                class="icon icon-earphones-alt"></i>
                                                        Support</a>
                                        </li>
                                        <li aria-haspopup="true"><a href="javascript:void(0)" class="sub-icon"><i
                                                                class="fe fe-clipboard"></i>Astro Shop <i
                                                                class="fa fa-angle-down horizontal-icon"></i></a>
                                                <ul class="sub-menu">
                                                        <!-- <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/mall-category/list']">Category</a>
                                                        </li> -->
                                                        <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/mall-product/associate']">Product</a>
                                                        </li>
                                                        <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/orders/store']">Shop
                                                                        Orders</a>
                                                        </li>
                                                </ul>
                                        </li>
                                        <li aria-haspopup="true"><a href="javascript:void(0)" class="sub-icon"><i
                                                                class="icon icon-present"></i>Add-Ons <i
                                                                class="fa fa-angle-down horizontal-icon"></i></a>
                                                <ul class="sub-menu">
                                                        <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/waiting-list']"
                                                                        class="sub-icon" routerLinkActive="active">
                                                                        <!-- <i class="fe fe-clock"></i> -->
                                                                        Waiting List
                                                                </a>
                                                        </li>
                                                        <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/offers/active']"
                                                                        class="sub-icon" routerLinkActive="active">
                                                                        <!-- <i class="icon icon-present"></i> -->
                                                                        Offers
                                                                </a>
                                                        </li>
                                                        <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/review']"
                                                                        class="sub-icon" routerLinkActive="active">
                                                                        <!-- <i class="icon icon-bubble"></i> -->
                                                                        Review
                                                                </a>
                                                        </li>
                                                        <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/notes/list']"
                                                                        class="sub-icon" routerLinkActive="active">
                                                                        <!-- <i class="icon icon-note"></i> -->
                                                                        Notes
                                                                </a>
                                                        </li>
                                                        <li aria-haspopup="true"><a
                                                                        [routerLink]="['/astrologer/festival']"
                                                                        class="sub-icon" routerLinkActive="active">
                                                                        <!-- <i class="icon icon-note"></i> -->
                                                                        Festival List
                                                                </a>
                                                        </li>
                                                </ul>
                                        </li>
                                        <li aria-haspopup="true"><a [routerLink]="['/astrologer/blog/list']"
                                                        class="sub-icon" routerLinkActive="active"><i
                                                                class="icon icon-bubble"></i>
                                                        Blogs</a>
                                        </li>
                                </ul>
                        </nav>
                        <!-- NAV END -->
                </div>
        </div>
</div>
<!-- HORIZONTAL-MENU END -->