import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-show-profile',
  templateUrl: './show-profile.component.html',
  styleUrls: ['./show-profile.component.scss']
})
export class ShowProfileComponent implements OnInit {

  BaseUrl = '';
  constructor(
    private profileService: ProfileServiceService,
    private userdata: UserdataService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }
  
  data:any = ''

  ngOnInit(): void {
    this.getAstroProfile()
  }

  getAstroProfile(){
    this.profileService.getAstroProfile({_id:this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.data = res.data
      },err=>{

      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

}
