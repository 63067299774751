import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NoteServiceService } from 'src/app/astrologer/services/note/note-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss']
})
export class AddNotesComponent implements OnInit {

  constructor(
    private notesService: NoteServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.modalService.dismissAll()
    this.id = this.router.snapshot.paramMap.get('id')
    this.formGroup.patchValue({noteId:this.id})
    this.getNoteDetail()
  }

  data: any = ''
  id:any = ''

  formGroup: FormGroup = new FormGroup({
    noteId: new FormControl('', Validators.required),
    note_description: new FormControl('', Validators.required)
  });

  get f() {
    return this.formGroup.controls
  }
  
  getNoteDetail(){
    this.spinner.show()
    this.notesService.noteDetails({_id:this.id}).subscribe(
      (res:any)=>{
        this.data = res.data
        this.formGroup.patchValue({note_description:this.data.note_description})
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  saveNote(){
    this.spinner.show()
    this.notesService.addNote(this.formGroup.value).subscribe(
      (res:any)=>{
        this.spinner.hide()
        this.toast.success(res.message,'Success')
        this.route.navigateByUrl('/astrologer/notes/list')
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
