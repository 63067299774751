import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueServiceService } from 'src/app/astrologer/services/issue/issue-service.service';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mall-detail',
  templateUrl: './mall-detail.component.html',
  styleUrls: ['./mall-detail.component.scss']
})
export class MallDetailComponent implements OnInit {
  serverTime: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private orderService: OrderServiceService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private route: Router,
    private issueService: IssueServiceService
  ) { }

  data: any = '';
  id: any = '';

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDetailOrderById()
  }

  getDetailOrderById() {
    this.spinner.show()
    this.orderService.getStoreSingle({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.data = res.data
        this.serverTime = res.server_current_time
        this.spinner.hide()
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/astrologer/orders/store')
      }
    )
  }

  /**
   * Check three days
   */
  checkThreeDays(val) {
    var str = val
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    //var enddateComponent = moment().utc().format('YYYY-MM-DD');
    var enddateComponent = moment(this.serverTime).utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(enddateComponent, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 3) {
      return true
    } else {
      return false
    }
  }

  /**
   * Review 
   */
  changeReviewReply: boolean = false

  reviewReply: FormGroup = new FormGroup({
    orderId: new FormControl(''),
    reviewReplyBy: new FormControl(''),
    reviewReply: new FormControl('', [Validators.required]),
    astroId: new FormControl(''),
  });

  updateReviewReply() {
    this.changeReviewReply = !this.changeReviewReply
  }

  get g() {
    return this.reviewReply.controls
  }

  reviewAnswer() {
    var msg = (this.reviewReply.get('reviewReply').value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Reply', "This message cannot be sent as our policy doesn't allows to share number or email", 'error')
      return;
    }
    this.reviewReply.patchValue({ reviewReply: msg })
    this.spinner.show()
    this.reviewReply.patchValue({ orderId: this.data._id })
    this.reviewReply.patchValue({ astroId: this.userdata.getId() })
    this.reviewReply.patchValue({ reviewReplyBy: this.userdata.getName() })
    this.orderService.answerStoreReview(this.reviewReply.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.changeReviewReply = false
        this.getDetailOrderById()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Update Order Status
   */
  open(content) {
    this.modalService.open(content);
  }

  updateStatusForm: FormGroup = new FormGroup({
    _id: new FormControl(''),
    updatedId: new FormControl('2', Validators.required),
    orderStatus: new FormControl('7', Validators.required),
    astroComments: new FormControl('', Validators.required),
    updatedBy: new FormControl(''),
  });

  get i() {
    return this.updateStatusForm.controls
  }

  updateStoreStatus() {
    this.updateStatusForm.patchValue({ _id: this.data._id })
    this.updateStatusForm.patchValue({ updatedId: this.userdata.getId() })
    this.updateStatusForm.patchValue({ updatedBy: this.userdata.getName() })
    this.orderService.updateStoreStatus(this.updateStatusForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.getDetailOrderById()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.modalService.dismissAll()
      }
    )
  }

  /**
   * Issue
   */
  openIssue(content) {
    this.modalService.open(content);
  }

  issueForm: FormGroup = new FormGroup({
    astroId: new FormControl(''),
    issue_type: new FormControl('2', Validators.required),
    is_order_issue: new FormControl(true, Validators.required),
    orderId: new FormControl(''),
    amount: new FormControl(''),
    issue_title: new FormControl('', Validators.required),
    issue_description: new FormControl('', Validators.required),
  });

  get issue() {
    return this.issueForm.controls
  }

  addIssue() {
    this.issueForm.patchValue({ astroId: this.userdata.getId() })
    this.issueForm.patchValue({ orderId: this.data._id })
    this.issueForm.patchValue({ amount: this.data.astroamounttopay })
    this.issueService.addAstroIssue(this.issueForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
        this.getDetailOrderById()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.modalService.dismissAll()
      }
    )
  }

  /**
   * Reply to Issue
   */
  openIssueReply(content) {
    this.issueReplyForm.patchValue({astroId:this.userdata.getId()})
    this.issueReplyForm.patchValue({_id:this.data?.astro_issueId?._id})
    this.modalService.open(content);
  }

  issueReplyForm: FormGroup = new FormGroup({
    astroId: new FormControl(''),
    _id: new FormControl('', Validators.required),
    reply_by_system: new FormControl('', Validators.required),
    is_reply_by_astro: new FormControl(true),
  });

  get issueReply() {
    return this.issueReplyForm.controls
  }

  addReplyIssue() {
    var value = (this.issueReplyForm.get('reply_by_system')?.value).replace(/^\s+|\s+$/gm,'')
    if(value == '' || value == undefined || value == null){
      return
    }
    this.issueReplyForm.patchValue({reply_by_system:value})
    this.issueService.replyAstroIssue(this.issueReplyForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        //this.modalService.dismissAll()
        this.getDetailOrderById()
        this.issueReplyForm.patchValue({reply_by_system:''})
      }, err => {
        this.toast.error(err.error.message, 'Error')
        //this.modalService.dismissAll()
      }
    )
  }
}
