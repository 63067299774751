import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/astrologer/services/blog-service/blog-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-detail-blog',
  templateUrl: './detail-blog.component.html',
  styleUrls: ['./detail-blog.component.scss']
})
export class DetailBlogComponent implements OnInit {

  BaseUrl: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private blogService: BlogServiceService,
    private userdata : UserdataService,
    private route: Router,
    private toast : ToastrService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  data: any = '';
  id: any = ''; 

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    this.spinner.show()
    this.blogService.getDetailBlog({_id: this.id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
        //console.log(this.data)
      },
      err => {
        this.spinner.hide()
        this.route.navigateByUrl('/astrologer/blog/my-blog')
      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

}
