<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Edit Blog</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/blog/list']">Blog Category</a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="updateBlogSubmit()">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="blogTitle">Title</label>
                                        <input type="text" class="form-control" form placeholder="Blog Title"
                                            formControlName="blogTitle">
                                        <small *ngIf="f.blogTitle.invalid && (f.blogTitle.dirty || f.blogTitle.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="categoryId">Blog Catrgory</label>
                                        <select class="form-control" formControlName="categoryId">
                                            <option value="">Select Blog Category</option>
                                            <option *ngFor="let data of dataList" [value]="data._id">{{data.categoryname}}
                                            </option>
                                        </select>
                                        <small *ngIf="f.categoryId.invalid && (f.categoryId.dirty || f.categoryId.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="blogImage">Image (Image should be 960 X 540)</label>
                                        <input type="file" class="form-control" placeholder="Banner Image"
                                            (change)="onBannerUpload($event)">
                                        <small *ngIf="f.blogImage.invalid && (f.blogImage.dirty || f.blogImage.touched)"
                                            class="d-block alert alert-danger mt-1">
                                            Required.
                                        </small>
                                        <small *ngIf="imageError != ''" class="d-block alert alert-danger mt-1">
                                            {{imageError}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <editor
                                formControlName="blogDescription" 
                                class="w-100"
                                apiKey="kb1shdcv4s1c8mg7s4jo6ebmrmwqkkkx5hfwwpofj87i778i" 
                                [init]="{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help',
                                    images_upload_handler: example_image_upload_handler
                                }"
                                ></editor>
                              </div>
                            <div class="row">
                                <button type="submit" class="btn btn-success mt-1"
                                    [disabled]="!formGroup.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
        <!-- <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4">
            <form [formGroup]="imageGroup" (submit)="addDirectImage()">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Images for blog</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="blogTitle">Upload Image to get image link for blog</label>
                                    <input type="file" class="form-control" placeholder="Banner Image"
                                        (change)="onDirectUpload($event)">
                                        <small class="text-danger">{{imageDirectError}}</small>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 border" *ngIf="directPath != ''">
                                <div class="form-group">
                                    <label for="blogTitle">Paste this path in source of image insert</label>
                                    <small>{{directPath}}</small>
                                    <button (click)="copyImageName()" class="btn btn-info" type="button">Copy to clipboard</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-center">
                        <button type="submit" class="btn-form-custom col-sm-4 btn btn-success mt-1 mb-2" [disabled]="!imageGroup.valid">Save</button>
                    </div>
                </div>
            </form>
        </div> -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->