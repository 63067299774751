import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupportServiceService } from 'src/app/astrologer/services/support/support-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-list-support',
  templateUrl: './list-support.component.html',
  styleUrls: ['./list-support.component.scss']
})
export class ListSupportComponent implements OnInit {

  constructor(
    private supportService: SupportServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }
  title = 'angular-datatables';

  issueList = [];
  id: any = '';
  temp: any = [];
  getIssueData: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getAllAstrologerSupport()
    this.getSupportNumbers()
    this.issueList = this.getIssueData;
  }

  /* ***********
  list All Astrologer Support
  *********** */

  getAllAstrologerSupport() {
    this.spinner.show()
    this.supportService.getAstroSupport({ 'astroId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList
        this.getIssueData = this.issueList
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.astroId.name.toLowerCase().indexOf(val) !== -1 ||
        d.supportId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.issueList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Pending(Not replied) Issue
   */
  pendingIssue() {
    this.spinner.show()
    this.supportService.getAstroSupport({ 'astroId': this.userdata.getId(), 'is_reply_by_system': false }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
   * Solved Issue
   */
  solvedIssue() {
    this.spinner.show()
    this.supportService.getAstroSupport({ 'astroId': this.userdata.getId(), 'is_reply_by_system': true, 'is_issue_solved': true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.issueList = res.data;
        //console.log(this.issueList)
        this.temp = this.issueList;
        this.getIssueData = this.issueList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  supportList:any;
  getSupportNumbers(){
    this.spinner.show()
    this.supportService.openSupport({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.supportList = res;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

}
