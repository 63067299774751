import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OfferServiceService } from 'src/app/astrologer/services/offer/offer-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-chat-offer',
  templateUrl: './chat-offer.component.html',
  styleUrls: ['./chat-offer.component.scss']
})
export class ChatOfferComponent implements OnInit {

  constructor(
    private offerService: OfferServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }

  chatOfferList = [];
  temp: any = [];
  astroData:any = ''

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getChatOffersList()
  }

  /* ***********
  list Chat Offers
  *********** */

  getChatOffersList() {
    this.spinner.show()
    this.offerService.astroPriceAfterOffer({ astroId: this.userdata.getId(), offer_on: 'chat' }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatOfferList = res.data;
        this.astroData = res.astro
        this.temp = this.chatOfferList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.offer_name.toLowerCase().indexOf(val) !== -1 ||
        d.offer_display_name.toLowerCase().indexOf(val) !== -1 ||
        d.offerId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.chatOfferList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  /**
   * Check If Offer Is active or not
   */
  checkIfOfferIsActive(id: any) {
    if(this.astroData.astroofferId.chat_offerId === id){
      return true
    }else{
      return false
    }
  }

  changeOfferStatus(id:any,val:any){
    this.offerService.changeOfferStatus({astroId:this.userdata.getId(),offer_on:'chat',offerId:id,offerstatus:val}).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.getChatOffersList()
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
