import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { NoteServiceService } from 'src/app/astrologer/services/note/note-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-list-notes',
  templateUrl: './list-notes.component.html',
  styleUrls: ['./list-notes.component.scss']
})
export class ListNotesComponent implements OnInit {

  constructor(
    private notesService: NoteServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private modalService: NgbModal
  ) { }

  noteList = [];
  temp: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getNoteList()
  }

  /* ***********
  list All Notes
  *********** */

  getNoteList() {
    this.spinner.show()
    this.notesService.myNote({astroId:this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.noteList = res.data;
        this.temp = this.noteList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
   updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId.name.toLowerCase().indexOf(val) !== -1 ||
        d.noteId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.noteList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  detailData:any = ''
  /**
   * Detail Modal
   */
   openLg(content,data) {
     this.detailData = data
    this.modalService.open(content, { size: 'lg' });
  }

}
