<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Store</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/orders/store']">Store</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- MODAL COMPLETE ORDER-->
    <ng-template #contentOrder let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Mark Order Complete</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="updateStatusForm" (submit)="updateStoreStatus()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="astroComments">Remarks</label>
                    <div class="input-group">
                        <textarea class="form-control" formControlName="astroComments"></textarea>
                    </div>
                    <div *ngIf="i.astroComments.invalid && (i.astroComments.dirty || i.astroComments.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark" [disabled]="!updateStatusForm.valid">Save</button>
            </div>
        </form>
    </ng-template>
    <!-- MODAL COMPLETE ORDER END-->
    <!-- MODAL ISSUE-->
    <ng-template #contentIssue let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="issueForm" (submit)="addIssue()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="issue_title">Issue Title</label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="issue_title">
                    </div>
                    <div *ngIf="issue.issue_title.invalid && (issue.issue_title.dirty || issue.issue_title.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
                <div class="form-group">
                    <label for="issue_description">Description</label>
                    <div class="input-group">
                        <textarea class="form-control" formControlName="issue_description"></textarea>
                    </div>
                    <div *ngIf="issue.issue_description.invalid && (issue.issue_description.dirty || issue.issue_description.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark" [disabled]="!issueForm.valid">Save</button>
            </div>
        </form>
    </ng-template>
    <!-- MODAL ISSUE END-->
    <!-- MODAL ISSUE DETAIL-->
    <ng-template #contentIssueDetail let-c="close" let-d="dismiss">
        <div class="modal-header py-0">
            <h4 class="modal-title" id="modal-basic-title">Reply Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="issueReplyForm" (submit)="addReplyIssue()">
            <div class="modal-body">
                <div class="row chatbox">
                    <div class="col-md-12 col-lg-12 col-xl-12 chat">
                        <div class="card">

                            <!-- MSG CARD-BODY OPEN -->
                            <div class="card-body msg_card_body py-0">

                                <div *ngFor="let reply of data.astro_issueId?.replylogs">
                                    <div class="d-flex justify-content-start" *ngIf="reply.isSystemReply">
                                        <div class="msg_cotainer">
                                            <span [innerHTML]="reply.new_reply"></span><br />
                                            <span class="msg_time">{{reply.replyDate | date:'mediumDate'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end" *ngIf="reply.isAstroReply">
                                        <div class="msg_cotainer bg-primary text-white">
                                            <span [innerHTML]="reply.new_reply"></span><br />
                                            <span class="msg_time text-white">{{reply.replyDate |
                                                date:'mediumDate'}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- MSG CARD-BODY END -->
                        </div>
                    </div>
                    <!-- COL END -->
                </div>
                <div class="row d-flex align-items-center">
                    <div class="col-10">
                        <textarea class="form-control" formControlName="reply_by_system" (keyup.enter)="addReplyIssue()"></textarea>
                    </div>
                    <div class="col-2">
                        <button type="submit" class="btn btn-outline-dark" [disabled]="!issueReplyForm.valid">Send</button>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
    <!-- MODAL ISSUE DETAIL END-->
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-8 col-md-4">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div
                                                        class="media-heading d-flex justify-content-between align-items-end">
                                                        <h4>Order Details
                                                        </h4>
                                                        <div>
                                                            <button class="mr-2 btn-user-custom btn btn-outline-primary"
                                                                *ngIf="data.orderStatus < 3 && !data.isOrderCompleted"
                                                                (click)="open(contentOrder)">Order Complete ?</button>
                                                            <button
                                                                class="mr-2 btn-user-custom btn btn-outline-secondary"
                                                                (click)="openIssue(contentIssue)"
                                                                *ngIf="!data?.is_astro_issue_created">Help</button>
                                                        </div>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong>Order Id :</strong> #{{data.orderId}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Product Name : </strong>
                                                                        {{data.productId?.productname}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Order On :</strong> {{data.createdAt |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Order Status : </strong> <span
                                                                            class="badge badge-danger"
                                                                            *ngIf="data.orderStatus == 1">Placed</span>
                                                                        <span class="badge badge-warning"
                                                                            *ngIf="data.orderStatus == 2">Confirmed</span>
                                                                        <span class="badge badge-info"
                                                                            *ngIf="data.orderStatus == 3">Shipped</span>
                                                                        <span class="badge badge-secondary"
                                                                            *ngIf="data.orderStatus == 4">Out For
                                                                            Delivery</span>
                                                                        <span class="badge badge-success"
                                                                            *ngIf="data.orderStatus == 5">Delivered</span>
                                                                        <span class="badge badge-danger"
                                                                            *ngIf="data.orderStatus == 6">Cancelled</span>
                                                                            <span class="badge badge-warning" *ngIf="data.orderStatus == 6 && data.refundStatus == 0">Not Refunded</span>
                                                                            <span class="badge badge-info" *ngIf="data.orderStatus == 6 && data.refundStatus == 1">Refunded Requested</span>
                                                                            <span class="badge badge-success" *ngIf="data.orderStatus == 6 && data.refundStatus == 2">Refunded Approved</span>
                                                                            <span class="badge badge-danger" *ngIf="data.orderStatus == 6 && data.refundStatus == 3">Refunded Rejected</span>
                                                                            <span class="badge badge-success" *ngIf="data.orderStatus == 6 && data.refundStatus == 4">Refund Complete</span>
                                                                        <span class="badge badge-success"
                                                                            *ngIf="data.orderStatus == 7">Completed</span>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="data.isOrderReviewed">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Review</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Review :</strong>
                                                                        {{data.reviewId?.customerReviewComments}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <!-- <tbody class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Stars :</strong>
                                                                        <ng-template #t let-fill="fill">
                                                                            <span class="star" [class.full]="fill === 100">
                                                                                <span class="half"
                                                                                    [style.width.%]="fill">&#x2605;</span>&#x2605;
                                                                            </span>
                                                                        </ng-template>

                                                                        <ngb-rating [(rate)]="data.reviewId.customerReviewStars" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                                                                    </td>
                                                                </tr>
                                                            </tbody> -->
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.reviewId?.reviewReply == '' && !checkThreeDays(data.reviewId?.customerReviewDate)">
                                                                <tr>
                                                                    <td><strong>Note: You cannot reply to this review
                                                                            after 3 days.</strong></td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.reviewId?.reviewReply != ''">
                                                                <tr>
                                                                    <td><strong>Astrologer Review Reply:</strong>
                                                                        {{data.reviewId?.reviewReply}} <button
                                                                            class="btn btn-link"
                                                                            (click)="updateReviewReply()"
                                                                            *ngIf="checkThreeDays(data.reviewId?.customerReviewDate)">Change</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Reviewed On :</strong>
                                                                        {{data.reviewId?.customerReviewDate |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0">
                                                                <tr *ngIf="data.reviewId?.reviewReply != ''">
                                                                    <td><strong>Review Reply On :</strong>
                                                                        {{data.reviewId?.reviewReplyDate |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="data.reviewId?.reviewReply == '' && checkThreeDays(data.reviewId?.customerReviewDate)">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="reviewReply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.reviewReply.invalid && (g.reviewReply.dirty || g.reviewReply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn btn-success btn-form-custom mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="data.reviewId?.reviewReply != '' && changeReviewReply">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="reviewReply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.reviewReply.invalid && (g.reviewReply.dirty || g.reviewReply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn btn-success btn-form-custom mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
        <div class="col-lg-4 col-md-4">
            <div class="card productdesc">
                <div class="card-body">
                    <div class="panel panel-info">
                        <div class="tab-menu-heading border-bottom">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-group-tab"
                                        [routerLink]="['/astrologer/groupchat-ongoing/',data?.group_chat_id]"><a
                                            href="#tab1" class="bg-primary text-white" data-toggle="tab">Group Chat</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card productdesc"
                *ngIf="data?.productId?.fields_applied?.isNameOn || data?.productId?.fields_applied?.isEmailOn || data?.productId?.fields_applied?.isPhoneOn || data?.productId?.fields_applied?.isQuantityOn || data?.productId?.fields_applied?.isMaritalStatusOn || data?.productId?.fields_applied?.isGenderOn || data?.productId?.fields_applied?.isCommentsOn || data?.productId?.fields_applied?.isAddressOn || data?.productId?.fields_applied?.isRingSizeOn || data?.productId?.fields_applied?.isDobOn || data?.productId?.fields_applied?.isTobOn || data?.customerId?.regCountryName != ''">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Client
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row" *ngIf="data?.customerId?.regCountryName != ''">
                                            <div class="col-sm-4 text-muted">Registered Country</div>
                                            <div class="col-sm-8">{{data?.customerId?.regCountryName}}</div>
                                        </li>
                                        <li class="row" *ngIf="data?.productId?.fields_applied?.isNameOn">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.userDetails?.name}}</div>
                                        </li>
                                        <li class="row" *ngIf="data?.productId?.fields_applied?.isEmailOn">
                                            <div class="col-sm-4 text-muted">Email</div>
                                            <div class="col-sm-8">{{data.userDetails?.email}}</div>
                                        </li>
                                        <li class="row" *ngIf="data?.productId?.fields_applied?.isPhoneOn">
                                            <div class="col-sm-4 text-muted">Contact</div>
                                            <div class="col-sm-8">{{data.userDetails?.phoneNumber}}</div>
                                        </li>
                                        <li class="row" *ngIf="data?.productId?.fields_applied?.isQuantityOn">
                                            <div class="col-sm-4 text-muted">Quantity</div>
                                            <div class="col-sm-8">{{data.userDetails?.quantity}}</div>
                                        </li>
                                        <li class="row" *ngIf="data?.productId?.fields_applied?.isMaritalStatusOn">
                                            <div class="col-sm-4 text-muted">Marital Status</div>
                                            <div class="col-sm-8">{{data.userDetails?.maritalStatus}}</div>
                                        </li>
                                        <li class="row" *ngIf="data?.productId?.fields_applied?.isGenderOn">
                                            <div class="col-sm-4 text-muted">Gender</div>
                                            <div class="col-sm-8">{{data.userDetails?.gender}}</div>
                                        </li>
                                        <li class="row" *ngIf="data?.productId?.fields_applied?.isCommentsOn">
                                            <div class="col-sm-4 text-muted">Note</div>
                                            <div class="col-sm-8">{{data.userDetails?.comments}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card productdesc" *ngIf="data?.is_astro_issue_created">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab3" class="active" data-toggle="tab">Issue
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab3">
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Issue Id</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issueId}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Amount</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.amount}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Title</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issue_title}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Description</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issue_description}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Status</div>
                                            <div class="col-sm-8">
                                                <span class="badge badge-success"
                                                    *ngIf="data.astro_issueId?.is_issue_solved">SOLVED</span>
                                                <span class="badge badge-danger"
                                                    *ngIf="!data.astro_issueId?.is_issue_solved">PENDING</span>
                                            </div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Created At</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.created_at | date:'medium'}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.astro_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.reply_by_system}}</div>
                                        </li>

                                        <li class="row" *ngIf="data.astro_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply Date</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.replyupdatedBy | date:'medium'}}
                                            </div>
                                        </li>
                                        <li class="row"><button class="btn btn-sm btn-block text-white" style="background-color: #564fc1;"
                                                (click)="openIssueReply(contentIssueDetail)">View Issue Details</button>
                                        </li>
                                    </ul>
                                    <h4 class="mb-5 mt-3" *ngIf="data.personId?.is_partner_details">Partner Details</h4>
                                    <ul class="list-unstyled mb-0" *ngIf="data.personId?.is_partner_details">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.partner_name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.partner_dob}}
                                                {{data.personId?.partner_tob}}, {{data.personId?.partner_city}},
                                                {{data.personId?.partner_state}}, {{data.personId?.partner_country}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->