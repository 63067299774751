<!-- FOOTER -->
<footer class="footer">
    <div class="container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-12 col-sm-12 text-center">
                Copyright © {{year}} <a href="https://astroriver.com/" target="blank">Astro River</a>. All rights reserved.
            </div>
        </div>
    </div>
</footer>
<!-- FOOTER END -->