import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import * as _ from 'lodash';
import { SkillsLanguageServiceService } from 'src/app/astrologer/services/skills-language/skills-language-service.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  id: any = ''
  data: any = ''
  skillsData: any = [];
  languageData: any = [];
  public skills:Array<any>= [];
  public languages:Array<any>= [];
  i: any;
  getWorkingStatus: Boolean | any;
  selectedLanguage:any = [];
  selectedLanguageJson: any =[];
  selectedSkills:any = [];
  selectedSkillsJson: any =[];
  defaultSkill:any = []
  defaultLanguage:any = []
  
  addAstrologerForm: any = new FormGroup({ 
    _id: new FormControl('',[Validators.required]),
    profile_image: new FormControl(''),
    complete_address: new FormControl('',[Validators.required]),
    city: new FormControl('',[Validators.required]),
    state: new FormControl('',[Validators.required]),
    country: new FormControl('',[Validators.required]),
    pincode: new FormControl('',[Validators.required]),
    experience: new FormControl('',[Validators.required]),
    working_with_other: new FormControl('',[Validators.required]),
    short_bio: new FormControl('',[Validators.required]),
    long_bio: new FormControl('',[Validators.required]),
    bank_account_num: new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,}')]),
    account_type: new FormControl('', [Validators.required]),
    ifsc: new FormControl('', [Validators.required]),
    account_holder_name: new FormControl('', [Validators.required]),
    lang: new FormControl(''),
    skill: new FormControl(''),
  })

  constructor(
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private skillsLangService: SkillsLanguageServiceService,
    private userdata: UserdataService,
    private profileService: ProfileServiceService,
    private toast: ToastrService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.addAstrologerForm.patchValue({_id:this.userdata.getId()})
    this.getSkillsList();
    this.getLaguangeList();
    this.getAstrologerDataById()
  }

  getAstrologerDataById(){
    this.profileService.getAstroProfile({_id:this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.data = res.data
        this.addAstrologerForm.patchValue({experience:this.data.experience})
        //this.addAstrologerForm.patchValue({lang:this.data.lang})
        //this.addAstrologerForm.patchValue({skill:this.data.skill})
        this.addAstrologerForm.patchValue({complete_address:this.data.complete_address})
        this.addAstrologerForm.patchValue({city:this.data.city})
        this.addAstrologerForm.patchValue({state:this.data.state})
        this.addAstrologerForm.patchValue({country:this.data.country})
        this.addAstrologerForm.patchValue({pincode:this.data.pincode})
        this.addAstrologerForm.patchValue({short_bio:this.data.short_bio})
        this.addAstrologerForm.patchValue({long_bio:this.data.long_bio})
        this.addAstrologerForm.patchValue({bank_account_num:this.data.bank_account_num})
        this.addAstrologerForm.patchValue({account_type:this.data.account_type})
        this.addAstrologerForm.patchValue({ifsc:this.data.ifsc})
        this.addAstrologerForm.patchValue({account_holder_name:this.data.account_holder_name})
        res.data.skill.forEach((element:any) => {
          this.defaultSkill.push({'value':element.skillId._id,'display':element.skillId.skillname})
          //console.log(this.defaultTag)
        }); 
        res.data.lang.forEach((element:any) => {
          this.defaultLanguage.push({'value':element.langId._id,'display':element.langId.name})
          //console.log(this.defaultTag)
        });
        if(this.data.working_with_other){
          this.addAstrologerForm.patchValue({working_with_other:'yes'})
        }else{
          this.addAstrologerForm.patchValue({working_with_other:'no'})
        }
      }
    )
  }
  
  profileError: string ='';
  public onProfileImageUpload(evnt: any){
    this.profileError = ''
    if(evnt.target.files.length > 0)
    {
      if (evnt.target.files && evnt.target.files[0]) {
        const allowed_types = ['image/png', 'image/jpeg'];
        if (!_.includes(allowed_types, evnt.target.files[0].type)) {
          this.profileError = 'Only Images are allowed ( JPG | PNG )';
          return;
        }else{
          this.addAstrologerForm.patchValue({
            profile_image: evnt.target.files[0]
          })
        }
      }
    }
  }

  getLaguangeList() {
    this.spinner.show()
    this.skillsLangService.getLaguangeList({status:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.languageData = res.data;
        for( this.i = 0; this.i<this.languageData.length; this.i++) {
          this.languages.push({'display': this.languageData[this.i].name, 'value': this.languageData[this.i]._id});
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getSkillsList() {
    this.spinner.show()
    this.skillsLangService.getSkillsList({status:true}).subscribe(
     (res: any) => {
       this.spinner.hide()
       this.skillsData = res.data;
       for( this.i = 0; this.i<this.skillsData.length; this.i++) {
         this.skills.push({'display': this.skillsData[this.i].skillname, 'value': this.skillsData[this.i]._id});
       }
     },
     (err) => {
       this.spinner.hide()
      //console.log(err);
     }
   );
 }

  get f(){
    return this.addAstrologerForm.controls
  }

  public onAddAstrologerSubmit(){
    this.spinner.show()
    var choosenLangues = this.addAstrologerForm.get("lang").value
    choosenLangues.forEach((element: { value: any; }) => {
      this.selectedLanguage.push(element.value)
    });
    // this.selectedLanguage.forEach((element: any) => {
    //   this.selectedLanguageJson.push({"langId":element})
    // });

    var choosenSkills = this.addAstrologerForm.get("skill").value
    choosenSkills.forEach((element: { value: any; }) => {
      this.selectedSkills.push(element.value)
    });
    // this.selectedSkills.forEach((element: any) => {
    //   this.selectedSkillsJson.push({"skillId":element})
    // });
    //-------/tags json-----//

    var convertBoolean = this.addAstrologerForm.get("working_with_other").value
     
     if(convertBoolean == "yes"){
       this.getWorkingStatus = true;
     } else {
      this.getWorkingStatus = false;
     }
    //return;
    const data = new FormData() 
    data.append("_id",this.addAstrologerForm.get("_id").value)
    data.append("name",this.userdata.getName())
    data.append("experience",this.addAstrologerForm.get("experience").value)
    data.append("lang", JSON.stringify(this.selectedLanguage))
    data.append("skill", JSON.stringify(this.selectedSkills))
    data.append("complete_address",this.addAstrologerForm.get("complete_address").value)
    data.append("city",this.addAstrologerForm.get("city").value)
    data.append("state",this.addAstrologerForm.get("state").value)
    data.append("country",this.addAstrologerForm.get("country").value)
    data.append("pincode",this.addAstrologerForm.get("pincode").value)
    data.append("working_with_other",this.getWorkingStatus)
    data.append("short_bio",this.addAstrologerForm.get("short_bio").value)
    data.append("long_bio",this.addAstrologerForm.get("long_bio").value)
    data.append("profile_image",this.addAstrologerForm.get("profile_image").value)
    data.append("account_holder_name",this.addAstrologerForm.get("account_holder_name").value)
    data.append("ifsc",this.addAstrologerForm.get("ifsc").value)
    data.append("account_type",this.addAstrologerForm.get("account_type").value)
    data.append("bank_account_num",this.addAstrologerForm.get("bank_account_num").value)
    
    this.profileService.updateAstroProfile(data).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.toast.success('Astrologer Updated!', 'Sucess')
        this.route.navigateByUrl('/astrologer/profile/view')
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error')
        //console.log(err)
      }
    )
  }

}
