import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { ScrollToBottomDirective } from 'src/app/astrologer/directive/scroll-to-bottom/scroll-to-bottom.directive';
import { Chat } from 'src/app/astrologer/services/firebase/chat';
import { FirebaseServiceService } from 'src/app/astrologer/services/firebase/firebase-service.service';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-chat-astrologer',
  templateUrl: './group-chat-astrologer.component.html',
  styleUrls: ['./group-chat-astrologer.component.scss']
})
export class GroupChatAstrologerComponent implements OnInit {

  /**
   * Scroll to bottom
   */
  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;

  /**----------------------------- */
  id; dataList: any = ''; chatId; chatsRef: any = ''
  chatList: Array<Chat> = [];
  customerId = ''
  initialMsg = ''
  BaseUrl: any;
  isChatComplete: Boolean = false

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderServiceService,
    private toast: ToastrService,
    private userdata: UserdataService,
    private db: AngularFireDatabase,
    private firebaseService: FirebaseServiceService,
    private router: Router,
    private modalService: NgbModal,
    private _lightbox: Lightbox,
    private _lightboxConfig: Lightbox,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getDetailGroupChat()
  }

  getDetailGroupChat() {
    this.orderService.getDetailGroupChats({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.dataList = res.data
        this.initialMsg = this.dataList.initial_msg
        this.isChatComplete = this.dataList.is_order_completed
        this.chatsRef = firebase.database().ref('groupchat/' + this.id);
        this.customerId = this.dataList.customerId._id
        this.retrieveAllChats()
        this.childAdded()
        this.childChanged()
      }, err => {
        //console.log(err)
        //this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Retrieve all Chat from Backend
   */
  retrieveAllChats() {
    this.firebaseService.getGroupChatList(this.id).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(chat => {
      this.chatList = chat;
      if (this.chatList.length <= 0) {
        this.orderService.getDetailGroupChats({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
          (res: any) => {
            this.dataList = res.data
            this.initialMsg = this.dataList.initial_msg
            this.isChatComplete = this.dataList.is_order_completed
            this.chatList = this.dataList.chatinfo
          }, err => {
            //console.log(err)
            //this.toast.error(err.error.message, 'Error')
          }
        )
      }
    }, (error) => {
      //console.log(error);
    });
  }

  public childAdded() {
    var demo = this
    var i = 0
    this.chatsRef.on("child_added", function (snapshot: any) {
      var data = snapshot.val();
      var key = snapshot.key;
      if (data.is_read_astrologer != undefined && !data.is_read_astrologer) {
        demo.chatsRef.child(key).update({ is_read_astrologer: true });
        //console.log(data, i, key)
      }
      if (key == '- Typing') {
        if (data.customer == true) {
          demo.customerTyping = true
        }
        else {
          demo.customerTyping = false
        }
        if (data.admin == true) {
          //console.log(data.admin)
          demo.adminTyping = true
        } else {
          demo.adminTyping = false
        }
        //console.log(demo.adminTyping)
      }
      if (data.chat_status == 2 || (data.message == 'Your order has been completed')) {
        demo.isChatComplete = true;
        demo.chatList = demo.dataList.chatinfo;
      }
      // demo.chatList.push(data)
      i++
    })
  }

  /**
   * Child Changed
   */
  customerTyping: boolean = false
  adminTyping: boolean = false

  childChanged() {
    var demo = this
    this.chatsRef.on("child_changed", function (snapshot: any) {
      //console.log(snapshot.key)
      var data = snapshot.val();
      var key = snapshot.key;
      if (key == '- Typing') {
        if (data.customer == true) {
          demo.customerTyping = true
        }
        else {
          demo.customerTyping = false
        }
        if (data.admin == true) {
          //console.log(data.admin)
          demo.adminTyping = true
        } else {
          demo.adminTyping = false
        }
        //console.log(demo.adminTyping)
      }
    })

  }
  /**
   * On Input focus show astrologer typing true
   */
  onKeyUpInput(event: any) {
    //console.log(event)
    if (event.target.value.length > 0) {
      this.chatsRef.child('- Typing').update({ astrologer: true });
    } else {
      this.chatsRef.child('- Typing').update({ astrologer: false });
    }
  }

  /**
   * On Input Blur show astrologer typing true
   */
  onBlurInput(event: any) {
    //console.log('blur')
    this.chatsRef.child('- Typing').update({ astrologer: false });
  }

  /**
   * View Customer Details and Connect Call Modal
   */
  openModal(content) {
    this.modalService.open(content);
  }

  /**
   * Send Chat Message
   */
  isImageUpload = false
  sendMsgForm = new FormGroup({
    chatId: new FormControl(''),
    astroId: new FormControl(''),
    message: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    is_to_show: new FormControl(true),
    isImage: new FormControl(false),
    isFile: new FormControl(false),
    type: new FormControl(2), //1-Admin, 2-Astrologer, 3-Customer
  });

  get f() {
    return this.sendMsgForm.controls
  }

  sendMsg() {
    this.sendMsgForm.patchValue({ chatId: this.id })
    this.sendMsgForm.patchValue({ astroId: this.userdata.getId() })
    this.sendMsgForm.patchValue({ name: this.userdata.getDisplayName() })
    if (!this.isImageUpload) {
      //console.log('not image')
      var msg = (this.sendMsgForm.get('message').value).trim()
      if (msg != '' && msg != null) {
        //console.log('msg not null')
        var count = (msg.match(/\d/g) || []).length
        var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
        if (count == 10 || (emailsArray != null && emailsArray.length)) {
          //console.log('error yes')
          this.sendMsgForm.patchValue({ is_to_show: false })
          //console.log(this.sendMsgForm.get('is_to_show').value, 'if')
        }
        this.sendMsgForm.patchValue({ message: msg })
        const data = new FormData()
        data.append("chatId", this.sendMsgForm.get("chatId").value)
        data.append("astroId", this.sendMsgForm.get("astroId").value)
        data.append("message", this.sendMsgForm.get("message").value)
        data.append("name", this.sendMsgForm.get("name").value)
        data.append("is_to_show", this.sendMsgForm.get("is_to_show").value)
        data.append("isImage", this.sendMsgForm.get("isImage").value)
        data.append("isFile", this.sendMsgForm.get("isFile").value)
        data.append("type", this.sendMsgForm.get("type").value)
        //console.log(this.sendMsgForm.get('is_to_show').value, 'after if')
        this.orderService.sendGroupChatMsg(data).subscribe(
          (res: any) => {
            this.sendMsgForm.reset()
            this.sendMsgForm.patchValue({ message: '' })
            this.sendMsgForm.patchValue({ is_to_show: true })
            this.sendMsgForm.patchValue({ isImage: false })
            this.sendMsgForm.patchValue({ isFile: false })
            this.sendMsgForm.patchValue({ type: 2 })
            this.f
            //console.log(this.sendMsgForm.get('is_to_show').value, 'result')
          }, err => {
            this.toast.error(err.error.message, 'Error')
          }
        )
      }
    }
    if (this.isImageUpload) {
      const data = new FormData()
      data.append("chatId", this.sendMsgForm.get("chatId").value)
      data.append("astroId", this.sendMsgForm.get("astroId").value)
      data.append("message", this.sendMsgForm.get("message").value)
      data.append("name", this.sendMsgForm.get("name").value)
      data.append("is_to_show", this.sendMsgForm.get("is_to_show").value)
      data.append("isImage", this.sendMsgForm.get("isImage").value)
      data.append("isFile", this.sendMsgForm.get("isFile").value)
      data.append("type", this.sendMsgForm.get("type").value)
      //console.log(this.sendMsgForm.get('is_to_show').value, 'after if')
      this.orderService.sendGroupChatMsg(data).subscribe(
        (res: any) => {
          this.sendMsgForm.reset()
          this.sendMsgForm.patchValue({ message: '' })
          this.sendMsgForm.patchValue({ is_to_show: true })
          this.sendMsgForm.patchValue({ isImage: false })
          this.sendMsgForm.patchValue({ isFile: false })
          this.sendMsgForm.patchValue({ type: 2 })
          this.f
          //console.log(this.sendMsgForm.get('is_to_show').value, 'result')
        }, err => {
          this.toast.error(err.error.message, 'Error')
        }
      )
    }
  }

  checkEnterPressed(event: any) {
    if (event.keyCode === 13) {
      this.sendMsg()
    }
  }

  /**
   * M3- Attachements
   */

  /**
   * Get attachment type
   */
  checkExtenstion(val: any) {
    var fileName, fileExtension;
    fileName = val
    fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toLowerCase();
    if (fileExtension == 'jpg' || fileExtension == 'png' || fileExtension == 'jpeg') {
      return 0
    } else if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'txt') {
      return 1
    } else if (fileExtension == 'xlsx' || fileExtension == 'xls' || fileExtension == 'csv') {
      return 2
    } else if (fileExtension == 'pdf') {
      return 3
    } else {
      return 4
    }
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  public getOpenUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl('http://docs.google.com/viewer?url=' + this.BaseUrl + url)
  }
  private _albums = [];
  open(val: any): void {
    this._albums = []
    const album = {
      src: this.BaseUrl + val,
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, { wrapAround: true, showImageNumberLabel: true, fitImageInViewPort: true, centerVertically: true });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onFileSelected(evnt: any) {
    // console.log(evnt.target.files[0].type)
    // return
    if (evnt.target.files.length > 0) {
      if (evnt.target.files.length > 0) {
        if (evnt.target.files && evnt.target.files[0]) {
          const allowed_types = ['image/png', 'image/jpeg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'text/csv', 'application/doc', 'application/ms-doc', 'application/msword', 'application/excel', 'application/vnd.ms-excel'];
          if (!_.includes(allowed_types, evnt.target.files[0].type)) {
            Swal.fire('Invalid File Extension', 'Only Images,Doc, PDF, Excel are allowed', 'error');
            return;
          } else {
            this.sendMsgForm.patchValue({
              message: evnt.target.files[0]
            })
            this.sendMsgForm.patchValue({
              isImage: true
            })
            if (evnt.target.files[0].type == 'image/png' || 'image/jpeg' || 'image/jpg') {
              this.sendMsgForm.patchValue({
                isFile: false
              })
            } else {
              this.sendMsgForm.patchValue({
                isFile: true
              })
            }
            this.isImageUpload = true
            this.sendMsg()
          }
        }
      }
    }
  }

  /**
   * Connect Call
   */
  callTime:any = 0
  isCustomTime: boolean = false
  enterCustomTime(val:any) {
    this.isCustomTime = false
    if(val == 0){
      this.isCustomTime = true
    }
    this.callTime = val
  }

  connectCallCustomer() {
    this.spinner.show()
    if(this.callTime == 0){
      this.callTime = Number((<HTMLInputElement>document.getElementById("customValue")).value) * 60
    }
    var data = {
      astroId: this.userdata.getId(),
      customerId: this.customerId,
      duration: this.callTime
    }
    this.orderService.directCallFromGroupChat(data).subscribe(
      (res:any)=>{
        this.spinner.hide()
        this.toast.success(res.message,'Success')
        this.modalService.dismissAll()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}

