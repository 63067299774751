<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Apply For A Product</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/mall-product/non-associate']">Non-Associate Product List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Apply</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <form [formGroup]="formGroup" (submit)="submit()">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="">Product Name</label>
                                    <input type="text" class="form-control text-capitalize" name="productname" [value]="data.productname" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="">Minimun Price</label>
                                    <input type="text" class="form-control text-capitalize" name="min_price" [value]="data.min_price" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="">Price</label>
                                    <input type="text" class="form-control text-capitalize" name="min_price" formControlName="price" placeholder="Enter Your Price.">
                                    <small class="text-info">Note: Your price cann't be less than Minimum Price.</small>
                                    <div *ngIf="f.price.invalid && (f.price.dirty || f.price.touched)" class="alert alert-danger mt-1">
                                        Required. Only Numbers are allowed.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="astrologer_comment">Remarks</label>
                                    <textarea formControlName="astrologer_comment" class="form-control" placeholder="Remarks"></textarea>
                                    <div *ngIf="f.astrologer_comment.invalid && (f.astrologer_comment.dirty || f.astrologer_comment.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="user-wrap">
                                    <button type="submit()" class="btn btn-primary mt-1 mb-2" [disabled]="!formGroup.valid"><i class="fa fa-rss"></i> Submit</button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->
