import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PriceServiceService } from 'src/app/astrologer/services/price/price-service.service';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-call-price',
  templateUrl: './call-price.component.html',
  styleUrls: ['./call-price.component.scss']
})
export class CallPriceComponent implements OnInit {

  constructor(
    private profileService: ProfileServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private priceService: PriceServiceService,
    private toast: ToastrService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.getProfile()
  }

  data: any = ''
  priceList = [];
  getProfile() {
    this.spinner.show()
    this.profileService.getAstroProfile({ _id: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.data = res.data
        var charge = this.data.call_charges
        var variation = charge * 0.10
        var up = Number(charge) + Number(variation)
        var down = Number(charge) - Number(variation)
        for (let index = down; index <= up; index++) {
          this.priceList.push(index)
        }
        this.spinner.hide()
      },
      err => {
        this.spinner.hide()
      }
    )
  }

  /**
   * Update Call Price
   */
  formGroup: FormGroup = new FormGroup({
    _id: new FormControl(''),
    service_name: new FormControl('call'),
    charges: new FormControl('', Validators.required),
    change_comment: new FormControl(''),
  });

  get f() {
    return this.formGroup.controls
  }

  updatePrice() {
    this.spinner.show()
    this.formGroup.patchValue({ _id: this.userdata.getId() })
    this.priceService.updatePrice(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message)
        this.reloadCurrentRoute()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

}
