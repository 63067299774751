import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogServiceService } from 'src/app/astrologer/services/blog-service/blog-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-list-my-blog',
  templateUrl: './list-my-blog.component.html',
  styleUrls: ['./list-my-blog.component.scss']
})
export class ListMyBlogComponent implements OnInit {

  constructor(
    private blogService: BlogServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }
  title = 'angular-datatables';

  dataList = [];
  id: any = '';
  temp: any = [];
  ColumnMode = ColumnMode;
  token: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit() {
    this.getAstrologerBlogList() 
  }

  /* ***********
  Astrologer Blog
  *********** */

  getAstrologerBlogList() {
    this.spinner.show()
    this.blogService.getBlog({status:true,astroId:this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /* ***********
  Astrologer Pending Blog
  *********** */

  getAstrologerPendingBlogList() {
    this.spinner.show()
    this.blogService.getBlog({status:true,astroId:this.userdata.getId(),isApproved:false,is_used:false}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /* ***********
  Astrologer Approved Blog
  *********** */

  getAstrologerApprovedBlogList() {
    this.spinner.show()
    this.blogService.getBlog({status:true,astroId:this.userdata.getId(),isApproved:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      return d.blogTitle.toLowerCase().indexOf(val) !== -1 ||
        d.categoryId?.categoryname.toString().indexOf(val) !== -1 ||
        d.userName.toLowerCase().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
