<ngx-spinner></ngx-spinner>
<!-- ROW-1 OPEN -->
<div class="row" id="user-profile">
    <div class="col-lg-12 pl-4 pr-4">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Add Support Question</h3>
            </div>
            <div class="card-body">
                <div>
                    <form [formGroup]="formGroup" (submit)="addSupport()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="support_title">Question</label>
                                    <input type="text" formControlName="support_title" class="form-control" placeholder="Write Your Question">
                                    <div *ngIf="f.support_title.invalid && (f.support_title.dirty || f.support_title.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="support_description">Problem</label>
                                    <textarea formControlName="support_description" class="form-control" placeholder="Describe Your Problem"></textarea>
                                    <div *ngIf="f.support_description.invalid && (f.support_description.dirty || f.support_description.touched)" class="alert alert-danger mt-1">
                                        Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row user-wrap justify-content-center">
                                    <button type="submit()" class="btn-form-custom col-sm-4 btn btn-success mt-1 mb-2" [disabled]="!formGroup.valid"><i class="fa fa-rss"></i> Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- COL-END -->
</div>
<!-- ROW-1 CLOSED -->