<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Review</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Review</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!--Mark Review-->
    <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Flag Review</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formGroup">
            <div class="form-group">
              <label for="dateOfBirth">Reason</label>
              <div class="input-group">
                <textarea formControlName="astrologer_reason" class="form-control" cols="30" rows="10"></textarea>
              </div>              
            </div>
            <div *ngIf="f.astrologer_reason.invalid && (f.astrologer_reason.dirty || f.astrologer_reason.touched)" class="alert alert-danger mt-1">
                Required
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="saveFlaggedReview()" [disabled]="!formGroup.valid">Save</button>
        </div>
      </ng-template>
    <!----->
    <!--Show Flag Reason-->
    <ng-template #reason let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Flag Review Reason</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body row">
            <div class="col-12">
                <ng-container *ngIf="flagReason.is_customer_flagged;else astroReason">
                    <strong>Customer Reason : </strong> {{flagReason.customer_reason}}
                </ng-container>
                <ng-template #astroReason>
                    <strong>Astrologer Reason : </strong> {{flagReason.astrologer_reason}}
                </ng-template>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        </div>
      </ng-template>
    <!--//Show Flag Reason-->
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="pl-2 pr-2 d-flex justify-content-between align-items-end">
                                    <input type="text" placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
                                      (keyup)="updateFilter($event)"/>
                                    <div class="mt-3">
                                      <button class="btn-filter-custom btn btn-secondary mr-2" (click)="getAllReviewList()">All</button>
                                      <button class="btn-filter-custom btn btn-primary mr-2" (click)="autoFlaggedReview()">Flagged Review</button>
                                    </div>
                                  </div>
                                  <br>
                                  <ngx-datatable class="material" [rows]="reviewList" [columnMode]="'force'" [headerHeight]="50"
                                  [footerHeight]="50" [rowHeight]="60" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
                                  <ngx-datatable-column [flexGrow]="1" name="ID" prop="reviewId">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          #{{row.reviewId}}
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [flexGrow]="3" name="Review" prop="customer_review">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          <span [title]="row.customer_review">{{row.customer_review}}</span>
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [flexGrow]="1" name="Stars" prop="customer_review_stars">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          <ngb-rating [(rate)]="row.customer_review_stars" [readonly]="true" [max]="5">
                                              <ng-template let-fill="fill" let-index="index">
                                                  <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"><span
                                                          class="half" [style.width.%]="fill"
                                                          [class.bad]="index < 3">&#9733;</span>&#9733;</span>
                                              </ng-template>
                                          </ngb-rating>
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <!-- <ngx-datatable-column [flexGrow]="1" name="Astrologer" prop="astroId.name">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          {{row.astroId.name}}
                                      </ng-template>
                                  </ngx-datatable-column> -->
                                  <ngx-datatable-column [flexGrow]="1" name="Customer" prop="customerId?.name">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          <span [title]="row.customerId?.name" *ngIf="!row.isDirectReview">{{row.customerId?.name}}</span>
                                          <span [title]="row.customerName" *ngIf="row.isDirectReview">{{row.customerName}}</span>
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [flexGrow]="1" name="Type">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          <span role="button" [routerLink]="['/astrologer/chat/',row.orderserviceId?._id]" *ngIf="row.orderserviceId?.is_chat_order"
                                              class="badge badge-primary">Chat</span>

                                          <span role="button" [routerLink]="['/astrologer/call/',row.orderserviceId?._id]"
                                              *ngIf="row.orderserviceId?.is_call_order" class="badge badge-info">Call</span>

                                          <span role="button" [routerLink]="['/astrologer/report/',row.orderserviceId?.reportId]"
                                              *ngIf="row.orderserviceId?.is_report_order" class="badge badge-success">Report</span>

                                          <span role="button" [routerLink]="['/astrologer/query/',row.orderserviceId?._id]"
                                              *ngIf="row.orderserviceId?.is_query_order" class="badge badge-warning">Query</span>
                                              <span role="button" 
                                              *ngIf="row.isDirectReview" class="badge badge-info">Direct</span>
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [flexGrow]="2" name="Date" prop="review_given_date">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          {{row.review_given_date | date:'medium'}}
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [flexGrow]="1" name="Flag" prop="review_given_date">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                       <span title="Flagged By AstroRiver Team" *ngIf="row.is_review_flagged && row.is_auto_flagged" class="fa fa-flag text-danger"></span>
                                       <span title="Flagged By Customer" *ngIf="row.is_review_flagged && row.is_customer_flagged" class="fa fa-flag text-danger" (click)="flagReviewReason(reason,row)"></span>
                                       <span title="Flagged By Astrologer" *ngIf="row.is_review_flagged && row.is_astrologer_flagged" class="fa fa-flag text-danger" (click)="flagReviewReason(reason,row)"></span>
                                       <span *ngIf="!row.is_review_flagged" class="fa fa-flag-o text-muted" (click)="flagReview(content,row.orderserviceId?._id)"></span>
                                    </ng-template>
                                </ngx-datatable-column>
                              </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->