import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {

  id: any = ''; 
  constructor(private router:Router,private loginService: LoginService, private toast: ToastrService, private spinner: NgxSpinnerService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.resendOtp()
  }

  onOtpChange(event:any){
    if(event.length == 4){
      this.loginService.verifyMobileOtp({email:this.id,otp:event}).subscribe(
        (res:any)=>{
          sessionStorage.setItem("isPhoneVerify",'true')
          this.toast.success(res.message,'Success')
          this.router.navigateByUrl('/astrologer/dashboard')
        },err =>{
          this.toast.error(err.error.message,'Error')
        }
      )
    }
  }

  resendOtp(){
    this.loginService.resendMobileOtp({email:this.id}).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
