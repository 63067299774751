<!-- CONTAINER -->
<div class="container content-area relative">
    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Support</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Support Detail</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12 col-md-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Support Details</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="table row table-borderless">
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Support ID :</strong> {{data.supportId}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Issue Title
                                                                            :</strong>{{data.support_title}}</td>
                                                                </tr>


                                                            </tbody>
                                                            <tbody class="col-lg-12 col-xl-12 p-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Issue Description
                                                                            :</strong>{{data.support_description}} </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Status :</strong><span class="badge badge-success" *ngIf="data.is_issue_solved">SOLVED</span><span class="badge badge-danger" *ngIf="!data.is_issue_solved">PENDING</span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="data.is_reply_by_system">
                                                                    <td><strong class="mr-1">Solution
                                                                            :</strong>{{data.reply_by_system}} </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong class="mr-1">Created On
                                                                            :</strong>{{data.created_at |date :'medium'}} </td>
                                                                </tr>
                                                                <tr *ngIf="data.is_reply_by_system">
                                                                    <td><strong class="mr-1">Reply On
                                                                            :</strong>{{data.replyDate | date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->