<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />

</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="productId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.productId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Name" prop="productname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.productname">{{row.productname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column [flexGrow]="1" name="Slug" prop="product_slug">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.product_slug">{{row.product_slug}}</span>
        </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column [flexGrow]="2" name="Min. Price">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="price">{{row.min_price | currency:'INR':'symbol-narrow'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/astrologer/malls-product/',row._id]" class="fa fa-eye text-primary mr-1"></a>
            <a [routerLink]="['/astrologer/malls-product/apply',row._id]" class="btn-user-custom btn btn-outline-primary btn-sm">Apply</a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>