<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />
    <div>
        <button class="btn-filter-custom btn btn-secondary mr-2" (click)="getProductRequest()">All</button>
        <button class="btn-filter-custom btn btn-primary mr-2" (click)="acceptedRequest()">Approved Request</button>
        <button class="btn-filter-custom btn btn-info mr-2" (click)="pendingRequest()">Pending Request</button>
        <button class="btn-filter-custom btn btn-danger mr-2" (click)="rejectedRequest()">Rejected Request</button>
    </div>
</div>
<!-- DETAIL MODAL -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Request Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-4">
        <table class="table table-bordered">
            <tr>
                <td><strong>Product Details:</strong></td>
                <td>
                    #{{detailData.productId.productId}} - {{detailData.productId.productname}}
                </td>
            </tr>
            <tr>
                <td><strong>Price:</strong></td>
                <td>
                    <span class="price">{{detailData.price | currency:'INR':'symbol-narrow'}}</span>
                </td>
            </tr>
            <tr>
                <td><strong>Status:</strong></td>
                <td>
                    <span *ngIf="!detailData.isApproved && !detailData.is_used" class="badge badge-warning">Pending</span>
                    <span *ngIf="detailData.isApproved && detailData.is_used" class="badge badge-success">Approved</span>
                    <span *ngIf="!detailData.isApproved && detailData.is_used" class="badge badge-danger">Rejected</span>
                </td>
            </tr>
            <tr>                
                <td><strong>Remarks:</strong></td>
                <td>{{detailData.astrologer_comment}}</td>
            </tr>
            <tr>                
                <td><strong>Date:</strong></td>
                <td>{{detailData.createdAt | date:'medium'}}</td>
            </tr>
            <tr *ngIf="detailData.is_used && detailData.admin_comment != ''">                
                <td><strong>Admin Remarks:</strong></td>
                <td>{{detailData.admin_comment }}</td>
            </tr>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
<!-- //DETAIL MODAL-->
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="productId.productId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/astrologer/malls-product/',row.productId._id]">#{{row.productId.productId}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Name" prop="productId.productname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.productId.productname">{{row.productId.productname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Status">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="!isApproved && !is_used" class="badge badge-warning">Pending</span>
            <span *ngIf="isApproved && is_used" class="badge badge-success">Approved</span>
            <span *ngIf="!isApproved && is_used" class="badge badge-danger">Rejected</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Price">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="price">{{row.price | currency:'INR':'symbol-narrow'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.createdAt | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:void(0)" (click)="openLg(content,row)"
                class="btn-user-custom btn btn-outline-primary btn-sm">Details</a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>