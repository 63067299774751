import { Inject, Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})

export class MessagingService {

  token:any=""

  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging,private userdata:UserdataService) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        //console.log(token)
        this.userdata.setFcm(token)
        //console.log(this.userdata.getFcm())
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  } 

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload:any) => {
        //console.log(payload);
        this.currentMessage.next(payload);
        const NotificationOptions = {
          body: payload.notification.body,
          data: payload.data,
          icon: payload.notification.icon
        }
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration:any) => {
          registration.showNotification(payload.notification.title, NotificationOptions);
        });
        //console.log(this.currentMessage,'ddd');
      }
    )
  }
}
