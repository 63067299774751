<div class="p-4">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Type</th>
                <th>Offer Name</th>
                <th>Display Name</th>
                <th>User Type</th>
                <th>Discount (%)</th>
            </tr>
        </thead>
        <tbody *ngIf="totalobj>0">
            <tr
                *ngIf="!offerList.is_call_offer_activate && !offerList.is_chat_offer_activate && !offerList.is_report_offer_activate && !offerList.is_query_offer_activate">
                <td colspan="5">No Active Offer!</td>
            </tr>
            <tr *ngIf="offerList.is_call_offer_activate">
                <td>Call</td>
                <td>{{offerList.call_offerId.offer_name}}</td>
                <td>{{offerList.call_offerId.offer_display_name}}</td>
                <td>
                    <span class="badge badge-primary" *ngIf="offerList.call_offerId.user_type == 1">All</span>
                    <span class="badge badge-warning" *ngIf="offerList.call_offerId.user_type == 2">Repeat User</span>
                    <span class="badge badge-success" *ngIf="offerList.call_offerId.user_type == 3">New User</span>
                </td>
                <td>{{offerList.call_offerId.offer_discount}}</td>
            </tr>
            <tr *ngIf="offerList.is_chat_offer_activate">
                <td>Chat</td>
                <td>{{offerList.chat_offerId.offer_name}}</td>
                <td>{{offerList.chat_offerId.offer_display_name}}</td>
                <td>
                    <span class="badge badge-primary" *ngIf="offerList.chat_offerId.user_type == 1">All</span>
                    <span class="badge badge-warning" *ngIf="offerList.chat_offerId.user_type == 2">Repeat User</span>
                    <span class="badge badge-success" *ngIf="offerList.chat_offerId.user_type == 3">New User</span>
                </td>
                <td>{{offerList.chat_offerId.offer_discount}}</td>
            </tr>
            <tr *ngIf="offerList.is_report_offer_activate">
                <td>Report</td>
                <td>{{offerList.report_offerId.offer_name}}</td>
                <td>{{offerList.report_offerId.offer_display_name}}</td>
                <td>
                    <span class="badge badge-primary" *ngIf="offerList.report_offerId.user_type == 1">All</span>
                    <span class="badge badge-warning" *ngIf="offerList.report_offerId.user_type == 2">Repeat User</span>
                    <span class="badge badge-success" *ngIf="offerList.report_offerId.user_type == 3">New User</span>
                </td>
                <td>{{offerList.report_offerId.offer_discount}}</td>
            </tr>
            <tr *ngIf="offerList.is_query_offer_activate">
                <td>Query</td>
                <td>{{offerList.query_offerId.offer_name}}</td>
                <td>{{offerList.query_offerId.offer_display_name}}</td>
                <td>
                    <span class="badge badge-primary" *ngIf="offerList.query_offerId.user_type == 1">All</span>
                    <span class="badge badge-warning" *ngIf="offerList.query_offerId.user_type == 2">Repeat User</span>
                    <span class="badge badge-success" *ngIf="offerList.query_offerId.user_type == 3">New User</span>
                </td>
                <td>{{offerList.query_offerId.offer_discount}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="totalobj==0">
            <tr
                *ngIf="!offerList.is_call_offer_activate && !offerList.is_chat_offer_activate && !offerList.is_report_offer_activate && !offerList.is_query_offer_activate">
                <td colspan="5">No Active Offer!</td>
            </tr>
            
        </tbody>
        
    </table>
</div>