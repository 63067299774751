<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Chat</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/orders/chat']">Chats</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>

    <!-- MODAL NOTE-->
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Note For Customer</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="noteForm" (submit)="addNote()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="note_description">Note</label>
                    <div class="input-group">
                        <textarea class="form-control" formControlName="note_description"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark" [disabled]="!noteForm.valid">Save</button>
            </div>
        </form>
    </ng-template>
    <!-- MODAL NOTE END-->

    <!-- MODAL ISSUE-->
    <ng-template #contentIssue let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="issueForm" (submit)="addIssue()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="issue_title">Issue Title</label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="issue_title">
                    </div>
                    <div *ngIf="i.issue_title.invalid && (i.issue_title.dirty || i.issue_title.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
                <div class="form-group">
                    <label for="issue_description">Description</label>
                    <div class="input-group">
                        <textarea class="form-control" formControlName="issue_description"></textarea>
                    </div>
                    <div *ngIf="i.issue_description.invalid && (i.issue_description.dirty || i.issue_description.touched)"
                        class="alert alert-danger mt-1">
                        Required.
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark" [disabled]="!issueForm.valid">Save</button>
            </div>
        </form>
    </ng-template>
    <!-- MODAL ISSUE END-->
    <!-- MODAL ISSUE DETAIL-->
    <ng-template #contentIssueDetail let-c="close" let-d="dismiss">
        <div class="modal-header py-0">
            <h4 class="modal-title" id="modal-basic-title">Reply Issue</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="issueReplyForm" (submit)="addReplyIssue()">
            <div class="modal-body">
                <div class="row chatbox">
                    <div class="col-md-12 col-lg-12 col-xl-12 chat-issue chat">
                        <div class="card">

                            <!-- MSG CARD-BODY OPEN -->
                            <div class="card-body msg_card_body py-0">

                                <div *ngFor="let reply of data.astro_issueId?.replylogs">
                                    <div class="d-flex justify-content-start" *ngIf="reply.isSystemReply">
                                        <div class="msg_cotainer">
                                            <span [innerHTML]="reply.new_reply"></span><br />
                                            <span class="msg_time">{{reply.replyDate | date:'mediumDate'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end" *ngIf="reply.isAstroReply">
                                        <div class="msg_cotainer bg-primary text-white">
                                            <span [innerHTML]="reply.new_reply"></span><br />
                                            <span class="msg_time text-white">{{reply.replyDate |
                                                date:'mediumDate'}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- MSG CARD-BODY END -->
                        </div>
                    </div>
                    <!-- COL END -->
                </div>
                <div class="row d-flex align-items-center">
                    <div class="col-10">
                        <textarea class="form-control" formControlName="reply_by_system" (keyup.enter)="addReplyIssue()"></textarea>
                    </div>
                    <div class="col-2">
                        <button type="submit" class="btn btn-outline-dark" [disabled]="!issueReplyForm.valid">Send</button>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
    <!-- MODAL ISSUE DETAIL END-->
    <!-- ROW-1 OPEN -->
    <div class=" chatbox" *ngIf = "isShowChatAcceptReject">
        <!-- chat accept box -->
        <div class="mb-5 py-3 border bg-light-pink" >
            <div class="row text-center">
              <div class="col">
                <div class="py-1 h5 fw-600 text-red">New Chat Request
                </div>
              </div>
            </div>
            <div class="row  text-center">
              <div class="col">
                <div class="py-1 text-dark">Please accept to join & start chatting</div>
              </div>
            </div>
            <!-- <div class="  text-center ">
              <div class="col">
                <div class="pb-3 ">Time Left: <span class="fw-600">12:40</span></div>
              </div>
            </div> -->
            <div class="row  text-center justify-content-center my-2">
              <div class="mx-2">
                <a type="button" class="btn btn-success btn-sm text-white px-5" (click)="acceptChat(true)">Accept</a>
              </div>
              <div class="mx-2">
                <a type="button" class="btn btn-danger btn-sm text-white px-5" (click)="acceptChat(false)">Reject</a>
              </div>
            </div>
          </div>
          <!-- chat accept box end -->
    </div>
    <div class="row chatbox">
        <div class="col-md-12 col-lg-12 col-xl-12 chat">
            <div class="card">
                <!-- ACTION HEDAER OPEN -->
                <div class="action-header clearfix">
                    <div class="float-left hidden-xs d-flex ml-2">
                        <div class="align-items-center mt-2">
                            <h4 class="mb-0 font-weight-semibold">Chat Details</h4>
                        </div>
                    </div>
                </div>
                <!-- ACTION HEADER END -->

                <!-- MSG CARD-BODY OPEN -->
                <div class="card-body msg_card_body">

                    <div *ngFor="let chat of data.chatId?.chatinfo">
                        <div class="d-flex justify-content-start"
                            *ngIf="(chat.type == 1 || chat.type == 3) && chat.is_to_show && (chat.message != data.astroId.name+' has ended the chat' && chat.message != data.customerId.name+' has ended the chat' && chat.message != 'The chat has ended due to low wallet balance. You may continue the conversation by recharging and connecting again. For any help, please message support')">
                            <ng-container *ngIf="chat.isImage">
                                <div class="msg_cotainer">
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 0"><img
                                            [src]="getSanitizeUrl(chat.message)" class="img-chat"
                                            (click)="lightOpen(chat.message)" /></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/doc.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/excel.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/pdf-2.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/unkown.png"
                                                class="img-chat-doc" /></a></span>
                                    <!-- <span class="bg-primary text-white p-2" *ngIf="checkExtenstion(chat.message) == 1"><a [href]="getSanitizeUrl(chat.message)" target="_blank"><img src="../../../../../assets/icons/doc.png"  class="img-chat-doc"/></a></span>
                                    <span class="bg-primary text-white p-2" *ngIf="checkExtenstion(chat.message) == 2"><a [href]="getSanitizeUrl(chat.message)" target="_blank"><img src="../../../../../assets/icons/pdf-2.png"  class="img-chat-doc"/></a></span> -->
                                    <br />
                                    <span class="msg_time">{{chat.date | date:'medium'}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!chat.isImage">
                                <div class="msg_cotainer">
                                    <span [innerHTML]="chat.message"></span><br />
                                    <span class="msg_time">{{chat.date | date:'mediumDate'}}</span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="d-flex justify-content-start"
                            *ngIf="(chat.type == 1 || chat.type == 3) && chat.is_to_show && (chat.message == data.astroId.name+' has ended the chat' || chat.message == data.customerId.name+' has ended the chat' || chat.message == 'The chat has ended due to low wallet balance. You may continue the conversation by recharging and connecting again. For any help, please message support')">
                            <div class="msg_cotainer bg-orange text-white">
                                <span [innerHTML]="chat.message"></span><br />
                            </div>
                        </div>
                        <div class="d-flex justify-content-end" *ngIf="chat.type == 2">
                            <ng-container *ngIf="chat.isImage">
                                <div class="msg_cotainer">
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 0"><img
                                            [src]="getSanitizeUrl(chat.message)" class="img-chat"
                                            (click)="lightOpen(chat.message)" /></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/doc.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/excel.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/pdf-2.png"
                                                class="img-chat-doc" /></a></span>
                                    <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"><a
                                            [href]="getOpenUrl(chat.message)" target="_blank"><img
                                                src="../../../../../assets/icons/unkown.png"
                                                class="img-chat-doc" /></a></span><br />
                                    <!-- <span class="bg-primary text-white p-2" *ngIf="checkExtenstion(chat.message) == 1"><a [href]="getSanitizeUrl(chat.message)" target="_blank"><img src="../../../../../assets/icons/doc.png"  class="img-chat-doc"/></a></span>
                                    <span class="bg-primary text-white p-2" *ngIf="checkExtenstion(chat.message) == 2"><a [href]="getSanitizeUrl(chat.message)" target="_blank"><img src="../../../../../assets/icons/pdf-2.png"  class="img-chat-doc"/></a></span> -->
                                    <span class="msg_time text-dark">{{chat.date | date:'medium'}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!chat.isImage">
                                <div class="msg_cotainer bg-primary text-white">
                                    <span [innerHTML]="chat.message"></span><br />
                                    <span class="msg_time text-white" *ngIf="!chat.is_to_show">This message will only
                                        appear to you.</span>
                                    <span class="msg_time text-white">{{chat.date | date:'mediumDate'}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </div>
                <!-- MSG CARD-BODY END -->
            </div>
        </div>
        <!-- COL END -->
    </div>
    <div class="row" id="user-profile">
        <div class="col-lg-8 col-md-4">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading d-flex justify-content-between">
                                                        <h4>Chat Details
                                                            <span
                                                                *ngIf="data.chatId?.is_promotional_call && !data.chatId?.is_direct_promo_call && !data.chatId?.is_report_promo_call && !data.chatId?.is_paid_promotional_call"
                                                                class="badge badge-success mr-2">FIRST PROMO CALL</span>
                                                            <span
                                                                *ngIf="data.chatId?.is_promotional_call && data.chatId?.is_report_promo_call"
                                                                class="badge badge-success mr-2">REPORT PROMO
                                                                CALL</span>
                                                            <span
                                                                *ngIf="data.chatId?.is_promotional_call && data.chatId?.is_direct_promo_call && data.chatId?.is_paid_promotional_call"
                                                                class="badge badge-success mr-2">PAID PROMO CALL</span>
                                                            <span
                                                                *ngIf="data.chatId?.is_promotional_call && data.chatId?.is_direct_promo_call && !data.chatId?.is_paid_promotional_call"
                                                                class="badge badge-success mr-2">FREE PROMO CALL</span>
                                                            <span *ngIf="data.chatId?.is_first_order"
                                                                class="badge badge-success">AR NEW</span><span
                                                                *ngIf="data.chatId?.is_new_for_astrologer"
                                                                class="badge badge-success">NEW FOR YOU</span><span
                                                                *ngIf="!data.chatId?.is_first_order"
                                                                class="badge badge-info">AR REPEAT</span>
                                                        </h4>
                                                        <div>
                                                            <button
                                                                class="mr-2 btn btn-outline-primary btn-filter-custom"
                                                                *ngIf="data.chatId?.is_order_completed && !data.is_remedy_suggested && checkSevenDays(data.chatId?.created_at)"
                                                                [routerLink]="['/astrologer/remedy/suggest',data._id]">Suggest
                                                                Remedy</button>
                                                            <button class="mr-2 btn-user-custom btn btn-outline-primary"
                                                                *ngIf="data.is_remedy_suggested "
                                                                [routerLink]="['/astrologer/remedy/suggest',data._id]">Check
                                                                Suggested Remedy</button>
                                                            <button
                                                                class="mr-2 btn btn-outline-secondary btn-filter-custom"
                                                                *ngIf="data.chatId?.is_order_completed && !data.is_astro_issue_created"
                                                                (click)="openIssue(contentIssue)">Help</button>
                                                            <button class="btn btn-outline-warning btn-filter-custom"
                                                                (click)="open(content)">Note</button>
                                                        </div>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td><strong>Chat Id :</strong>
                                                                        #{{data.chatId?.ochatId}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <!-- <td><strong>Astrologer Charges :</strong>
                                                                        {{data.chatId?.astroCostPerMinute}}/min.
                                                                    </td> -->
                                                                    <td><strong>Astrologer Charges : </strong>
                                                                        <span class="price"
                                                                            *ngIf="!data?.is_foreign_order && data?.is_foreign_order != null">{{data?.chatId?.astroDisplayPrice/2
                                                                            | currency:'INR':'symbol-narrow'}}</span>

                                                                        <span class="price"
                                                                            *ngIf="(data?.is_foreign_order || data?.is_foreign_order == null) && data?.isAstroExclusive">{{data?.chatId?.astroDisplayPrice
                                                                            | currency:'INR':'symbol-narrow'}}</span>

                                                                        <span class="price"
                                                                            *ngIf="(data?.is_foreign_order || data?.is_foreign_order == null) && !data?.isAstroExclusive">{{data?.chatId?.astroDisplayPrice/2
                                                                            | currency:'INR':'symbol-narrow'}}</span>
                                                                        /min.
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Chat Duration :</strong>
                                                                        {{convertSecondstoTime(data.chatId?.totalchatDuration)}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Chat Charges : </strong> <span
                                                                            class="price">{{data.chatId?.astro_amount |
                                                                            currency:'INR':'symbol-narrow'}}</span>
                                                                        <span class="badge badge-pill badge-primary"
                                                                            *ngIf="data.chatId?.is_foreign_order">{{data.customerId?.regCountryName}}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                                <tr>
                                                                    <td class="text-uppercase"><strong
                                                                            class="text-capitalize">Status : </strong>
                                                                        <span
                                                                            class="badge badge-info">{{data.chatId?.order_current_status}}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody
                                                                class="border-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Order Date :</strong>
                                                                        {{data.chatId?.created_at | date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ROW-1 CLOSED -->
            <div class="card" *ngIf="data.chatId?.is_feedback_given">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Feedback</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Question :</strong>
                                                                        {{data.chatId?.feedback_value}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="!data.chatId?.is_astro_replied_feedback && !checkThreeDays(data.chatId?.feedback_date)">
                                                                <tr>
                                                                    <td><strong>Note: You cannot reply to this feedback
                                                                            after 3 days.</strong></td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.chatId?.is_astro_replied_feedback">
                                                                <tr>
                                                                    <td><strong>Answer :</strong>
                                                                        {{data.chatId?.astro_feedback_reply}} <button
                                                                            class="btn btn-link"
                                                                            (click)="updateFeedbackReply()"
                                                                            *ngIf="checkThreeDays(data.chatId?.feedback_date)">Change</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Question Time :</strong>
                                                                        {{data.chatId?.feedback_date | date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.chatId?.is_astro_replied_feedback">
                                                                <tr>
                                                                    <td><strong>Answer Time:</strong>
                                                                        {{data.chatId?.astro_feedback_date |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <form [formGroup]="feedbackReply" (submit)="feedbackAnswer()"
                                                        *ngIf="!data.chatId?.is_astro_replied_feedback && checkThreeDays(data.chatId?.feedback_date)">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_feedback_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="h.astro_feedback_reply.invalid && (h.astro_feedback_reply.dirty || h.astro_feedback_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!feedbackReply.valid">
                                                                        Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <form [formGroup]="feedbackReply" (submit)="feedbackAnswer()"
                                                        *ngIf="data.chatId?.is_astro_replied_feedback && changeFeedbackReply">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_feedback_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="h.astro_feedback_reply.invalid && (h.astro_feedback_reply.dirty || h.astro_feedback_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!feedbackReply.valid">
                                                                        Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ROW-1 OPEN -->
            <div class="card" *ngIf="data.chatId?.is_order_reviewed">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div class="card-body">
                                    <div class="border-0">
                                        <div class="tab-content">
                                            <div class="tab-pane active show" id="tab-51">
                                                <div id="profile-log-switch">
                                                    <div class="media-heading">
                                                        <h4>Review</h4>
                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="table-responsive ">
                                                        <table class="user-table-custom table row table-bordered">
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Review :</strong>
                                                                        {{data.chatId?.customer_review}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Stars :</strong>
                                                                        <ng-template #t let-fill="fill">
                                                                            <span class="star"
                                                                                [class.full]="fill === 100">
                                                                                <span class="half"
                                                                                    [style.width.%]="fill">&#x2605;</span>&#x2605;
                                                                            </span>
                                                                        </ng-template>

                                                                        <ngb-rating
                                                                            [(rate)]="data.chatId.customer_review_stars"
                                                                            [starTemplate]="t" [readonly]="true"
                                                                            [max]="5"></ngb-rating>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="!data.chatId?.is_astro_replied_review && !checkThreeDays(data.chatId?.review_given_date)">
                                                                <tr>
                                                                    <td><strong>Note: You cannot reply to this review
                                                                            after 3 days.</strong></td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0"
                                                                *ngIf="data.chatId?.is_astro_replied_review">
                                                                <tr>
                                                                    <td><strong>Astrologer Review Reply:</strong>
                                                                        {{data.chatId?.astro_review_reply}} <button
                                                                            class="btn btn-link"
                                                                            (click)="updateReviewReply()"
                                                                            *ngIf="checkThreeDays(data.chatId?.review_given_date)">Change</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr>
                                                                    <td><strong>Reviewed On :</strong>
                                                                        {{data.chatId?.review_given_date |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody class="border-0 col-lg-12 col-xl-12 pr-0">
                                                                <tr *ngIf="data.chatId?.is_astro_replied_review">
                                                                    <td><strong>Review Reply :</strong>
                                                                        {{data.chatId?.astro_review_date |
                                                                        date:'medium'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="!data.chatId?.is_astro_replied_review && checkThreeDays(data.chatId?.review_given_date)">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_review_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.astro_review_reply.invalid && (g.astro_review_reply.dirty || g.astro_review_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <form [formGroup]="reviewReply" (submit)="reviewAnswer()"
                                                        *ngIf="data.chatId?.is_astro_replied_review && changeReviewReply">
                                                        <div class="row mt-1">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for=""><strong>Answer: </strong></label>
                                                                    <textarea class="form-control"
                                                                        formControlName="astro_review_reply"
                                                                        rows="5"></textarea>
                                                                    <div *ngIf="g.astro_review_reply.invalid && (g.astro_review_reply.dirty || g.astro_review_reply.touched)"
                                                                        class="alert alert-danger mt-1">
                                                                        Required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="user-wrap d-flex justify-content-center">
                                                                    <button type="submit"
                                                                        class="col-sm-4 btn-form-custom btn btn-success mt-1 mb-2"
                                                                        [disabled]="!reviewReply.valid"> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
        <div class="col-lg-4 col-md-4">
            <div class="card productdesc">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Client
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row" *ngIf="data.customerId?.regCountryName">
                                            <div class="col-sm-4 text-muted">Registered Country</div>
                                            <div class="col-sm-8">{{data.customerId?.regCountryName}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.name">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.name}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.gender">
                                            <div class="col-sm-4 text-muted">Gender</div>
                                            <div class="col-sm-8">{{data.personId?.gender}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.marital_status">
                                            <div class="col-sm-4 text-muted">Marital Status</div>
                                            <div class="col-sm-8">{{data.personId?.marital_status}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.dob">
                                            <div class="col-sm-4 text-muted">Date Of Birth</div>
                                            <div class="col-sm-8">{{data.personId?.dob}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.tob">
                                            <div class="col-sm-4 text-muted">Time Of Birth</div>
                                            <div class="col-sm-8">{{data.personId?.tob}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.city">
                                            <div class="col-sm-4 text-muted">City</div>
                                            <div class="col-sm-8">{{data.personId?.city}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.state">
                                            <div class="col-sm-4 text-muted">State</div>
                                            <div class="col-sm-8">{{data.personId?.state}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.country">
                                            <div class="col-sm-4 text-muted">Country</div>
                                            <div class="col-sm-8">{{data.personId?.country}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.employed_in">
                                            <div class="col-sm-4 text-muted">Occupation</div>
                                            <div class="col-sm-8">{{data.personId?.employed_in}}</div>
                                        </li>
                                        <li class="row" *ngIf="data.personId?.comments">
                                            <div class="col-sm-4 text-muted">Note</div>
                                            <div class="col-sm-8">{{data.personId?.comments}}</div>
                                        </li>
                                    </ul>
                                    <h4 class="mb-5 mt-3" *ngIf="data.personId?.is_partner_details">Partner Details</h4>
                                    <ul class="list-unstyled mb-0" *ngIf="data.personId?.is_partner_details">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.partner_name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.partner_dob}}
                                                {{data.personId?.partner_tob}}, {{data.personId?.partner_city}},
                                                {{data.personId?.partner_state}}, {{data.personId?.partner_country}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="card productdesc" *ngIf="data.is_remedy_suggested">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Suggested
                                            Remedy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">ID</div>
                                            <div class="col-sm-8">#{{suggestedRemedy?.rsId}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Remedy Type</div>
                                            <div class="col-sm-8"><span class="badge badge-info"
                                                    *ngIf="suggestedRemedy?.isPaid">PAID</span><span
                                                    class="badge badge-success"
                                                    *ngIf="!suggestedRemedy?.isPaid">FREE</span><span
                                                    class="badge badge-warning"
                                                    *ngIf="suggestedRemedy?.is_created_by_astro_remedy">OWN</span></div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && !suggestedRemedy?.is_created_by_astro_remedy">
                                            <div class="col-sm-4 text-muted">Product Name</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.suggestedProductId?.productname}}
                                            </div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && suggestedRemedy?.is_created_by_astro_remedy">
                                            <div class="col-sm-4 text-muted">Category Name</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.categoryId.categoryname}}
                                            </div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && suggestedRemedy?.is_created_by_astro_remedy">
                                            <div class="col-sm-4 text-muted">Product Name</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.product_name}}
                                            </div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.remedy_description != '' && suggestedRemedy?.remedy_description != null">
                                            <div class="col-sm-4 text-muted">Description</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.remedy_description}}</div>
                                        </li>
                                        <li class="row"
                                            *ngIf="suggestedRemedy?.isPaid && suggestedRemedy?.suggestedastroId != null">
                                            <div class="col-sm-4 text-muted">Suggested Astrologer</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.suggestedastroId?.name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Suggested On</div>
                                            <div class="col-sm-8">{{suggestedRemedy?.created_at | date:'medium'}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="card productdesc" *ngIf="data.is_astro_issue_created">
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li class="custom-tab"><a href="#tab1" class="active" data-toggle="tab">Issue
                                            Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab1">
                                    <!-- <h4 class="mb-5 mt-3">General</h4> -->
                                    <ul class="list-unstyled mb-0">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Issue Id</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issueId}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Amount</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.amount}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Title</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issue_title}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Description</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.issue_description}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Status</div>
                                            <div class="col-sm-8">
                                                <span class="badge badge-success"
                                                    *ngIf="data.astro_issueId?.is_issue_solved">SOLVED</span>
                                                <span class="badge badge-danger"
                                                    *ngIf="!data.astro_issueId?.is_issue_solved">PENDING</span>
                                            </div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Created At</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.created_at | date:'medium'}}
                                            </div>
                                        </li>
                                        <li class="row" *ngIf="data.astro_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.reply_by_system}}</div>
                                        </li>

                                        <li class="row" *ngIf="data.astro_issueId?.is_reply_by_system">
                                            <div class="col-sm-4 text-muted">Reply</div>
                                            <div class="col-sm-8">{{data.astro_issueId?.replyupdatedBy | date:'medium'}}
                                            </div>
                                        </li>

                                        <li class="row"><button class="btn btn-sm btn-block text-white"
                                                style="background-color: #564fc1;"
                                                (click)="openIssueReply(contentIssueDetail)">View Issue Details</button>
                                        </li>
                                    </ul>
                                    <h4 class="mb-5 mt-3" *ngIf="data.personId?.is_partner_details">Partner Details</h4>
                                    <ul class="list-unstyled mb-0" *ngIf="data.personId?.is_partner_details">
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Name</div>
                                            <div class="col-sm-8">{{data.personId?.partner_name}}</div>
                                        </li>
                                        <li class="row">
                                            <div class="col-sm-4 text-muted">Birth Details</div>
                                            <div class="col-sm-8">{{data.personId?.partner_dob}}
                                                {{data.personId?.partner_tob}}, {{data.personId?.partner_city}},
                                                {{data.personId?.partner_state}}, {{data.personId?.partner_country}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->