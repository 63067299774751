import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ScrollToBottomDirective } from 'src/app/astrologer/directive/scroll-to-bottom/scroll-to-bottom.directive';
import { Chat } from 'src/app/astrologer/services/firebase/chat';
import { FirebaseServiceService } from 'src/app/astrologer/services/firebase/firebase-service.service';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { LightboxConfig, Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ongoing-chat-astrologer',
  templateUrl: './ongoing-chat-astrologer.component.html',
  styleUrls: ['./ongoing-chat-astrologer.component.scss']
})
export class OngoingChatAstrologerComponent implements OnInit {

  /**
   * Scroll to bottom
   */
  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;

  /**----------------------------- */
  id; dataList: any = ''; chatId; chatsRef: any = ''
  chatList: Array<Chat> = [];
  customerId = ''
  maxTimeDuration = 0
  public isDecided = false
  public isCustomerAccept = false
  isCustomerReject = false
  initialMsg = ''
  constructor(
    private route: ActivatedRoute,
    private orderService: OrderServiceService,
    private toast: ToastrService,
    private userdata: UserdataService,
    private db: AngularFireDatabase,
    private firebaseService: FirebaseServiceService,
    private router: Router,
    private modalService: NgbModal,
    private _lightbox: Lightbox,
    private _lightboxConfig: Lightbox,
    private cdref: ChangeDetectorRef,
    private zone: NgZone,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    //console.log('hello')
    this.id = this.route.snapshot.paramMap.get('id')
    this.getServiceOrderDetail()
  }

  getServiceOrderDetail() {
    this.orderService.getOrderDetail({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.dataList = res.data
        this.customerId = res.data.customerId._id
        this.getRecentChats()
        this.chatId = this.dataList.chatId._id
        this.initialMsg = this.dataList.chatId.initial_msg
        if (this.dataList.chatId.is_customer_acceptance_used && this.dataList.chatId.is_customer_accept) {
          this.isDecided = true
          this.isCustomerAccept = true
          /**
         * Updated code for time left according to balance
         */
          //this.maxTimeDuration = this.dataList.chatId.maxChatDuration
          var totalDuration = this.dataList.chatId.maxChatDuration
          var date = moment(this.dataList.chatId.customer_accepting_time);
          var accept = date.utc().format('YYYY-MM-DD hh:mm:ss');
          //var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
          var currentdate = moment(res.server_current_time);//for server time
          var current = currentdate.utc().format('YYYY-MM-DD hh:mm:ss');//for server time
          var acceptTime = moment(accept, 'YYYY-MM-DD hh:mm:ss');
          var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
          var secondsDiff = currentTime.diff(acceptTime, 'seconds');
          this.maxTimeDuration = totalDuration - secondsDiff
          /**----------------------------------------------------------- */
          this.startCountdown(this.maxTimeDuration)
        }
        if (this.dataList.is_end) {
          this.zone.run(() => {
            this.router.navigateByUrl('/astrologer/chat/' + this.dataList._id)
          });
          
        }
        //console.log(this.maxTimeDuration,'2')
        this.chatsRef = firebase.database().ref('chat/' + this.chatId);
        this.retrieveAllChats()
        this.childAdded()
        this.childChanged()
      }, err => {
        //console.log(err)
        //this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * View Customer Details
   */
  openViewCustomerDetails(content) {
    this.modalService.open(content);
  }

  /**
   * Retrieve all Chat from Backend
   */
  retrieveAllChats() {
    this.firebaseService.getChatList(this.chatId).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(chat => {
      this.chatList = chat;
      if (this.chatList.length <= 0) {
        this.zone.run(() => {
          this.router.navigateByUrl('/astrologer/chat/' + this.dataList._id)
        });
      }
    }, (error) => {
      //console.log(error);
    });
  }

  /**
   * Child Added
   */

  childAdded() {
    var custName = this.dataList.customerId.name
    var id = this.dataList._id
    var toast = this.toast
    var router = this.router
    var demo = this
    this.chatsRef.on("child_added", function (snapshot) {
      var data = snapshot.val();
      var key = snapshot.key;
      //console.log(data)
      if (data.message == custName + ' has ended the chat') {
        toast.success('Customer has ended the chat.', 'Success')
        demo.zone.run(() => {
          router.navigateByUrl('/astrologer/chat/' + id)
        });
        return
      }
      if (data.message == 'Customer has accepted the chat') {
        demo.customerChatChange()
        return
      }
      if (data.message == 'Customer Rejected Chat') {
        toast.success('Customer has Rejected the chat.', 'Error')
        demo.zone.run(() => {
          router.navigateByUrl('/astrologer/chat/' + id)
        });
        return
      }
      if (data.message == 'Customer missed the chat') {
        toast.success('Customer has missed the chat.', 'Error')
        demo.zone.run(() => {
          router.navigateByUrl('/astrologer/chat/' + id)
        });
        return
      }
      if (data.is_read != undefined && (!data.is_read && data.type == 3)) {
        //console.log('read')
        demo.chatsRef.child(key).update({ is_read: true });
        //console.log(data, i, key)
      }
      if (key == '- Typing') {
        if (data.customer == true) {
          demo.customerTyping = true
        }
        else {
          demo.customerTyping = false
        }
      }
    })

  }

  /**
   * Child Changed
   */
  customerTyping = false
  childChanged() {
    var demo = this
    this.chatsRef.on("child_changed", function (snapshot) {
      var data = snapshot.val();
      if (data.maxChatDuration != undefined) {
        let currentUrl = demo.router.url;
        demo.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          demo.router.navigate([currentUrl]);
        });
      }
      var key = snapshot.key;
      if (key == '- Typing') {
        if (data.customer == true) {
          demo.customerTyping = true
        }
        else {
          demo.customerTyping = false
        }
      }
    })

  }

  /**
   * On Input focus show astrologer typing true
   */
  onKeyUpInput(event: any) {
    //console.log(event)
    if (event.target.value.length > 0) {
      this.chatsRef.child('- Typing').update({ astrologer: true });
    } else {
      this.chatsRef.child('- Typing').update({ astrologer: false });
    }
  }

  /**
   * On Input Blur show astrologer typing true
   */
  onBlurInput(event: any) {
    //console.log('blur')
    this.chatsRef.child('- Typing').update({ astrologer: false });
  }

  customerChatChange() {
    //console.log('inside')
    //return
    this.orderService.getOrderDetail({ _id: this.id, astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.dataList = res.data
        this.chatId = this.dataList.chatId._id
        this.initialMsg = this.dataList.chatId.initial_msg
        //console.log(this.dataList)
        if (this.dataList.chatId.is_customer_acceptance_used && this.dataList.chatId.is_customer_accept) {
          this.isDecided = true
          this.isCustomerAccept = true
          /**
         * Updated code for time left according to balance
         */
          //this.maxTimeDuration = this.dataList.chatId.maxChatDuration
          var totalDuration = this.dataList.chatId.maxChatDuration
          var date = moment(this.dataList.chatId.customer_accepting_time);
          var accept = date.utc().format('YYYY-MM-DD hh:mm:ss');
          //var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
          var currentdate = moment(res.server_current_time);//for server time
          var current = currentdate.utc().format('YYYY-MM-DD hh:mm:ss');//for server time
          var acceptTime = moment(accept, 'YYYY-MM-DD hh:mm:ss');
          var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
          var secondsDiff = currentTime.diff(acceptTime, 'seconds');
          this.maxTimeDuration = totalDuration - secondsDiff
          /**----------------------------------------------------------- */
          //console.log(this.maxTimeDuration,'1')
        }
        if (this.dataList.is_end) {
          this.zone.run(() => {
            this.router.navigateByUrl('/astrologer/chat/' + this.dataList._id)
          });
        }
        // this.startCountdown(this.maxTimeDuration)
        //console.log(this.maxTimeDuration,'2')
        this.chatsRef = firebase.database().ref('chat/' + this.chatId);
        this.retrieveAllChats()
        //this.childAdded()
        /**
         * Time
         */
        let counters = this.maxTimeDuration;

        const interval = setInterval(() => {
          //const sec = parseInt(counters, 10); // convert value to number if it's string
          this.hours = Math.floor(counters / 3600); // get hours
          this.minutes = Math.floor((counters - (this.hours * 3600)) / 60); // get minutes
          this.seconds = counters - (this.hours * 3600) - (this.minutes * 60); //  get seconds
          // add 0 if value < 10; Example: 2 => 02
          if (this.hours < 10) { this.hours = "0" + this.hours; }
          if (this.minutes < 10) { this.minutes = "0" + this.minutes; }
          if (this.seconds < 10) { this.seconds = "0" + this.seconds; }
          //this.convertHMS(counters)
          //console.log(counter);
          counters--;

          if (counters < 0) {
            clearInterval(interval);
            //console.log('Ding!');
            if (this.isCustomerAccept) { this.endChat(1) }
          }
        }, 1000);
        /**----------------------- */
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Send Chat Message
   */
  isImageUpload = false
  sendMsgForm = new FormGroup({
    chatId: new FormControl(''),
    astroId: new FormControl(''),
    message: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    is_to_show: new FormControl(true),
    isImage: new FormControl(false),
    isFile: new FormControl(false),
  });

  get f() {
    return this.sendMsgForm.controls
  }

  sendMsg() {
    this.sendMsgForm.patchValue({ chatId: this.chatId })
    this.sendMsgForm.patchValue({ astroId: this.userdata.getId() })
    this.sendMsgForm.patchValue({ name: this.userdata.getDisplayName() })
    //this.sendMsgForm.patchValue({ name: this.userdata.getName() })
    if (!this.isImageUpload) {
      var msg = (this.sendMsgForm.get('message').value).trim()
      if (msg != '' && msg != null) {
        var count = (msg.match(/\d/g) || []).length
        var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
        if (count == 10 || (emailsArray != null && emailsArray.length)) {
          this.sendMsgForm.patchValue({ is_to_show: false })
          //console.log(this.sendMsgForm.get('is_to_show').value, 'if')
        }
        this.sendMsgForm.patchValue({ message: msg })
        const data = new FormData()
        data.append("chatId", this.sendMsgForm.get("chatId").value)
        data.append("astroId", this.sendMsgForm.get("astroId").value)
        data.append("message", this.sendMsgForm.get("message").value)
        data.append("name", this.sendMsgForm.get("name").value)
        data.append("is_to_show", this.sendMsgForm.get("is_to_show").value)
        data.append("isImage", this.sendMsgForm.get("isImage").value)
        data.append("isFile", this.sendMsgForm.get("isFile").value)
        //console.log(this.sendMsgForm.get('is_to_show').value, 'after if')
        this.orderService.saveChatMsg(data).subscribe(
          (res: any) => {
            this.sendMsgForm.reset()
            this.sendMsgForm.patchValue({ message: '' })
            this.sendMsgForm.patchValue({ is_to_show: true })
            this.sendMsgForm.patchValue({ isImage: false })
            this.sendMsgForm.patchValue({ isFile: false })
            this.f
            //console.log(this.sendMsgForm.get('is_to_show').value, 'result')
          }, err => {
            this.toast.error(err.error.message, 'Error')
          }
        )
      }
    }
    if (this.isImageUpload) {
      const data = new FormData()
      data.append("chatId", this.sendMsgForm.get("chatId").value)
      data.append("astroId", this.sendMsgForm.get("astroId").value)
      data.append("message", this.sendMsgForm.get("message").value)
      data.append("name", this.sendMsgForm.get("name").value)
      data.append("is_to_show", this.sendMsgForm.get("is_to_show").value)
      data.append("isImage", this.sendMsgForm.get("isImage").value)
      data.append("isFile", this.sendMsgForm.get("isFile").value)
      //console.log(this.sendMsgForm.get('is_to_show').value, 'after if')
      this.orderService.saveChatMsg(data).subscribe(
        (res: any) => {
          this.sendMsgForm.reset()
          this.sendMsgForm.patchValue({ message: '' })
          this.sendMsgForm.patchValue({ is_to_show: true })
          this.sendMsgForm.patchValue({ isImage: false })
          this.sendMsgForm.patchValue({ isFile: false })
          this.f
          //console.log(this.sendMsgForm.get('is_to_show').value, 'result')
        }, err => {
          this.toast.error(err.error.message, 'Error')
        }
      )
    }
  }

  checkEnterPressed(event: any) {
    if (event.keyCode === 13) {
      this.sendMsg()
    }
  }

  /**
   * End Chat
   */

  endChatForm = new FormGroup({
    chatId: new FormControl(''),
    astroId: new FormControl(''),
    message: new FormControl(''),
    // duration: new FormControl(''),
    is_astro_end_chat: new FormControl(true),
  });

  get e() {
    return this.endChatForm.controls
  }

  endChat(id?) {
    var msg = ''
    this.endChatForm.patchValue({ chatId: this.chatId })
    this.endChatForm.patchValue({ astroId: this.userdata.getId() })
    if (id == undefined || id == null || id == '') {
      msg = this.userdata.getDisplayName() + ' has ended the chat';
    }
    if (id != undefined && id == 1) {
      msg = 'The chat has ended due to low wallet balance. You may continue the conversation by recharging and connecting again. For any help, please message support';
    }
    //console.log(msg)
    this.endChatForm.patchValue({ message: msg })
    var date = moment(this.dataList.chatId?.customer_accepting_time);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var accept = date.utc().format('YYYY-MM-DD hh:mm:ss');
    var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
    var acceptTime = moment(accept, 'YYYY-MM-DD hh:mm:ss');
    var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
    var secondsDiff = currentTime.diff(acceptTime, 'seconds');
    this.endChatForm.patchValue({ duration: secondsDiff })
    this.orderService.endChat(this.endChatForm.value).subscribe(
      (res: any) => {
        this.zone.run(() => {
          this.router.navigateByUrl('/astrologer/chat/' + this.dataList._id)
        });
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Timer
   */
  startCountdown(seconds) {
    let counters = seconds;

    const interval = setInterval(() => {
      this.convertHMS(counters)
      //console.log(counter);
      counters--;

      if (counters < 0) {
        clearInterval(interval);
        //console.log('Ding!');
        if (this.isCustomerAccept) { this.endChat(1) }
      }
    }, 1000);
  }

  hours: any; minutes: any; seconds: any
  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    this.hours = Math.floor(sec / 3600); // get hours
    this.minutes = Math.floor((sec - (this.hours * 3600)) / 60); // get minutes
    this.seconds = sec - (this.hours * 3600) - (this.minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (this.hours < 10) { this.hours = "0" + this.hours; }
    if (this.minutes < 10) { this.minutes = "0" + this.minutes; }
    if (this.seconds < 10) { this.seconds = "0" + this.seconds; }
    //console.log(this.hours+':'+this.minutes+':'+this.seconds);
    //return this.hours+':'+minutes+':'+this.seconds; // Return is HH : MM : SS
  }

  /**
   * M3- Attachements
   */

  /**
   * Get attachment type
   */
  checkExtenstion(val: any) {
    var fileName, fileExtension;
    fileName = val
    fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toLowerCase();
    if (fileExtension == 'jpg' || fileExtension == 'png' || fileExtension == 'jpeg') {
      return 0
    } else if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'txt') {
      return 1
    } else if (fileExtension == 'xlsx' || fileExtension == 'xls' || fileExtension == 'csv') {
      return 2
    } else if (fileExtension == 'pdf') {
      return 3
    } else {
      return 4
    }
  }

  BaseUrl: any;

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  public getOpenUrl(url: string) {
    // return this.trusturl.bypassSecurityTrustUrl('http://docs.google.com/viewer?url=' + this.BaseUrl + url)
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
  private _albums = [];
  open(val: any): void {
    this._albums = []
    const album = {
      src: this.BaseUrl + val,
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, { wrapAround: true, showImageNumberLabel: true, fitImageInViewPort: true, centerVertically: true });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onFileSelected(evnt: any) {
    // console.log(evnt.target.files[0].type,evnt.target.files[0],ext)
    // return
    if (evnt.target.files.length > 0) {
      if (evnt.target.files && evnt.target.files[0]) {        
        var ext =  evnt.target.files[0].name.split('.').pop();
        const allowed_ext = ['png','jpeg','jpg','pdf','csv','doc','docx','xlsx']
        const allowed_types = ['image/png', 'image/jpeg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'text/csv', 'application/doc', 'application/ms-doc', 'application/msword', 'application/excel', 'application/vnd.ms-excel'];
        if (!allowed_types.includes(evnt.target.files[0].type) && !allowed_ext.includes(ext.toLowerCase())) {
          Swal.fire('Invalid File Extension', 'Only Images,Doc, PDF, Excel are allowed', 'error');
          return;
        } else {
          this.sendMsgForm.patchValue({
            message: evnt.target.files[0]
          })
          this.sendMsgForm.patchValue({
            isImage: true
          })
          if ((evnt.target.files[0].type == ('image/png' || 'image/jpeg' || 'image/jpg') || (ext.toLowerCase() == ('png'||'jpg'||'jpeg')))) {
            //console.log('if')
            this.sendMsgForm.patchValue({
              isFile: false
            })
          } else {
            //console.log('else')
            this.sendMsgForm.patchValue({
              isFile: true
            })
          }
          this.isImageUpload = true
          this.sendMsg()
        }
      }
    }
  }

  /**
   * Recent Chats
   */
  recentChatList: any = []

  getRecentChats() {
    this.orderService.getRecentChats({ astroId: this.userdata.getId(), customerId: this.customerId, is_order_completed: true }).subscribe(
      (res: any) => {
        this.recentChatList = res.data
      }, err => {

      }
    )
  }

}
