<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
      (keyup)="updateFilter($event)"   />
  
  </div>
  <br>
  <ngx-datatable class="material" [rows]="reportOfferList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="offerId">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.offerId}}
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column [flexGrow]="3" name="Offer Name" prop="offer_name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.offer_name}}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column [flexGrow]="3" name="Display Name" prop="offer_display_name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span [title]="row.offer_display_name">{{row.offer_display_name}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="Type">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span class="badge badge-primary" *ngIf="row.user_type == 1">All</span>
        <span class="badge badge-warning" *ngIf="row.user_type == 2">Repeat User</span>
        <span class="badge badge-success" *ngIf="row.user_type == 3">New User</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Discount" prop="offer_discount">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.offer_discount}}%
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Cust. Pays" prop="charges.customer_pays">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.charges.customer_pays | currency:'INR':'symbol-narrow'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Astro Share" prop="charges.astro_share">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.charges.astro_share | currency:'INR':'symbol-narrow'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="AR Share" prop="charges.ar_share">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.charges.ar_share | currency:'INR':'symbol-narrow'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Status">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-container *ngIf="checkIfOfferIsActive(row._id); then thenBlock; else elseBlock">
        </ng-container>
        <ng-template #thenBlock><mat-slide-toggle [checked]="true" (click)="changeOfferStatus(row._id,false)"></mat-slide-toggle></ng-template>
        <ng-template #elseBlock><mat-slide-toggle (click)="changeOfferStatus(row._id,true)"></mat-slide-toggle></ng-template>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>