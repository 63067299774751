<div class="pr-2 pl-2 pb-2">
    <p class="text-primary lead">Contact Numbers</p>
    <ul>
        <li>
            <span>Email:</span> <span class="font-weight-bold"> {{supportList?.email}}</span>
        </li>
        <li>
            <span>Whatsapp Number:</span> <span class="font-weight-bold"> {{supportList?.whatsappNumber}}</span>
        </li>
        <li>
            <span>Support Phone Number:</span> <span class="font-weight-bold"> {{supportList?.supportPhoneNumber}}</span>
        </li>
    </ul>
</div>
<div class="pr-2 pl-2 d-flex justify-content-between align-items-end">
    <input type="text" placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1" (keyup)="updateFilter($event)"/>
    <div>
        <button class="btn-filter-custom btn btn-warning mr-2" (click)="pendingIssue()">Pending Issues</button>
        <button class="btn-filter-custom btn btn-success mr-2" (click)="solvedIssue()">Solved Issues</button>
    </div>
</div>
<br>
<ngx-datatable class="material" [rows]="issueList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column name="Support ID" prop="supportId" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.supportId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Issue" prop="support_title" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.support_title}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge badge-success" *ngIf="row.is_issue_solved">SOLVED</span>
            <span class="badge badge-danger" *ngIf="!row.is_issue_solved">PENDING</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Actions" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/astrologer/supports/', row._id]" title="View Details" class="btn-user-custom btn btn-outline-primary btn-sm">View</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Date" [flexGrow]="2" prop="created_at">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>