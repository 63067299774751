import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login/login.service';
import { UserdataService } from '../../services/userdata/userdata.service';

@Component({
  selector: 'app-verify-email-otp',
  templateUrl: './verify-email-otp.component.html',
  styleUrls: ['./verify-email-otp.component.scss']
})
export class VerifyEmailOtpComponent implements OnInit {

  id: any = ''; 
  constructor(private router:Router,private loginService: LoginService, private toast: ToastrService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private userdata: UserdataService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.resendOtp()
  }

  onOtpChange(event:any){
    if(event.length == 4){
      this.loginService.verifyEmailOtp({email:this.userdata.getEmail(),otp:event}).subscribe(
        (res:any)=>{
          sessionStorage.setItem("isEmailVerify",'true')
          this.toast.success(res.message,'Success')
          this.router.navigateByUrl('/astrologer/dashboard')
        },err =>{
          this.toast.error(err.error.message,'Error')
        }
      )
    }
  }

  resendOtp(){
    this.loginService.resendEmailOtp({email:this.userdata.getEmail()}).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
