import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private profileService: ProfileServiceService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router
  ) { }

  passwordForm: any = new FormGroup({
    userId: new FormControl(''),
    oldpassword: new FormControl('',[Validators.required]),
    newpassword: new FormControl('',[Validators.required]),
    confirmpassword: new FormControl('',[Validators.required])
  }, { validators: checkPassword })
  
  ngOnInit(): void {

  }

  changePassword(){
    this.passwordForm.patchValue({userId:this.userdata.getUserId()})
    this.profileService.updatePassword(this.passwordForm.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/astrologer/profile/view')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  get f(){
    return this.passwordForm.controls
  }

}

const checkPassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const newpassword = control.get('newpassword');
  const confirmpassword = control.get('confirmpassword');
  return newpassword && confirmpassword && confirmpassword.value === newpassword.value ?  null:{ checkPassword: true };
};
