import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OfferServiceService } from 'src/app/astrologer/services/offer/offer-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-active-offer',
  templateUrl: './active-offer.component.html',
  styleUrls: ['./active-offer.component.scss']
})
export class ActiveOfferComponent implements OnInit {

  constructor(
    private offerService: OfferServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }

  offerList:any = ''
  totalobj:any = ''

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getActiveOffer()
  }

  /* ***********
  list Active Offers
  *********** */

  getActiveOffer() {
    // this.spinner.show()
    this.offerService.activeOffers({ astroId: this.userdata.getId()}).subscribe(
      (res: any) => {
        // this.spinner.hide()
        // console.log(res)
        // console.log(res.totalobj)
        // return

        this.offerList = res.data[0];
        this.totalobj = res.totalobj;
      },
      (err) => {
        // this.spinner.hide()
      }
    );
  }

}
