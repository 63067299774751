import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return 'https://apiv3.astroriver.com/astro';
}

export function getBaseImageUrl() {
  return 'https://apiv3.astroriver.com/';
}

export function getOpenBaseUrl() {
  return 'https://apiv3.astroriver.com/api';
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'BASE_OPEN_URL', useFactory: getOpenBaseUrl, deps: [] },
  // { provide: 'OPEN_BASE_URL', useFactory: getOpenBaseUrl, deps: [] },
  { provide: 'BASE_IMAGE_URL', useFactory: getBaseImageUrl, deps: [] },
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
