import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { MallServiceService } from 'src/app/astrologer/services/mall-service/mall-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-list-mall-product',
  templateUrl: './list-mall-product.component.html',
  styleUrls: ['./list-mall-product.component.scss']
})
export class ListMallProductComponent implements OnInit {

  dataList:any = [];
  temp: any = [];
  constructor(
    private mallService: MallServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService
  ) { }

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getProductList()
  }

  getProductList() {
    this.spinner.show()
    this.mallService.getProductList({}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  associateExists(){
    this.spinner.show()
    this.mallService.getProductList({is_astrologer_associate:true}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.dataList = res.data;
        this.temp = this.dataList;
      },
      (err) => {
        this.spinner.hide()
        //console.log(err);
      }
    );
  }

  /**
* Update filter
*/
  updateFilter(event: any) {
    //console.log(this.temp)
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {
      return d.productname.toLowerCase().indexOf(val) !== -1 ||
        d.productId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.dataList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

}
