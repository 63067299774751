import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServiceService } from 'src/app/astrologer/services/order/order-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chat-order-list',
  templateUrl: './chat-order-list.component.html',
  styleUrls: ['./chat-order-list.component.scss']
})
export class ChatOrderListComponent implements OnInit {

  constructor(
    private orderService: OrderServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private router: Router,
    private toast: ToastrService
  ) { }

  chatList = [];
  temp: any = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getChatOrderList()
  }

  /* ***********
  list Chat Orders
  *********** */

  getChatOrderList() {
    this.spinner.show()
    this.orderService.getChatOrders({ astroId: this.userdata.getId() }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        this.temp = this.chatList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d?.customerId?.name.toLowerCase().indexOf(val) !== -1 ||
        d?.ochatId.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.chatList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  customerReview() {
    this.spinner.show()
    this.orderService.getChatOrders({ astroId: this.userdata.getId(), is_order_reviewed: true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        this.temp = this.chatList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  noAstrologerReviewReply() {
    this.spinner.show()
    this.orderService.getChatOrders({ astroId: this.userdata.getId(), is_order_reviewed: true, is_astro_replied_review: false }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        this.temp = this.chatList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  showPromoChats() {
    this.spinner.show()
    this.orderService.getChatOrders({ astroId: this.userdata.getId(), is_promotional_chat: true }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.chatList = res.data;
        this.temp = this.chatList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  public convertSecondstoTime(val: any) {
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString = hours.toString().padStart(2, '0')
      + ':' + minutes.toString().padStart(2, '0')
      + ':' + seconds.toString().padStart(2, '0');
    return timeString
  }

  /**
   * Check status for redirection
   */
  checkStatus(key: any) {
    //console.log(key)
    if (key.is_end || key.order_current_status == 'Customer Rejected' || key.order_current_status == 'Cancelled by customer' || key.order_current_status == 'Astro Rejected') {
      //console.log('if')
      this.router.navigateByUrl('/astrologer/chat/' + key.orderserviceId?._id)
    } else if (key.order_current_status == 'Pending') {
      let timerInterval
      var orderData
      //console.log('popup')
      /** API to fetch order Details*/
      this.orderService.getOrderDetail({ astroId: this.userdata.getId(), _id: key.orderserviceId._id }).subscribe(
        (res: any) => {
          orderData = res.data
          var date = moment(orderData.chatId.astrologer_notification_time);
          var notify = date.utc().format('YYYY-MM-DD hh:mm:ss');
          var notifyTime = moment(notify, 'YYYY-MM-DD hh:mm:ss');
          //var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
          //var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
          var current = moment(res.server_current_time);
          var currentdate = current.utc().format('YYYY-MM-DD hh:mm:ss');
          var currentTime = moment(currentdate, 'YYYY-MM-DD hh:mm:ss');
          var secondsDiff = (currentTime.diff(notifyTime, 'seconds')) + 10;
          var timeLeft = 180 - secondsDiff
          if (timeLeft <= 0) {
            Swal.fire(
              'Timeout',
              "Chat request can't be accepted due to chat timeout.",
              'error'
            )
            this.router.navigateByUrl('/astrologer/chat/' + key.orderserviceId?._id)
            return
          }
          //console.log(orderData)
          /**
           * Popup
           */
          Swal.fire({
            // title: change.notification.title,
            html: 'Time Left <b></b>. <hr/><table class="table table-bordered p-0"><tr><td><strong>Name:</strong></td><td>' + orderData.customerId?.name + '</td></tr><tr><td colspan="2" class="text-danger"><strong>Profile Details</strong></td></tr><tr><td><strong>Person Name:</strong></td><td>' + orderData.personId?.name + '</td></tr><tr><td><strong>Gender:</strong></td><td>' + orderData.personId?.gender + '</td></tr><tr><td><strong>DOB:</strong></td><td>' + orderData.personId?.dob + '</td></tr><tr><td><strong>TOB:</strong></td><td>' + orderData.personId?.tob + '</td></tr><tr><td><strong>POB:</strong></td><td>' + orderData.personId?.city + '</td></tr><tr><td><strong>Marital Status:</strong></td><td>' + orderData.personId?.marital_status + '</td></tr><tr><td><strong>Occupation:</strong></td><td>' + orderData.personId?.employed_in + '</td></tr><tr><td><strong>Comments</strong></td><td>' + orderData.personId?.comments + '</td></tr></table>',
            // icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ACCEPT',
            cancelButtonText: 'REJECT',
            allowOutsideClick: false,
            timer: timeLeft * 1000,
            timerProgressBar: true,
            didOpen: () => {
              //Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                  const b = content.querySelector('b')
                  if (b) {
                    b.textContent = String(moment.utc(Swal.getTimerLeft()).format('mm:ss'));
                  }
                }
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            if (result.value) {
              this.orderService.acceptRejectChat({ chatId: orderData.chatId._id, is_astrologer_accept: true }).subscribe(
                (res: any) => {
                  //console.log(data.orderserviceId)
                  this.router.navigateByUrl('/astrologer/chat-ongoing/' + orderData._id)
                }, err => {
                  this.toast.error(err.error.message, 'Error')
                }
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.orderService.acceptRejectChat({ chatId: orderData.chatId._id, is_astrologer_accept: false }).subscribe(
                (res: any) => {
                  this.toast.success(res.message, 'Success')
                }, err => {
                  this.toast.error(err.error.message, 'Error')
                }
              )
            }
          })
          /**---------------------- */
        }, err => {
          //console.log(err)
        }
      )
      /**--------------------- */
    } else if (key.order_current_status == 'Astro Accepted' || key.order_current_status == 'Chat Ongoing' || key.order_current_status == 'Chat ongoing' || key.order_current_status == 'Chat Started') {
      //console.log('else if 2')
      this.router.navigateByUrl('/astrologer/chat-ongoing/' + key.orderserviceId?._id)
    } else {
      //console.log('else')
      this.router.navigateByUrl('/astrologer/chat/' + key.orderserviceId?._id)
    }
  }

  selected = [];
  SelectionType = SelectionType;
  onSelect({ selected }) {
    this.checkStatus(selected[0])
    //this.router.navigateByUrl('/astrologer/chat/'+selected[0].orderserviceId?._id)
    //console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

}
