<div class="default-header">

    <!-- BACKGROUND-IMAGE -->
    <div class="login-img">

        <!-- GLOABAL LOADER -->
        <!-- <div id="global-loader">
				<img src="../../assets/images/svgs/loader.svg" class="loader-img" alt="Loader">
            </div> -->


            <ngx-spinner></ngx-spinner>
        <div class="page">
            <div class="fix-to-center">
                <!-- CONTAINER OPEN -->
                <div class="col col-login mx-auto">
                    <div class="text-center">
                        <img src="../../assets/images/brand/astro.png" class="header-brand-img" alt="">
                    </div>
                </div>
                <div class="container-login100">
                    <div class="wrap-login100 p-6">
                        <form class="login100-form validate-form">
                            <span class="login100-form-title">
                                Verify Email OTP
                            </span>
                            <div class="wrap-input100 text-center">
                                <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4}"></ng-otp-input>
                            </div>
                            <div class="wrap-input100 text-center">
                                <button class="btn btn-link text-danger" (click)="resendOtp()">Resend OTP.</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- CONTAINER CLOSED -->
            </div>
        </div>
    </div>
    <!-- BACKGROUND-IMAGE CLOSED -->


</div>