import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  constructor() { }

  public setData(userdata: any){
    sessionStorage.setItem("astroToken",userdata.token)
    sessionStorage.setItem("isSocial",userdata.data.is_social_login)
    sessionStorage.setItem("email",userdata.data.email)
    sessionStorage.setItem("name",userdata.data.name)
    sessionStorage.setItem("id",userdata.astrodetail._id)
    sessionStorage.setItem("userId",userdata.data._id)
    sessionStorage.setItem("autoId",userdata.astrodetail.astroAutoId)
    sessionStorage.setItem("pic",userdata.astrodetail.profile_image)
    sessionStorage.setItem("displayname",userdata.astrodetail.displayname)
    sessionStorage.setItem("isPhoneVerify",userdata.astrodetail.is_otp_verified)
    sessionStorage.setItem("isEmailVerify",userdata.astrodetail.is_email_verified)
  }

  public getToken(){
    return sessionStorage.getItem("astroToken");
  }

  public getEmail(){
    return sessionStorage.getItem("email");
  }

  public getIsSocialLogin(){
    return sessionStorage.getItem("isSocial");
  }

  public getDisplayName(){
    return sessionStorage.getItem("displayname");
  }

  public getName(){
    return sessionStorage.getItem("name");
  }

  public getId(){
    return sessionStorage.getItem("id");
  }

  public getAutoId(){
    return sessionStorage.getItem("autoId");
  }

  public getPic(){
    return sessionStorage.getItem("pic");
  }

  public getUserId(){
    return sessionStorage.getItem("userId");
  }

  public getPhoneVerify(){
    return sessionStorage.getItem("isPhoneVerify");
  }

  public getEmailVerify(){
    return sessionStorage.getItem("isEmailVerify");
  }

  public setFcm(fcm){
    sessionStorage.setItem("fcm",fcm)
  }

  public getFcm(){
    return sessionStorage.getItem("fcm")
  }

  public removeData(){
    sessionStorage.removeItem("astroToken")
    sessionStorage.removeItem("displayname")
    sessionStorage.removeItem("name")
    sessionStorage.removeItem("email")
    sessionStorage.removeItem("isSocialLogin")
    sessionStorage.removeItem("id")
    sessionStorage.removeItem("autoId")
    sessionStorage.removeItem("pic")
    sessionStorage.removeItem("userId")
    sessionStorage.removeItem("isPhoneVerify")
    sessionStorage.removeItem("isEmailVerify")
    sessionStorage.removeItem("fcm")
  }
}
