<div class="default-header">

    <!-- BACKGROUND-IMAGE -->
    <div class="login-img">

        <!-- GLOABAL LOADER -->
        <!-- <div id="global-loader">
				<img src="../../assets/images/svgs/loader.svg" class="loader-img" alt="Loader">
            </div> -->


        <ngx-spinner></ngx-spinner>
        <div class="page">
            <div class="fix-to-center">
                <!-- CONTAINER OPEN -->
                <div class="col col-login mx-auto">
                    <div class="text-center">
                        <img src="../../assets/images/brand/astro.png" class="header-brand-img" alt="">
                    </div>
                </div>
                <div class="container-login100">
                    <div class="wrap-login100 p-6">
                        <form class="login100-form validate-form" [formGroup]="loginForm" (submit)="onLogin()">
                            <span class="login100-form-title">
                                Astrologer Login
                            </span>
                            <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                <input class="input100" type="text" formControlName="email" placeholder="Email" required>
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i class="zmdi zmdi-email" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger mt-1">
                                <!-- <div *ngIf="f.email.errors.required">Required</div> -->
                                Required
                            </div>

                            <div class="wrap-input100 validate-input" data-validate="Password is required">
                                <input class="input100" type="password" formControlName="password" placeholder="Password" required>
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i class="zmdi zmdi-lock" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger mt-1">
                                <!-- <div *ngIf="f.password.errors.required">Required</div> -->
                                Required
                            </div>
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false" formControlName="recaptcha">
                            </ngx-recaptcha2>
                            <!-- <div class="text-right pt-1">
                                <p class="mb-0"><a href="forgot-password.html" class="text-primary ml-1">Forgot
                                        Password?</a></p>
                            </div> -->       

                            <div class="mb-2">
                                <input type="checkbox" class="mr-2" (change)="acceptTerms()"> <label class="custom-label">I've read and accept the <a href="https://astroriver.com/terms-and-conditions-of-use/" target="_blank">Terms & Conditions</a>, <a href="https://astroriver.com/privacy-policy/" target="_blank">Privacy Policy</a>.</label>
                            </div>
                            <div class="container-login100-form-btn">
                                <button type="submit" class="login100-form-btn btn-primary" [disabled]="!loginForm.valid || !isTermsAccepted">
                                    Login
                                </button>
                            </div>
                        </form>
                        <form class="login100-form validate-form" [formGroup]="loginSocialForm" (submit)="onSocialLogin()">
                            <div class=" flex-c-m text-center mt-3">
                                <div class="social-icons">
                                    <ul>
                                        <li><button type="submit" [disabled]="loginSocialForm.invalid || !isTermsAccepted" class="btn  btn-social btn-block" ><i class="fa fa-google-plus text-google-plus"></i> Sign In With Google</button></li>
                                        <!-- <li><a class="btn  btn-social btn-block mt-2"><i class="fa fa-facebook text-facebook"></i> Sign in with Facebook</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </form>
                            <div class="text-center pt-3">
                                <p>Or</p>
                                <p class="text-dark mb-0">Not a member?<a [routerLink]="['/']" class="text-primary ml-1">Sign UP now</a></p>
                            </div>
                            
                    </div>
                </div>
                <!-- CONTAINER CLOSED -->
            </div>
        </div>
    </div>
    <!-- BACKGROUND-IMAGE CLOSED -->


</div>