import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class BlogServiceService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private http: HttpClient, @Inject('BASE_URL') _base: string,
    private userdata: UserdataService
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  /**
   * Get Blog Category
   */
  public getBlogCategoryList(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/allblogcategory", data, { headers: header_object });
  }

  /**
   * Detail Blog Category
   */
   public getSingleBlogCategory(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getsingleblogcategory", data,{ headers: header_object});
  }

  /**
   * Add Blog
   */
   public addBlog(data: any) {
    var header_object = new HttpHeaders().set("Authorization", this.userdata.getToken());
    return this.http.post(this.BaseUrl + "/addBlog", data, { headers: header_object });
  }

  /**
   * Get Blog
   */
   public getBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/allblogs", data,{ headers: header_object});
  }

  /**
   * Get Blog Details
   */
   public getDetailBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/getSingleBlog", data,{ headers: header_object});
  }

  /**
   * Update Blog
   */
   public updateBlog(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/updateBlog", data,{ headers: header_object});
  }

  /**
   * Add Blog Image
   */
   public addBlogImage(data: any){
    var header_object = new HttpHeaders().set("Authorization", this.Token);
    return this.http.post(this.BaseUrl + "/addblogimages", data,{ headers: header_object});
  }
}
