import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProfileServiceService } from '../../services/profile/profile-service.service';
import { UserdataService } from '../../services/userdata/userdata.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  BaseUrl = '';
  isSocialLogin:String = 'false'
  constructor(
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private profileService: ProfileServiceService,
    private trusturl: DomSanitizer,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: Router,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  data: any = '';

  ngOnInit(): void {
    this.getAstrologerProfile();
    this.isSocialLogin = this.userdata.getIsSocialLogin()
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  getAstrologerProfile(){
    this.spinner.show()
    this.profileService.getAstroProfile({_id: this.userdata.getId()}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.data = res.data
      },
      err => {
        this.spinner.hide()
        //console.log(err) 
        
      }
    )
  }

}
