<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">My Profile</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <ng-template #active>
        <span class="badge badge-success  mr-1">YES</span>
    </ng-template>
    <ng-template #Inactive>
        <span class="badge badge-danger  mr-1">No</span>
    </ng-template>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="wideget-user">
                        <div class="row">
                            <div class="col-md-10 border-right">
                                <div class="wideget-user-desc d-flex">
                                    <div class="wideget-user-img">
                                        <img class="" height="135px" [src]=getSanitizeUrl(data.profile_image)
                                            alt="Astro Image">
                                    </div>
                                    <div class="user-wrap">
                                        <h4>{{(data.displayname == '' || data.displayname == null) ? data.name :
                                            data.displayname }} <span *ngIf="!data.is_current_active"
                                                class="badge badge-danger ml-2">OFFLINE</span><span
                                                *ngIf="data.is_current_active"
                                                class="badge badge-success ml-2">ONLINE</span></h4>
                                        <h5 class="text-dark mb-3 d-block mr-2">ID - #{{data.astroAutoId}}</h5>
                                        <h5 class="text-muted mb-3 d-inline-block mr-2">Exclusive Member</h5>
                                        <ng-container *ngIf="data.is_exclusive === true; then active; else Inactive">
                                        </ng-container>
                                        <h5 class="mt-3"> <i class="fe fe-dollar"></i>Wallet Balance -
                                            {{data.current_wallet | currency:'INR':'symbol-narrow'}}</h5>
                                        <p><span *ngIf="data.is_blocked"
                                                class="badge badge-danger mr-2">BLOCKED</span><span
                                                *ngIf="data.is_delete"
                                                class="badge badge-danger mr-2">DELETED</span><span
                                                *ngIf="data.is_approved"
                                                class="badge badge-success mr-2">APPROVED</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <h4 class="margin-top-1-5">Charges</h4>
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <th>Call</th>
                                            <td>
                                                <span *ngIf="data.is_call_allow">{{data.call_charges |
                                                    currency:'INR':'symbol-narrow'}}</span>
                                                <span *ngIf="!data.is_call_allow">N/A</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Chat</th>
                                            <td><span *ngIf="data.is_chat_allow">{{data.chat_charges |
                                                    currency:'INR':'symbol-narrow'}}</span>
                                                <span *ngIf="!data.is_chat_allow">N/A</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Query</th>
                                            <td><span *ngIf="data.is_query_allow">{{data.query_charges |
                                                    currency:'INR':'symbol-narrow'}}</span>
                                                <span *ngIf="!data.is_query_allow">N/A</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Report</th>
                                            <td><span *ngIf="data.is_report_allow">{{data.report_charges |
                                                    currency:'INR':'symbol-narrow'}}</span>
                                                <span *ngIf="!data.is_report_allow">N/A</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul class="nav-tabs nav mb-4">
                                    <li class="nav-item">
                                        <a [routerLink]="['/astrologer/profile/view']" class="nav-link"
                                            routerLinkActive="active">Profile</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a [routerLink]="['/astrologer/profile/review']" class="nav-link"
                                            routerLinkActive="active">Reviews</a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a [routerLink]="['/astrologer/profile/edit']" class="nav-link"
                                            routerLinkActive="active">Edit</a>
                                    </li>
                                    <li class="nav-item" *ngIf="isSocialLogin == 'false'">
                                        <a [routerLink]="['/astrologer/profile/password']" class="nav-link"
                                            routerLinkActive="active">Change Password</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a [routerLink]="['/astrologer/profile/earning']" class="nav-link"
                                            routerLinkActive="active">Earning</a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a [routerLink]="['/astrologer/profile/notification']" class="nav-link"
                                            routerLinkActive="active">Notification</a>
                                    </li>
                                </ul>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->