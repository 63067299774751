<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control col-lg-4 mt-1"
        (keyup)="updateFilter($event)"   />
</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="3" name="Title" prop="blogTitle">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.blogTitle">{{row.blogTitle}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Blog Category" prop="Category Name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.categoryId?.categoryname">{{row.categoryId?.categoryname}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Added By" prop="userName">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span [title]="row.userName">{{row.userName}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.created_at | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a [routerLink]="['/astrologer/blogs/view/',row._id]" class="btn btn-sm btn-primary">View</a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>