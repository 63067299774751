import { Component, OnInit } from '@angular/core';
import { ProfileServiceService } from 'src/app/astrologer/services/profile/profile-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-my-review',
  templateUrl: './my-review.component.html',
  styleUrls: ['./my-review.component.scss']
})
export class MyReviewComponent implements OnInit {

  constructor(
    private profileService : ProfileServiceService,
    private userdata: UserdataService
  ) { }

  ngOnInit(): void {
    this.getAstroProfile()
  }

  reviewList:any = ''
  getAstroProfile(){
    this.profileService.getAstroProfile({_id:this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.reviewList = res.reviews
      },err=>{

      }
    )
  }
}
