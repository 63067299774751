<div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"   placeholder="Type to filter the ID column..."  class="form-control col-lg-4 mt-3" (keyup)="updateFilter($event)"   />
    <!-- <div class="mt-3">
        <button class="btn-filter-custom btn btn-secondary mr-2" (click)="getCallOrderList()">All</button>
        <button class="btn-filter-custom btn btn-primary mr-2" (click)="customerReview()">Customer Reviewed</button>
        <button class="btn-filter-custom btn btn-info mr-2" (click)="noAstrologerReviewReply()">No Review Reply</button>
        <button class="btn-filter-custom btn btn-danger mr-2" (click)="showPromoCalls()">Promo Calls</button>
    </div> -->
</div>
<br>
<ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;" [selected]="selected" [selectionType]="SelectionType.single" (activate)="onActivate($event)" (select)="onSelect($event)">
    <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="orderId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="text-primary" [routerLink]="['/astrologer/store/', row._id]">#{{row.orderId}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Product" prop="productId.productname">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.productId.productname}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Customer" prop="customerId.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.customerId.name}} <span class="badge badge-pill badge-primary">{{row.customerId?.regCountryName}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Amount" prop="astroamounttopay">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="price">{{row.astroamounttopay | currency :'INR':'symbol-narrow'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Status" >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge badge-danger" *ngIf="row.orderStatus == 1">Placed</span>
            <span class="badge badge-warning" *ngIf="row.orderStatus == 2">Confirmed</span>
            <span class="badge badge-info" *ngIf="row.orderStatus == 3">Shipped</span>
            <span class="badge badge-secondary" *ngIf="row.orderStatus == 4">Out For Delivery</span>
            <span class="badge badge-success" *ngIf="row.orderStatus == 5">Delivered</span>
            <span class="badge badge-danger" *ngIf="row.orderStatus == 6">Cancelled</span>
            <span class="badge badge-warning" *ngIf="row.orderStatus == 6 && row.refundStatus == 0">Not Refunded</span>
            <span class="badge badge-info" *ngIf="row.orderStatus == 6 && row.refundStatus == 1">Refunded Requested</span>
            <span class="badge badge-success" *ngIf="row.orderStatus == 6 && row.refundStatus == 2">Refunded Approved</span>
            <span class="badge badge-danger" *ngIf="row.orderStatus == 6 && row.refundStatus == 3">Refunded Rejected</span>
            <span class="badge badge-success" *ngIf="row.orderStatus == 6 && row.refundStatus == 4">Refund Complete</span>
            <span class="badge badge-success" *ngIf="row.orderStatus == 7 || row.orderStatus == 8">Completed</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="createdAt">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.createdAt |date :'medium'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Action">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="fa fa-eye text-primary" [routerLink]="['/astrologer/store/', row._id]"></a>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>