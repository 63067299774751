 <!-- HEADER -->
 <div class="header">
    <div class="container">
        <div class="d-flex">
            <a id="horizontal-navtoggle" class="animated-arrow hor-toggle"><span></span></a>
            <a class="header-brand" href="index.html">
                <img src="../../../../../assets/images/brand/astro.png" class="header-brand-img desktop-logo" alt="Solic logo">
                <img src="../../../../../assets/images/brand/astro.png" class="header-brand-img mobile-view-logo" alt="Solic logo">
            </a><!-- LOGO -->
            <div class="d-flex order-lg-2 ml-auto header-right-icons header-search-icon">
                <a href="#" data-toggle="search" class="nav-link nav-link-lg d-md-none navsearch"><i class="fa fa-search"></i></a>
                <div class="">
                    <form class="form-inline">
                    </form>
                <div class="dropdown d-md-flex header-settings">
                    <a href="#" class="nav-link " data-toggle="dropdown">
                        <span>
                            <img [src]="getSanitizeUrl(userPic)" alt="profile-user" class="avatar brround cover-image mb-0 ml-0">
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <div class="drop-heading border-bottom pb-3">
                            <h5 class="text-dark mb-1">{{(displayName == '' || displayName == null) ? userName : displayName }}</h5>
                            <h5 class="text-primary mb-1">ID- #{{userAutoId}}</h5>
                            <small class="d-block text-muted">{{userEmail}}</small>
                        </div>
                        <a class="dropdown-item" [routerLink]="['/astrologer/profile/view']"><i class="fe fe-user mr-2"></i> <span>My profile</span></a>
                        <a class="dropdown-item" [routerLink]="['/astrologer/profile/notification']"><i class="fe fe-bell mr-2"></i> <span>Notification</span></a>
                        <!-- <a class="dropdown-item" href="#"><i class="mdi mdi-settings mr-2"></i> <span>Settings</span></a>
                        <a class="dropdown-item" href="#"><i class="fe fe-calendar mr-2"></i> <span>Activity</span></a>
                        <a class="dropdown-item" href="#"><i class="mdi mdi-compass-outline mr-2"></i> <span>Support</span></a> -->
                        <button class="dropdown-item" (click)="onLogout()"><i class="fe fe-log-out mr-2"></i> <span>Logout</span></button>
                    </div>
                </div><!-- SIDE-MENU -->
               
            </div>
        </div>
    </div>
</div>
<!-- HEADER END -->
