<!-- ROW-1 OPEN -->
<div >
    <form [formGroup]="addAstrologerForm" (submit)="onAddAstrologerSubmit()" class="row" id="user-profile" >
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header"><h3 class="card-title">Edit Profile</h3></div>
            <div class="card-body">                 
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Profile Image <strong class="required-text">*</strong></label>
                            <input type="file" class="form-control" 
                                placeholder="Profile Image" (change)="onProfileImageUpload($event)">
                            <div *ngIf="f.profile_image.invalid && (f.profile_image.dirty || f.profile_image.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                            <div class="alert alert-danger mt-1" *ngIf="profileError !=''">{{profileError}}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Language Known <strong class="required-text">*</strong></label>
                            <div>
                                <tag-input secondaryPlaceholder="Enter Languages" formControlName="lang" placeholder="+Language" [(ngModel)]="defaultLanguage">
                                    <tag-input-dropdown  [autocompleteItems]="languages" [identifyBy]="'value'" [displayBy]="'display'" [showDropdownIfEmpty]="true" [dynamicUpdate]="false" value="[lang]"></tag-input-dropdown>
                                 </tag-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">Skills <strong class="required-text">*</strong></label>
                            <div>
                                <tag-input secondaryPlaceholder="Enter Skills" formControlName="skill" placeholder="+Skills" [(ngModel)]="defaultSkill">
                                    <tag-input-dropdown [autocompleteItems]="skills" [showDropdownIfEmpty]="true"
                                     [dynamicUpdate]="false" value="[skills]" [identifyBy]="'value'" [displayBy]="'display'"></tag-input-dropdown>
                                 </tag-input>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Address <strong class="required-text">*</strong></label>
                            <input type="text" class="form-control" 
                                placeholder="Complete Address" formControlName="complete_address">
                            <div *ngIf="f.complete_address.invalid && (f.complete_address.dirty || f.complete_address.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Country <strong class="required-text">*</strong></label>
                            <input type="text" class="form-control" 
                                placeholder="Country" formControlName="country">
                            <div *ngIf="f.country.invalid && (f.country.dirty || f.country.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">State <strong class="required-text">*</strong></label>
                            <input type="text" class="form-control" 
                                placeholder="State" formControlName="state">
                            <div *ngIf="f.state.invalid && (f.state.dirty || f.state.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">City <strong class="required-text">*</strong></label>
                            <input type="text" class="form-control" 
                                placeholder="City" formControlName="city">
                            <div *ngIf="f.city.invalid && (f.city.dirty || f.city.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">Pin Code <strong class="required-text">*</strong></label>
                            <input type="text" class="form-control" 
                                placeholder="Pin Code" formControlName="pincode">
                            <div *ngIf="f.pincode.invalid && (f.pincode.dirty || f.pincode.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Working With Others <strong class="required-text">*</strong></label>
                            <div class="col-xl-12 col-lg-12 col-md-12 p-0">
                                <select class="form-control" formControlName="working_with_other">
                                    <option value="yes">yes</option>
                                    <option value="no">no</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Bank Account Number<strong class="text-danger">*</strong></label>
                            <input type="text" class="form-control"
                                placeholder="Bank Account Number"
                                formControlName="bank_account_num">
                            <div *ngIf="f.bank_account_num.invalid && (f.bank_account_num.dirty || f.bank_account_num.touched)"
                                class="alert alert-danger mt-1">
                                Required. Enter Only numbers
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">Account Type<strong class="text-danger">*</strong></label>
                            <div>
                                <select class="form-control" formControlName="account_type">
                                    <option value="" selected disabled>Select Account Type</option>
                                    <option value="savings">Saving</option>
                                    <option value="current">Current</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname">Account Holder Name<strong class="text-danger">*</strong></label>
                            <input type="text" class="form-control"
                                placeholder="Account Holder Name"
                                formControlName="account_holder_name">
                            <div *ngIf="f.account_holder_name.invalid && (f.account_holder_name.dirty || f.account_holder_name.touched)"
                                class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">IFSC<strong class="text-danger">*</strong></label>
                            <input type="text" class="form-control" placeholder="IFSC"
                                formControlName="ifsc">
                            <div *ngIf="f.ifsc.invalid && (f.ifsc.dirty || f.ifsc.touched)"
                                class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label for="exampleInputname1">Experience <strong class="required-text">*</strong></label>
                            <input type="text" class="form-control" 
                                placeholder="Experience" formControlName="experience">
                            <div *ngIf="f.experience.invalid && (f.experience.dirty || f.experience.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="form-group">
                            <label class="">Short Bio <strong class="required-text">*</strong></label>
                            <input class="form-control" placeholder="Description" formControlName="short_bio"/>
                            <div *ngIf="f.short_bio.invalid && (f.short_bio.dirty || f.short_bio.touched)" class="alert alert-danger mt-1">
                                Required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="">About Me <strong class="required-text">*</strong></label>
                    <textarea class="form-control" rows="6" formControlName="long_bio">My bio.........</textarea>
                    <div *ngIf="f.long_bio.invalid && (f.long_bio.dirty || f.long_bio.touched)" class="alert alert-danger mt-1">
                        Required
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
                <button type="submit" class="btn-form-custom btn btn-success mt-1 col-sm-4" [disabled]="!addAstrologerForm.valid">Save</button>
            </div>
            <p class="text-danger m-auto" *ngIf="!addAstrologerForm.valid">Fields with * are Required</p>
        </div>
    </div><!-- COL-END -->
    </form>
</div>
<!-- ROW-1 CLOSED -->