<!-- <div class="pr-2 pl-2 d-flex justify-content-between">
    <input type="text"  placeholder="Type to filter the name column..." class="form-control
        col-lg-4 mt-1" (keyup)="updateFilter($event)"   />
    <div class="mt-3">
        <button class="btn btn-primary btn-sm mr-2"
            (click)="getReviewList()">All</button>
        <button class="btn btn-warning btn-sm mr-2"
            (click)="autoFlagged()">Auto Flagged</button>
    </div>
</div> -->
<br>
<ngx-datatable class="material" [rows]="reviewList" [columnMode]="'force'"
    [headerHeight]="50" [footerHeight]="50" [rowHeight]="60"
    [scrollbarV]="false" [limit]="10" [sorts]="[{dir: 'desc'}]" style="position: relative !important;">
    <ngx-datatable-column [flexGrow]="1" name="ID" prop="reviewId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            #{{row.reviewId}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="3" name="Review"
        prop="customer_review">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <small [title]="row.customer_review">{{row.customer_review}}</small>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [flexGrow]="1" name="Stars" prop="customer_review_stars">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ngb-rating [(rate)]="row.customer_review_stars" [readonly]="true" [max]="5">
                <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"><span
                            class="half" [style.width.%]="fill"
                            [class.bad]="index < 3">&#9733;</span>&#9733;</span>
                </ng-template>
            </ngb-rating>
        </ng-template>
    </ngx-datatable-column> 
    <ngx-datatable-column [flexGrow]="1" name="Customer" prop="customerId?.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="!row.isDirectReview" [title]="row.customerId.name">{{row.customerId.name}}</span>
            <span *ngIf="row.isDirectReview" [title]="row.customerName">{{row.customerName}}</span>
        </ng-template>
    </ngx-datatable-column>                 
    <ngx-datatable-column [flexGrow]="2" name="Date" prop="review_given_date">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.review_given_date | date:'medium'}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>