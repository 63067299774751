import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MallServiceService } from 'src/app/astrologer/services/mall-service/mall-service.service';
import { RemedyServiceService } from 'src/app/astrologer/services/remedy/remedy-service.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-apply-mall-product',
  templateUrl: './apply-mall-product.component.html',
  styleUrls: ['./apply-mall-product.component.scss']
})
export class ApplyMallProductComponent implements OnInit {

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private toast: ToastrService,
    private mallService: MallServiceService,
    private route: Router,
  ) { }

  data: any = ''
  id: any = '';


  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id')
    this.getDataById()
    this.formGroup.patchValue({ astroId: this.userdata.getId() })
    this.formGroup.patchValue({ productId: this.id })
  }

  formGroup: FormGroup = new FormGroup({
    productId: new FormControl('', Validators.required),
    astroId: new FormControl('', Validators.required),
    price: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    astrologer_comment: new FormControl('', Validators.required)
  });


  getDataById() {
    this.spinner.show()
    this.mallService.detailProduct({ _id: this.id }).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.data = res.data
        this.formGroup.patchValue({price:this.data.min_price})
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error');
        this.route.navigateByUrl('/astrologer/mall-product/non-associate')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  /* ***********
  Apply for product
 *********** */

  public submit() {
    if(this.formGroup.get('price')?.value < this.data.min_price){
      this.toast.error('Your price should be equal to or more than minimum price set.')
      return
    }
    this.spinner.show();
    this.mallService.applyForProduct(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.route.navigateByUrl('/astrologer/mall-product/non-associate')
        this.toast.success(res.message, 'Success');
        //this.route.navigateByUrl('/astrologer/remedy')
      },
      err => {
        this.spinner.hide()
        this.toast.error('Something went wrong!', 'Error');
      })
  }

}
