import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/astrologer/services/login/login.service';
import { UserdataService } from 'src/app/astrologer/services/userdata/userdata.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(
    private userdata: UserdataService,
    private route: Router,
    private trusturl: DomSanitizer,
    private loginService: LoginService,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {this.BaseUrl = _imageurl}
  
  userName = ''; userEmail = ''; userPic = '';BaseUrl = ''; displayName = '';userAutoId: any;

  ngOnInit(): void {
    this.displayName = this.userdata.getDisplayName();
    this.userName = this.userdata.getName();
    this.userEmail = this.userdata.getEmail();
    this.userAutoId = this.userdata.getAutoId();
    this.userPic = this.userdata.getPic();
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  /**
   * Logout Astrologer
   */
   logoutForm = new FormGroup({
    email: new FormControl(''),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
  });

  public onLogout(){
    this.logoutForm.patchValue({email:this.userdata.getEmail()})
    this.logoutForm.patchValue({fcm_token:this.userdata.getFcm()})
    this.loginService.logout(this.logoutForm.value).subscribe(
      (res:any)=>{
        this.toast.success(res.message,'Success')
        this.userdata.removeData();
        this.route.navigateByUrl('/');
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
