import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReviewServiceService } from '../../services/review/review-service.service';
import { UserdataService } from '../../services/userdata/userdata.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  constructor(
    private reviewService: ReviewServiceService,
    private spinner: NgxSpinnerService,
    private userdata: UserdataService,
    private modalService: NgbModal,
    private toast: ToastrService
  ) { }

  reviewList = [];
  temp: any = [];
  closeResult = '';

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  ngOnInit(): void {
    this.getAllReviewList()
  }

  /* ***********
  list Call Orders
  *********** */

  getAllReviewList() {
    this.spinner.show()
    this.reviewService.getAllReview({astroId:this.userdata.getId()}).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reviewList = res.data;
        this.temp = this.reviewList
      },
      (err) => {
        this.spinner.hide()
      }
    );
  }

  /**
 * Update filter
 */
  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d: any) {

      return d.customerId.name.toLowerCase().indexOf(val) !== -1 ||
        d.reviewId.toString().indexOf(val) !== -1 || d.customer_review_stars.toString().indexOf(val) !== -1 || !val
    });

    // update the rows
    this.reviewList = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  autoFlaggedReview(){
    this.spinner.show()
    this.reviewService.getAutoFlaggedReview({astroId:this.userdata.getId()}).subscribe(
      (res:any)=>{
        this.reviewList = res.data
        this.temp = this.reviewList
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }

  flagReviewId = ''
  flagReview(content,id) {
    this.flagReviewId = id
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  flagReason:any = ''
  flagReviewReason(content,data:any) {
    this.flagReason = data
    this.modalService.open(content)
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  formGroup: FormGroup = new FormGroup({
    orderserviceId: new FormControl(''),
    astroId: new FormControl(''),
    astrologer_reason: new FormControl('', Validators.required),
    is_astrologer_flagged: new FormControl(true),
  });

  saveFlaggedReview(){
    this.formGroup.patchValue({orderserviceId:this.flagReviewId})
    this.formGroup.patchValue({astroId:this.userdata.getId()})
    this.spinner.show()
    this.reviewService.flagMyReview(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.getAllReviewList()
        this.formGroup.patchValue({astrologer_reason:''})
        this.modalService.dismissAll()
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
        this.formGroup.patchValue({astrologer_reason:''})
        this.modalService.dismissAll()
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }
}
