<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <h4 class="page-title">Dashboard</h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ng-template #allTime let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Schedule Online Time For All Services</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="text-center">
                <div class="form-group col-md-6 offset-md-3">
                    <label for="dateOfBirth">Date</label>
                    <ngb-datepicker #dp [(ngModel)]="allServiceDateModel" [minDate]="minDate" name="dp"></ngb-datepicker>
                </div>
                <div class="form-group">
                    <label for="dateOfBirth">Time</label>
                    <div class="input-group m-auto">
                        <ngb-timepicker class="m-auto" name="time" [(ngModel)]="allServiceTimeModel" [meridian]="true"></ngb-timepicker>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="setAllServiceTime()">Save</button>
        </div>
    </ng-template>
    <ng-template #callTime let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Schedule Online Time For Call</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="text-center">
                <div class="form-group col-md-6 offset-md-3">
                    <label for="dateOfBirth">Date</label>
                    <ngb-datepicker #dp [(ngModel)]="callDateModel" [minDate]="minDate" name="dp"></ngb-datepicker>
                </div>
                <div class="form-group">
                    <label for="dateOfBirth">Time</label>
                    <div class="input-group m-auto">
                        <ngb-timepicker class="m-auto" name="time" [(ngModel)]="callTimeModel" [meridian]="true"></ngb-timepicker>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="setCallTime()">Save</button>
        </div>
    </ng-template>
    <ng-template #chatTime let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Schedule Online Time For Chat</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="text-center">
                <div class="form-group col-md-6 offset-md-3">
                    <label for="dateOfBirth">Date</label>
                    <ngb-datepicker #dp [(ngModel)]="chatDateModel" [minDate]="minDate" name="dp"></ngb-datepicker>
                    <!-- <div class="input-group">
                        <input [(ngModel)]="chatDateModel" id="dateOfBirth" class="form-control"
                            placeholder="YYYY-MM-DD" name="dp" ngbDatepicker #dp="ngbDatepicker"
                            [minDate]="{year:2021,month:7,day:31}">
                        <div class="input-group-append">
                            <button class="btn btn-outline-info calendar" (click)="dp.toggle()" type="button"></button>
                        </div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label for="dateOfBirth">Time</label>
                    <div class="input-group m-auto">
                        <ngb-timepicker class="m-auto" name="time" [(ngModel)]="chatTimeModel"></ngb-timepicker>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="setChatTime()">Save</button>
        </div>
    </ng-template>
    <ng-template #queryTime let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Schedule Online Time For Query</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="text-center">
                <div class="form-group col-md-6 offset-md-3">
                    <label for="dateOfBirth">Date</label>
                    <ngb-datepicker #dp [(ngModel)]="queryDateModel" [minDate]="minDate" name="dp"></ngb-datepicker>
                    <!-- <div class="input-group">
                        <input [(ngModel)]="queryDateModel" id="dateOfBirth" class="form-control"
                            placeholder="YYYY-MM-DD" name="dp" ngbDatepicker #dp="ngbDatepicker"
                            [minDate]="{year:2021,month:7,day:31}">
                        <div class="input-group-append">
                            <button class="btn btn-outline-info calendar" (click)="dp.toggle()" type="button"></button>
                        </div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label for="dateOfBirth">Time</label>
                    <div class="input-group m-auto">
                        <ngb-timepicker class="m-auto" name="time" [(ngModel)]="queryTimeModel"></ngb-timepicker>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="setQueryTime()">Save</button>
        </div>
    </ng-template>
    <ng-template #reportTime let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Schedule Online Time For Report</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="text-center">
                <div class="form-group col-md-6 offset-md-3">
                    <label for="dateOfBirth">Date</label>
                    <ngb-datepicker #dp [(ngModel)]="reportDateModel" [minDate]="minDate" name="dp"></ngb-datepicker>
                    <!-- <div class="input-group">
                        <input [(ngModel)]="reportDateModel" id="dateOfBirth" class="form-control"
                            placeholder="YYYY-MM-DD" name="dp" ngbDatepicker #dp="ngbDatepicker"
                            [minDate]="{year:2021,month:7,day:31}">
                        <div class="input-group-append">
                            <button class="btn btn-outline-info calendar" (click)="dp.toggle()" type="button"></button>
                        </div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label for="dateOfBirth">Time</label>
                    <div class="input-group m-auto">
                        <ngb-timepicker class="m-auto" name="time" [(ngModel)]="reportTimeModel"></ngb-timepicker>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="setReportTime()">Save</button>
        </div>
    </ng-template>
    <ng-template #noticeBoard let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Notice Board</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ol>
                <li *ngFor="let notice of noticeList">
                    <span>
                        {{notice.value}}
                    </span><br>
                    <span class = "ml-auto">
                        {{notice.added_date | date: 'medium'}}
                    </span>
                    </li>
            </ol>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        </div>
    </ng-template>
    <ngx-spinner></ngx-spinner>
    <!-- ROW 1 -->
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="card">
                <div class="row">
                    <div class="col-xl-2 col-lg-2 col-sm-3 border-right">
                        <div class="card-body text-center">
                            <h6 class="mb-4">On/Off</h6>
                            <span class="fe fe-power service-block bg-primary text-white"></span>
                            <h4 class="mb-1 mt-3  display-4 font-weight-semibold text-dark">&nbsp;</h4>
                            <p class="mb-3">
                                <mat-slide-toggle (click)="updateServiceStatus($event,'all',!data.is_current_active,allTime)" [(ngModel)]="data.is_current_active"></mat-slide-toggle>
                                <!-- <mat-slide-toggle (click)="updateServiceStatus($event,'all',true)"
                                    *ngIf="!data.is_current_active"></mat-slide-toggle> -->
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-sm-3 border-right">
                        <div class="card-body text-center">
                            <h6 class="mb-4">Call</h6>
                            <span class="cursor-pointer fe fe-phone-call service-block bg-info text-white"
                                [routerLink]="['/astrologer/orders/call']"></span>
                            <h4 class="mb-1 mt-3  display-4 font-weight-semibold text-dark"><i
                                    class="fa fa-inr mr-1"></i>{{data.call_charges}}</h4>
                            <p class="mb-3"
                                [ngClass]="{'disabled-p': data.is_call_allow === false || !data.is_current_active}">
                                <mat-slide-toggle (click)="updateServiceStatus($event,'call',!data.is_call_active,callTime)" [(ngModel)]="data.is_call_active"></mat-slide-toggle>
                                <!-- <mat-slide-toggle (click)="updateServiceStatus($event,'call',true,callTime)"
                                    *ngIf="!data.is_call_active"></mat-slide-toggle> -->
                            </p>
                            <ng-container *ngIf="!data.is_call_active">
                                <button id="scheduleCall" *ngIf="onlineTimeCall == null || onlineTimeCall == ''"
                                    (click)="openCallModal(callTime)"
                                    class="set-time-btn btn btn-info btn-sm mb-2">Schedule Date/Time</button>
                                <a *ngIf="onlineTimeCall != null && onlineTimeCall != ''"
                                    (click)="openCallModal(callTime)"
                                    class="set-time-btn btn btn-info btn-sm mb-2">{{onlineTimeCall | date:'medium'}}</a>
                            </ng-container>
                            <a [routerLink]="['/astrologer/orders/call']" class="btn btn-info btn-sm">View more <i
                                    class="cursor-pointer fe fe-arrow-right"></i></a>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-sm-3 border-right">
                        <div class="card-body text-center">
                            <h6 class="mb-4">Chat</h6>
                            <span class="cursor-pointer fe fe-message-circle service-block bg-secondary text-white"
                                [routerLink]="['/astrologer/orders/chat']"></span>
                            <h4 class="mb-1 mt-3  display-4 font-weight-semibold text-dark"><i
                                    class="fa fa-inr mr-1"></i>{{data.chat_charges}}</h4>
                            <p class="mb-3"
                                [ngClass]="{'disabled-p': data.is_chat_allow === false || !data.is_current_active}">
                                <mat-slide-toggle (click)="updateServiceStatus($event,'chat',!data.is_chat_active,chatTime)" [(ngModel)]="data.is_chat_active"></mat-slide-toggle>
                                <!-- <mat-slide-toggle (click)="updateServiceStatus($event,'chat',true,chatTime)"
                                    *ngIf="!data.is_chat_active"></mat-slide-toggle> -->
                            </p>
                            <ng-container *ngIf="!data.is_chat_active">
                                <a *ngIf="onlineTimeChat == null || onlineTimeChat == ''"
                                    (click)="openChatModal(chatTime)"
                                    class="set-time-btn btn btn-secondary btn-sm mb-2">Schedule Date/Time</a>
                                <a *ngIf="onlineTimeChat != null && onlineTimeChat != ''"
                                    (click)="openChatModal(chatTime)"
                                    class="set-time-btn btn btn-secondary btn-sm mb-2">{{onlineTimeChat |
                                    date:'medium'}}</a>
                            </ng-container>
                            <a [routerLink]="['/astrologer/orders/chat']" class="btn btn-secondary btn-sm">View more <i
                                    class="cursor-pointer fe fe-arrow-right"></i></a>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-sm-3 border-right">
                        <div class="card-body text-center">
                            <h6 class="mb-4">Query</h6>
                            <span class="cursor-pointer fe fe-help-circle service-block bg-warning text-white"
                                [routerLink]="['/astrologer/orders/query']"></span>
                            <h4 class="mb-1 mt-3  display-4 font-weight-semibold text-dark"><i
                                    class="fa fa-inr mr-1"></i>{{data.query_charges}}</h4>
                            <p class="mb-3"
                                [ngClass]="{'disabled-p': data.is_query_allow === false || !data.is_current_active}">
                                <mat-slide-toggle (click)="updateServiceStatus($event,'query',!data.is_query_active,queryTime)" [(ngModel)]="data.is_query_active"></mat-slide-toggle>
                                <!-- <mat-slide-toggle (click)="updateServiceStatus($event,'query',true,queryTime)"
                                    *ngIf="!data.is_query_active"></mat-slide-toggle> -->
                            </p>
                            <ng-container *ngIf="!data.is_query_active">
                                <a *ngIf="onlineTimeQuery == null || onlineTimeQuery == ''"
                                    (click)="openQueryModal(queryTime)"
                                    class="set-time-btn btn btn-warning btn-sm mb-2">Schedule Date/Time</a>
                                <a *ngIf="onlineTimeQuery != null && onlineTimeQuery != ''"
                                    (click)="openQueryModal(queryTime)"
                                    class="set-time-btn btn btn-warning btn-sm mb-2">{{onlineTimeQuery |
                                    date:'medium'}}</a>
                            </ng-container>
                            <a [routerLink]="['/astrologer/orders/query']" class="btn btn-warning btn-sm">View more <i
                                    class="cursor-pointer fe fe-arrow-right"></i></a>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-sm-3 border-right">
                        <div class="card-body text-center">
                            <h6 class="mb-4">Report</h6>
                            <span class="cursor-pointer fe fe-file-text service-block bg-danger text-white"
                                [routerLink]="['/astrologer/orders/report']"></span>
                            <h4 class="mb-1 mt-3  display-4 font-weight-semibold text-dark"><i
                                    class="fa fa-inr mr-1"></i>{{data.report_charges}}</h4>
                            <p class="mb-3"
                                [ngClass]="{'disabled-p': data.is_report_allow === false || !data.is_current_active}">
                                <mat-slide-toggle (click)="updateServiceStatus($event,'report',!data.is_report_active,reportTime)" [(ngModel)]="data.is_report_active"></mat-slide-toggle>
                                <!-- <mat-slide-toggle (click)="updateServiceStatus($event,'report',true,reportTime)"
                                    *ngIf="!data.is_report_active"></mat-slide-toggle> -->
                            </p>
                            <ng-container *ngIf="!data.is_report_active">
                                <a *ngIf="onlineTimeReport == null || onlineTimeReport == ''"
                                    (click)="openReportModal(reportTime)"
                                    class="set-time-btn btn btn-danger btn-sm mb-2">Schedule Date/Time</a>
                                <a *ngIf="onlineTimeReport != null && onlineTimeReport != ''"
                                    (click)="openReportModal(reportTime)"
                                    class="set-time-btn btn btn-danger btn-sm mb-2">{{onlineTimeReport |
                                    date:'medium'}}</a>
                            </ng-container>
                            <a [routerLink]="['/astrologer/orders/report']" class="btn btn-danger btn-sm">View more <i
                                    class="cursor-pointer fe fe-arrow-right"></i></a>
                        </div>
                    </div>

                    <div class="col-xl-2 col-lg-2 col-sm-3">
                        <div class="card-body text-center">
                            <h6 class="mb-4">Promotional</h6>
                            <span class="cursor-pointer fe fe-target service-block bg-primary text-white"
                                ></span>
                            <h4 class="mb-1 mt-3  display-4 font-weight-semibold text-dark">{{this.currentPromo}} / {{this.totalPromo}}</h4>
                            <p *ngIf="data.is_promotional_allow === false" class="mb-3 disabled-p">
                                <mat-slide-toggle (click)="updateServiceStatus($event,'promo',false)"
                                    *ngIf="data.is_promotional_active" [checked]="true"></mat-slide-toggle>
                                <mat-slide-toggle (click)="updateServiceStatus($event,'promo',true)"
                                    *ngIf="!data.is_promotional_active"></mat-slide-toggle>
                            </p>
                            <p *ngIf="data.is_promotional_allow === true" class="mb-3"
                                [ngClass]="{'disabled-p': (data.is_promotional_active &&!promoStatus) || !data.is_current_active}">
                                <mat-slide-toggle (click)="updateServiceStatus($event,'promo',false)"
                                    *ngIf="data.is_promotional_active" [checked]="true"></mat-slide-toggle>
                                <mat-slide-toggle (click)="updateServiceStatus($event,'promo',true)"
                                    *ngIf="!data.is_promotional_active"></mat-slide-toggle>
                            </p>
                            <!-- <a [routerLink]="['/astrologer/orders/promo']" class="btn btn-primary btn-sm">View more <i
                                    class="cursor-pointer fe fe-arrow-right"></i></a> -->
                                    
                                    <span class="fa fa-info-circle text-primary"
                                title="Today's Promo Not CompletedYou are supposed to take {{totalPromo}} promo calls/chats"
                                *ngIf="!promoStatus"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW 1 END-->
    <!-- ROW 2-->
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="card">
                <div class="row">
                    <div class="col-sm-8 pl-0">
                        <div class="row">
                            <div class="col-md-12 p-0">
                                <div class="card">
                                    <div class="card-header bg-secondary text-white d-flex justify-content-between">
                                        <div class="card-title card-text"><i class="fa fa-bell"></i> Notice Board</div>
                                        <button class="btn btn-sm btn-warning" (click)="openNoticeBoard(noticeBoard)">View All</button>
                                    </div>
                                    <div class="card-body">
                                        <ng-container *ngIf="noticeList.length > 0;else elseNotice">
                                            {{noticeList[0].value}}
                                        </ng-container>
                                        <ng-template #elseNotice>
                                            No Notice.
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row bg-secondary text-white text-center">
                            <div [routerLink]="['/astrologer/orders/call']"
                                class="cursor-pointer col-3 border-bottom border-right p-4">
                                <p class="fe fe-phone-call fe-size-3x">
                                    <span *ngIf="isCallGoing" matBadge="1" matBadgeOverlap="false"
                                        matBadgeColor="warn"></span>
                                </p>
                                Call History
                            </div>
                            <div [routerLink]="['/astrologer/orders/chat']"
                                class="cursor-pointer col-3 border-bottom border-right p-4">
                                <p class="fa fa-comments-o fe-size-3x d-block">
                                    <span *ngIf="isChatGoing" matBadge="1" matBadgeOverlap="false"
                                        matBadgeColor="warn"></span>
                                </p>
                                Chats
                            </div>
                            <div [routerLink]="['/astrologer/orders/query']"
                                class="cursor-pointer col-3 border-bottom border-right p-4">
                                <p class="fa fa-question d-block fe-size-3x">
                                    <span *ngIf="unAnswerQuery > 0" [matBadge]="unAnswerQuery" matBadgeOverlap="false"
                                        matBadgeColor="warn"></span>
                                </p>
                                Query
                            </div>
                            <div [routerLink]="['/astrologer/orders/report']"
                                class="cursor-pointer col-3 border-bottom border-right p-4">
                                <p class="fe fe-calendar fe-size-3x">
                                    <span *ngIf="unAnswerReport > 0" [matBadge]="unAnswerReport" matBadgeOverlap="false"
                                        matBadgeColor="warn"></span>
                                </p>
                                Reports
                            </div>
                            <div [routerLink]="['/astrologer/orders/store']"
                                class="cursor-pointer col-2 border-bottom border-right p-4">
                                <p class="fe fe-shopping-bag fe-size-3x"></p>
                                Astro Shop
                            </div>
                            <!-- <div class="cursor-pointer col-2 border-bottom border-right p-4">
                                <p class="fa fa-commenting-o d-block fe-size-3x"></p>
                                AstroQ
                            </div> -->
                            <div [routerLink]="['/astrologer/wallet/wallet-details']"
                                class="cursor-pointer col-2 border-bottom border-right p-4">
                                <p class="icon icon-wallet fe-size-3x"></p>
                                My Wallet
                            </div>
                            <div [routerLink]="['/astrologer/waiting-list']" class="cursor-pointer col-2 border-right p-4">
                                <p class="fe fe-clock fe-size-3x"></p>
                                Wait List
                            </div>
                            <div [routerLink]="['/astrologer/support/list']" class="cursor-pointer col-2 border-right p-4">
                                <p class="icon icon-earphones-alt fe-size-3x"></p>
                                Support
                            </div>
                            <div [routerLink]="['/astrologer/issue/list']" class="cursor-pointer col-2 border-right p-4">
                                <p class="fe fe-alert-triangle fe-size-3x"></p>
                                Issue
                            </div>
                            <!-- <div class="cursor-pointer col-2 border-right p-4">
                                <p class="fe fe-phone-incoming fe-size-3x"></p>
                                Product Callback
                            </div> -->

                            <div [routerLink]="['/astrologer/offers/active']" class="cursor-pointer col-2 border-right p-4">
                                <p class="fe fe-percent fe-size-3x"></p>
                                Offers
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pr-0">
                        <div class="row">
                            <div class="card" *ngIf="isChatGoing">
                                <div class="card-header bg-success">
                                    <a (click)="checkOngoingChat()" class="card-title text-white text-center">Chat
                                        in-process...</a>
                                </div>
                            </div>
                            <div class="card" *ngIf="isCallGoing">
                                <div class="card-header bg-success">
                                    <a [routerLink]="['/astrologer/call/',data.currentcallserviceId]"
                                        class="card-title text-white text-center">Call in-process...</a>
                                </div>
                            </div>
                            <div class="card cursor-pointer" [routerLink]="['/astrologer/wallet/wallet-details']">
                                <div class="card-header card-header-bg ">
                                    <div class="cursor-pointer card-title card-text" [routerLink]="['/astrologer/wallet/wallet-details']">Wallet Balance</div>
                                </div>
                                <div class="card-body">
                                    <div class="">
                                        <p class="mb-2"><span class="card-body-head card-body-text">Amount</span><span
                                                class="price float-right card-body-text">{{data.current_wallet |
                                                currency:'INR':'symbol-narrow'}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header card-header-bg">
                                    <div class="cursor-pointer card-title card-text" [routerLink]="['/astrologer/profile/view']">Bank Details</div>
                                </div>
                                <div class="card-body">
                                    <div class="border-bottom">
                                        <p class="mb-2"><span class="card-body-head card-body-text">Account Holder
                                                Name</span><span
                                                class="float-right card-body-text">{{data.account_holder_name}}</span>
                                        </p>
                                    </div>
                                    <div class="border-bottom">
                                        <p class="mb-2 mt-2"><span class="card-body-head card-body-text">Account
                                                Number</span><span
                                                class="float-right card-body-text">{{data.bank_account_num}}</span>
                                        </p>
                                    </div>
                                    <div class="border-bottom">
                                        <p class="mb-2 mt-2"><span class="card-body-head card-body-text">IFSC
                                                Number</span><span
                                                class="float-right card-body-text">{{data.ifsc}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ROW 2 END-->

</div>
<!-- CONTAINER END -->