<!-- CONTAINER -->
<div class="container content-area relative">

    <!-- PAGE-HEADER -->
    <div class="page-header">
        <div>
            <h4 class="page-title">Shop Product</h4>
            <small class="d-block text-white mb-2">Note: To request for a product, kindly go to <strong>Non-Associated Products</strong> tab below. </small>
        </div>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/astrologer/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Shop Product</li>
        </ol>
    </div>
    <!-- PAGE-HEADER END -->
    <ngx-spinner></ngx-spinner>
    <!-- ROW-1 OPEN -->
    <div class="row" id="user-profile">
        <div class="col-lg-12">
            <div class="card">
                <div class="border-top">
                    <div class="wideget-user-tab">
                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <ul class="nav-tabs nav mb-4">
                                    <li class="nav-item">
                                        <a [routerLink]="['/astrologer/mall-product/associate']" class="nav-link"
                                            routerLinkActive="active">Associated Products</a>
                                    </li> 
                                    <li class="nav-item">
                                        <a [routerLink]="['/astrologer/mall-product/non-associate']" class="nav-link"
                                            routerLinkActive="active">Non-Associated Products</a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/astrologer/mall-product/list']" class="nav-link"
                                            routerLinkActive="active">All Products</a>
                                    </li>                            
                                    <li class="nav-item">
                                        <a [routerLink]="['/astrologer/mall-product/request']" class="nav-link"
                                            routerLinkActive="active">Product Associate Requests</a>
                                    </li>
                                </ul>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- COL-END -->
    </div>
    <!-- ROW-1 CLOSED -->
</div>
<!-- CONTAINER CLOSED -->