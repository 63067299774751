import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './astrologer/component/sharedComponents/header/header.component';
import { FooterComponent } from './astrologer/component/sharedComponents/footer/footer.component';
import { HorizontalMenuComponent } from './astrologer/component/sharedComponents/horizontal-menu/horizontal-menu.component';
import { SidebarComponent } from './astrologer/component/sharedComponents/sidebar/sidebar.component';
import { MainDashboardComponent } from './astrologer/component/main-dashboard/main-dashboard.component';
import { LayoutComponent } from './astrologer/component/layout/layout.component';
import { LoginComponent } from './astrologer/component/login/login.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SuggestRemedyComponent } from './astrologer/component/remedy/suggest-remedy/suggest-remedy.component';
import { OrdersComponent } from './astrologer/component/orders/orders.component';
import { CallOrderListComponent } from './astrologer/component/orders/call-order-list/call-order-list.component';
import { ReportOrderListComponent } from './astrologer/component/orders/report-order-list/report-order-list.component';
import { QueryOrderListComponent } from './astrologer/component/orders/query-order-list/query-order-list.component';
import { ReportDetailComponent } from './astrologer/component/orders/report-detail/report-detail.component';
import { CallDetailComponent } from './astrologer/component/orders/call-detail/call-detail.component';
import { QueryDetailComponent } from './astrologer/component/orders/query-detail/query-detail.component';
import { WalletComponent } from './astrologer/component/wallet/wallet.component';
import { PriceComponent } from './astrologer/component/price/price.component';
import { CallPriceComponent } from './astrologer/component/price/call-price/call-price.component';
import { QueryPriceComponent } from './astrologer/component/price/query-price/query-price.component';
import { ReportPriceComponent } from './astrologer/component/price/report-price/report-price.component';
import { ProfileComponent } from './astrologer/component/profile/profile.component';
import { ShowProfileComponent } from './astrologer/component/profile/show-profile/show-profile.component';
import { MyReviewComponent } from './astrologer/component/profile/my-review/my-review.component';
import { EditProfileComponent } from './astrologer/component/profile/edit-profile/edit-profile.component';

import { TagInputModule } from 'ngx-chips';
import { ChangePasswordComponent } from './astrologer/component/profile/change-password/change-password.component';
import { EarningComponent } from './astrologer/component/profile/earning/earning.component';
import { NotificationComponent } from './astrologer/component/profile/notification/notification.component';
import { WaitingListComponent } from './astrologer/component/waiting-list/waiting-list.component';
import { OfferComponent } from './astrologer/component/offer/offer.component';
import { CallOfferComponent } from './astrologer/component/offer/call-offer/call-offer.component';
import { ChatOfferComponent } from './astrologer/component/offer/chat-offer/chat-offer.component';
import { QueryOfferComponent } from './astrologer/component/offer/query-offer/query-offer.component';
import { ReportOfferComponent } from './astrologer/component/offer/report-offer/report-offer.component';
import { ReviewComponent } from './astrologer/component/review/review.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { VerifyOtpComponent } from './astrologer/component/verify-otp/verify-otp.component';
import { VerifyEmailOtpComponent } from './astrologer/component/verify-email-otp/verify-email-otp.component';
import { IssueComponent } from './astrologer/component/issue/issue.component';
import { ListIssueComponent } from './astrologer/component/issue/list-issue/list-issue.component';
import { ChatOrderListComponent } from './astrologer/component/orders/chat-order-list/chat-order-list.component';
import { UnapprovedPriceComponent } from './astrologer/component/price/unapproved-price/unapproved-price.component';
import { NotesComponent } from './astrologer/component/notes/notes.component';
import { AddNotesComponent } from './astrologer/component/notes/add-notes/add-notes.component';
import { ListNotesComponent } from './astrologer/component/notes/list-notes/list-notes.component';
import { WalletDetailsComponent } from './astrologer/component/wallet/wallet-details/wallet-details.component';
import { PayoutDetailsComponent } from './astrologer/component/wallet/payout-details/payout-details.component';
import { ActiveOfferComponent } from './astrologer/component/offer/active-offer/active-offer.component';
import { ChatDetailComponent } from './astrologer/component/orders/chat-detail/chat-detail.component';
import { CommonSupportComponent } from './astrologer/component/support/common-support/common-support.component';
import { AddSupportComponent } from './astrologer/component/support/add-support/add-support.component';
import { DetailSupportComponent } from './astrologer/component/support/detail-support/detail-support.component';
import { ListSupportComponent } from './astrologer/component/support/list-support/list-support.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MessagingService } from './astrologer/services/messaging/messaging.service';
import { AsyncPipe } from '@angular/common';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { OngoingChatAstrologerComponent } from './astrologer/component/orders/ongoing-chat-astrologer/ongoing-chat-astrologer.component';
import { ScrollToBottomDirective } from './astrologer/directive/scroll-to-bottom/scroll-to-bottom.directive';
import { ScrolltopService } from './astrologer/services/scrolltotop.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ChatPriceComponent } from './astrologer/component/price/chat-price/chat-price.component';
import { SolvedIssueComponent } from './astrologer/component/issue/solved-issue/solved-issue.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { BlockCopyPasteDirective } from './astrologer/directive/block-copy-paste/block-copy-paste.directive';
import { AddAstrologerComponent } from './astrologer/component/add-astrologer/add-astrologer.component';
import { CommonMallCategoryComponent } from './astrologer/component/mall-category/common-mall-category/common-mall-category.component';
import { ListMallCategoryComponent } from './astrologer/component/mall-category/list-mall-category/list-mall-category.component';
import { DetailMallCategoryComponent } from './astrologer/component/mall-category/detail-mall-category/detail-mall-category.component';
import { DetailMallProductComponent } from './astrologer/component/mall-product/detail-mall-product/detail-mall-product.component';
import { ListMallProductComponent } from './astrologer/component/mall-product/list-mall-product/list-mall-product.component';
import { CommonMallProductComponent } from './astrologer/component/mall-product/common-mall-product/common-mall-product.component';
import { NonAssociateMallProductComponent } from './astrologer/component/mall-product/non-associate-mall-product/non-associate-mall-product.component';
import { AssociateMallProductComponent } from './astrologer/component/mall-product/associate-mall-product/associate-mall-product.component';
import { RequestMallProductComponent } from './astrologer/component/mall-product/request-mall-product/request-mall-product.component';
import { ApplyMallProductComponent } from './astrologer/component/mall-product/apply-mall-product/apply-mall-product.component';
import { RequestMallProductDetailsComponent } from './astrologer/component/mall-product/request-mall-product-details/request-mall-product-details.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonBlogComponent } from './astrologer/component/blog/common-blog/common-blog.component';
import { ListBlogCategoryComponent } from './astrologer/component/blog/list-blog-category/list-blog-category.component';
import { DetailBlogCategoryComponent } from './astrologer/component/blog/detail-blog-category/detail-blog-category.component';
import { FestivalListComponent } from './astrologer/component/festival-list/festival-list.component';
import { MallOrderListComponent } from './astrologer/component/orders/mall-order-list/mall-order-list.component';
import { AddBlogComponent } from './astrologer/component/blog/add-blog/add-blog.component';
import { ListBlogComponent } from './astrologer/component/blog/list-blog/list-blog.component';
import { DetailBlogComponent } from './astrologer/component/blog/detail-blog/detail-blog.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ListMyBlogComponent } from './astrologer/component/blog/list-my-blog/list-my-blog.component';
import { EditBlogComponent } from './astrologer/component/blog/edit-blog/edit-blog.component';
import { MallDetailComponent } from './astrologer/component/orders/mall-detail/mall-detail.component';
import { LightboxModule } from 'ngx-lightbox';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { GroupChatAstrologerComponent } from './astrologer/component/orders/group-chat-astrologer/group-chat-astrologer.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HorizontalMenuComponent,
    SidebarComponent,
    MainDashboardComponent,
    LayoutComponent,
    LoginComponent,
    OrdersComponent,
    CallOrderListComponent,
    ReportOrderListComponent,
    QueryOrderListComponent,
    ReportDetailComponent,
    CallDetailComponent,
    QueryDetailComponent,
    SuggestRemedyComponent,
    WalletComponent,
    PriceComponent,
    CallPriceComponent,
    QueryPriceComponent,
    ReportPriceComponent,
    ProfileComponent,
    ShowProfileComponent,
    MyReviewComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    EarningComponent,
    NotificationComponent,
    WaitingListComponent,
    OfferComponent,
    CallOfferComponent,
    ChatOfferComponent,
    QueryOfferComponent,
    ReportOfferComponent,
    ReviewComponent,
    VerifyOtpComponent,
    VerifyEmailOtpComponent,
    IssueComponent,
    ListIssueComponent,
    ChatOrderListComponent,
    UnapprovedPriceComponent,
    NotesComponent,
    AddNotesComponent,
    ListNotesComponent,
    WalletDetailsComponent,
    PayoutDetailsComponent,
    ActiveOfferComponent,
    ChatDetailComponent,
    CommonSupportComponent,
    AddSupportComponent,
    DetailSupportComponent,
    ListSupportComponent,
    OngoingChatAstrologerComponent,
    ScrollToBottomDirective,
    ChatPriceComponent,
    SolvedIssueComponent,
    BlockCopyPasteDirective,
    AddAstrologerComponent,
    CommonMallCategoryComponent,
    ListMallCategoryComponent,
    DetailMallCategoryComponent,
    DetailMallProductComponent,
    ListMallProductComponent,
    CommonMallProductComponent,
    NonAssociateMallProductComponent,
    AssociateMallProductComponent,
    RequestMallProductComponent,
    ApplyMallProductComponent,
    RequestMallProductDetailsComponent,
    CommonBlogComponent,
    ListBlogCategoryComponent,
    DetailBlogCategoryComponent,
    FestivalListComponent,
    MallOrderListComponent,
    AddBlogComponent,
    ListBlogComponent,
    DetailBlogComponent,
    ListMyBlogComponent,
    EditBlogComponent,
    MallDetailComponent,
    GroupChatAstrologerComponent,
  ],
  imports: [
    TagInputModule,
    NgxDatatableModule,
    BrowserModule,
    AppRoutingModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatSlideToggleModule,
    NgbModule,
    NgOtpInputModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    MatIconModule,
    MatBadgeModule,
    SlickCarouselModule,
    MatRadioModule,
    MatCheckboxModule,
    EditorModule,
    ClipboardModule,
    LightboxModule,
    SocialLoginModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ScrolltopService,
    MessagingService,
    AsyncPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '317093558149-k6loagm8ai1i8c3s06cfhnamis6tbk7i.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('327231889074188'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
